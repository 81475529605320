<template>
    <v-card dark class="mx-auto py-3">
        <v-tabs class="d-flex justify-center" v-model="tab" dark>
            <v-tab>Zeiterfassung</v-tab>
            <v-tab>
                <v-badge color="error" :value="holidayEntries.length" :content="holidayEntries.length">
                    <span>Anträge</span>
                </v-badge>
            </v-tab>
            <v-tab>Abwesenheit</v-tab>
            <v-tab>Übersicht</v-tab>
            <v-tab v-if="userService.hasRight('tt_eventsAdmin')">Übersicht Centerleiter</v-tab>
            <v-tab v-if="userService.hasRight('tt_eventsAdmin') && false">Übersicht Techniker</v-tab>
        </v-tabs>
        <v-tabs-items dark v-model="tab">
            <v-tab-item>
                <v-row v-if="loading">
                    <v-col cols="12">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12">
                        <v-expansion-panels class="px-5" v-model="panel">
                            <v-expansion-panel
                                v-for="(employee, index) of filteredItems"
                                :key="employee.user + '-panel'"
                            >
                                <v-expansion-panel-header
                                    :class="{
                                        'orange--text':
                                            submissionState(employee) &&
                                            submissionState(employee).state === 'submitted',
                                        'primary--text':
                                            submissionState(employee) && submissionState(employee).state === 'accepted',
                                        'red--text':
                                            submissionState(employee) && submissionState(employee).state === 'rejected',
                                    }"
                                >
                                    <span :style="submissionState(employee) ? 'font-weight: bold' : ''">
                                        {{ employee.fullName + ' ' + employee.email }}
                                    </span>
                                    <span
                                        v-if="
                                            employee &&
                                            employee.selfSubmittedTimetrackerMonths &&
                                            employee.selfSubmittedTimetrackerMonths.find(
                                                (month) => month === $moment(datePickerRange[0]).format('YYYY-MM')
                                            )
                                        "
                                    >
                                        <v-icon color="primary">mdi-check-decagram</v-icon>
                                    </span>
                                    <span class="ml-2">
                                        <v-chip
                                            v-if="
                                                submissionState(employee) &&
                                                submissionState(employee).state !== 'onlyNotes'
                                            "
                                            :color="
                                                submissionState(employee) &&
                                                submissionState(employee).state === 'rejected'
                                                    ? 'red'
                                                    : submissionState(employee) &&
                                                      submissionState(employee).state === 'accepted'
                                                    ? 'primary'
                                                    : 'orange'
                                            "
                                        >
                                            {{
                                                submissionState(employee) &&
                                                submissionState(employee).state === 'rejected'
                                                    ? 'abgelehnt'
                                                    : submissionState(employee) &&
                                                      submissionState(employee).state === 'accepted'
                                                    ? 'abgeschlossen'
                                                    : 'eingerreicht'
                                            }}
                                        </v-chip>
                                    </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <office-time-tracker-view
                                        v-if="index === panel"
                                        :studio="selectedStudioSingle"
                                        @fetchEntries="trackerViewUpdated"
                                        :month="$moment(datePickerRange[0])"
                                        :timeEntryView="employee"
                                    ></office-time-tracker-view>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                    <v-col cols="12" v-if="searching">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </v-col>
                    <v-col cols="12" v-if="Object.keys(filteredItems).length === 0">
                        <v-alert class="mx-5" type="info">Es wurden keine Einträge gefunden</v-alert>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row class="justify-content-center">
                    <v-col cols="auto" class="pb-0">
                        <v-select
                            class="mt-3"
                            :items="[
                                { text: 'Offen', value: 'open' },
                                { text: 'Abgelehnt', value: 'rejected' },
                                { text: 'Akzeptiert', value: 'accepted' },
                            ]"
                            v-model="selectedStatus"
                            label="Filtern nach Status"
                        ></v-select>
                    </v-col>
                    <v-col cols="auto" class="pb-0">
                        <v-select
                            class="mt-3"
                            clearable
                            v-model="employeeFilter"
                            :items="
                                holidayEntries.map((entry) => {
                                    return {
                                        value: entry.employee._id,
                                        text: entry.employee.fullName,
                                    }
                                })
                            "
                            label="Filtern nach Mitarbeiter"
                        ></v-select>
                    </v-col>
                </v-row>
                <v-expansion-panels v-if="holidayEntries.length > 0">
                    <v-expansion-panel v-for="(holidayEntry, i) of filteredHolidayEntries" :key="i">
                        <v-expansion-panel-header
                            :class="{
                                'primary--text': holidayEntry.status === 'accepted',
                                'error--text': holidayEntry.status === 'rejected',
                            }"
                        >
                            <span>
                                <v-chip
                                    color="orange"
                                    v-if="
                                        $moment(holidayEntry.dates[holidayEntry.dates.length - 1].date).isSameOrBefore(
                                            $moment()
                                        )
                                    "
                                >
                                    Vergangen
                                </v-chip>
                                {{ holidayEntry.employee.fullName }} |
                                {{ $moment(holidayEntry.dates[0].date).format('DD.MM.YYYY') }} -
                                {{
                                    $moment(holidayEntry.dates[holidayEntry.dates.length - 1].date).format('DD.MM.YYYY')
                                }}
                                ({{ holidayEntry.duration }} Tage)
                            </span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="auto" v-for="(date, index) of holidayEntry.dates" :key="'datum' + index">
                                    <v-card :class="{ primary: date.vacationDay }">
                                        <v-card-title>
                                            {{ $moment(date.date).format('DD.MM.YYYY') }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ date.vacationDay ? date.vacationDay : date.duration + ' Stunden' }}
                                        </v-card-subtitle>
                                    </v-card>
                                </v-col>
                            </v-row>
                            <v-row class="flex-column">
                                <v-card-title>Restsaldo</v-card-title>
                                <v-card-subtitle>
                                    {{ Math.round(holidayEntry.vacationDaysLeft * 100) / 100 }}
                                </v-card-subtitle>
                            </v-row>
                            <v-row class="flex-column" v-if="holidayEntry.reason">
                                <v-card-title>Begründung</v-card-title>
                                <v-card-subtitle>
                                    {{ holidayEntry.reason }}
                                </v-card-subtitle>
                            </v-row>
                            <v-row class="justify-space-around" v-if="holidayEntry.status === 'open'">
                                <v-btn color="error" @click="changeHolidayStatus(holidayEntry, 'rejected')">
                                    Ablehnen
                                </v-btn>
                                <v-btn
                                    class="mr-3"
                                    color="success"
                                    @click="changeHolidayStatus(holidayEntry, 'accepted')"
                                >
                                    Akzeptieren
                                </v-btn>
                            </v-row>
                            <v-row v-if="holidayEntry.status === 'accepted'">
                                <v-btn color="error" @click="changeHolidayStatus(holidayEntry, 'rejected')">
                                    stornieren
                                </v-btn>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-row v-else>
                    <v-col cols="12">
                        <v-alert class="ma-5" type="info">Keine Anträge gefunden</v-alert>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row v-if="tab === 2">
                    <v-col cols="12"><sick-days-view @fetchEntries="fetchEntries"></sick-days-view></v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row v-if="tab === 3">
                    <v-col cols="12">
                        <holiday-entries-view
                            :datePickerRange="datePickerRange"
                            :selectedStudioSingle="selectedStudioSingle"
                        ></holiday-entries-view>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row v-if="tab === 4">
                    <v-col cols="12">
                        <holiday-entries-view
                            :datePickerRange="datePickerRange"
                            :studioManager="true"
                        ></holiday-entries-view>
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-row v-if="tab === 5">
                    <v-col cols="12">
                        <technic-team-overview :datePickerRange="datePickerRange"></technic-team-overview>
                    </v-col>
                </v-row>
            </v-tab-item>
        </v-tabs-items>
    </v-card>
</template>

<script>
import api from '@/api'
import userService from '@/services/userService'
import OfficeTimeTrackerView from './OfficeTimeTrackerView.vue'
import HolidayEntriesView from './HolidayEntriesView.vue'
import TechnicTeamOverview from './TechnicTeamOverview.vue'
import SickDaysView from './SickDaysView.vue'
export default {
    name: 'ManagementTimeTracker',
    components: { OfficeTimeTrackerView, HolidayEntriesView, SickDaysView, TechnicTeamOverview },
    props: {
        datePickerRange: {},
        selectedStudioSingle: {
            required: true,
        },
    },
    data() {
        return {
            employeeFilter: null,
            mapHolidayStatus: {
                open: 'Offen',
                accepted: 'Akzeptiert',
                denied: 'Abgelehnt',
            },
            tab: null,
            userService,
            panel: null,
            loading: false,
            timeEntries: [],
            filteredItems: [],
            selectedMonth: this.$moment().startOf('month').format('YYYY-MM'),
            datePickerDialog: false,
            search: '',
            searching: false,
            selectedStatus: 'open',
            holidayEntries: [],
            displayHolidayEntries: false,
            today: new Date(),
        }
    },
    created() {
        this.fetchEntries()
    },
    computed: {
        filteredHolidayEntries() {
            let entries = JSON.parse(JSON.stringify(this.holidayEntries))
            entries = entries.sort((a, b) => {
                const startDateA = new Date(a.dates[0].date)
                const endDateA = new Date(a.dates[a.dates.length - 1].date)
                const startDateB = new Date(b.dates[0].date)
                const endDateB = new Date(b.dates[b.dates.length - 1].date)

                const isPastA = endDateA < this.today
                const isPastB = endDateB < this.today

                if (isPastA && !isPastB) {
                    return 1 // a ist in der Vergangenheit, b nicht
                }
                if (!isPastA && isPastB) {
                    return -1 // b ist in der Vergangenheit, a nicht
                }
                return startDateA - startDateB // beide sind entweder in der Vergangenheit oder in der Zukunft oder aktuell
            })

            if (this.employeeFilter) {
                return entries.filter((entry) => entry.employee._id === this.employeeFilter)
            }
            return entries
        },
    },
    methods: {
        submissionState(employee) {
            if (
                employee.timeTrackerMonths &&
                employee.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.datePickerRange[0]).format('YYYY-MM')
                )
            ) {
                return employee.timeTrackerMonths.find(
                    (ttm) => ttm.month === this.$moment(this.datePickerRange[0]).format('YYYY-MM')
                )
            }
            return
        },
        shorten(reason) {
            if (reason && reason.length > 5) {
                return reason.slice(0, 5) + '...'
            } else {
                return '-'
            }
        },
        async changeHolidayStatus(holidayEntry, status) {
            try {
                await api.changeHolidayStatus({ _id: holidayEntry._id, status: status })
                this.$toast.success('Status aktualisiert')
                this.fetchEntries()
            } catch (e) {
                this.$toast.error('Es gab einen Fehler beim aktualisieren des Status')
            }
        },
        trackerViewUpdated(data) {
            this.fetchEntries(data)
        },

        async fetchEntries(data = null) {
            this.loading = true
            try {
                this.filteredItems = await api.fetchOfficeTimeEntriesForStudio(
                    this.datePickerRange,
                    this.selectedStudioSingle
                )
                this.holidayEntries = await api.fetchHolidayEntryEmployeeStudioManagement(
                    this.selectedStudioSingle,
                    this.selectedStatus
                )

                this.loading = false
            } catch (e) {
                this.filteredItems = []
                this.loading = false
                this.$toast.erorr('Fehler beim Laden der Einträge')
            }
        },
        /*    try {
                let entries = await api.fetchManagementTimeEntries(this.selectedMonth)
                this.timeEntries = entries
                this.filteredItems = JSON.parse(JSON.stringify(entries))

                this.loading = false
                this.$nextTick(() => {
                    if (
                        data &&
                        this.filteredItems[data.employeeId] &&
                        this.filteredItems[data.employeeId].entries[this.$moment(data.date).format('YYYY-MM-DD')]
                    ) {
                        let ref = this.$refs['timeTrackerView-' + data.employeeId][0]

                        if (ref)
                            ref.setEditTimeEntry(
                                this.filteredItems[data.employeeId].entries[this.$moment(data.date).format('YYYY-MM-DD')]
                            )
                    }
                })
            } catch (e) {
                this.timeEntries = []
                this.filteredItems = []
                this.loading = false
                this.$toast.error('Es gab einen Fehler beim Laden')
            }
        },
        */
        //
    },
    watch: {
        selectedStatus: {
            handler: function (val, oldVal) {
                this.fetchEntries()
            },
        },
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.fetchEntries()
            }
        },
        async selectedStudioSingle() {
            this.fetchEntries()
        },
    },
}
</script>

<style lang="scss" scoped>
</style>