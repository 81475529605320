<template>
    <div class="px-3">
        <v-form ref="pageDialogForm">
            <v-text-field
                label="Angezeigter übertitel im Anamnesebogen"
                filled
                v-model="formData.displayedTitle"
            ></v-text-field>

            <v-card :key="'addedQuestion' + question._id" v-for="(question, index) in formData.questions">
                <v-btn
                    absolute
                    top
                    right
                    icon
                    color="red"
                    @click="
                        formData.questions = formData.questions.filter(
                            (origQuestion) => origQuestion._id !== question._id
                        )
                    "
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-card-title class="font-weight-bold">Frage {{ index + 1 }}</v-card-title>
                <v-card-title>{{ question.title }}</v-card-title>
                <v-divider></v-divider>
            </v-card>
            <div>
                <v-menu v-model="addQuestionMenu" :close-on-content-click="false" dark offset-y absolute>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="addNewQuestion ma-auto">
                            <v-card-title class="font-weight-bold text-center">Neue Frage Hinzufügen</v-card-title>
                        </div>
                    </template>
                    <v-card dark>
                        <v-card-title>Frage auswählen</v-card-title>
                        <v-divider></v-divider>
                        <v-list dark style="max-height: 60vh">
                            <v-list-item
                                v-for="question in questions.filter(
                                    (question) =>
                                        !formData.questions.find((origQuestion) => origQuestion._id === question._id)
                                )"
                                class="dark"
                                :key="question._id"
                                @click="addQuestion(question)"
                            >
                                <v-list-item-title>{{ question.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </div>
            <v-switch
                label="Im Express Anamnesebogen anzeigen"
                color="primary"
                inset
                v-model="formData.showInExpress"
            ></v-switch>
        </v-form>
        <v-card-actions class="justify-content-center">
            <v-btn class="ma-auto" v-if="formData._id" @click="deletePage()" color="red">löschen</v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import api from '@/api'

import basicDialog from '@/components/tools/basicDialog'
export default {
    components: {
        basicDialog,
    },
    props: {
        studio: {
            type: String,
            required: true,
        },
        page: {
            type: Object,
            required: false,
        },
        questions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            addQuestionMenu: false,
            rules: [(v) => !!v || 'Dieses Feld ist ein Pflichtfeld'],

            formData: {
                title: '',
                displayedTitle: '',
                questions: [],
                showInExpress: true,
            },
        }
    },
    created() {
        console.log(this.page)
        if (this.page && this.page._id) {
            this.formData = JSON.parse(JSON.stringify(this.page))
            console.log(this.page)
        } else {
            this.formData = {
                displayedTitle: '',
                title: '',
                questions: [],
                showInExpress: true,
            }
        }
    },
    methods: {
        addQuestion(question) {
            this.formData.questions.push(question)
            this.addQuestionMenu = false
        },
        async createPage() {
            if (this.$refs.pageDialogForm.validate()) {
                if (!this.formData.questions.length) {
                    alert('Bitte mindestens eine Frage hinzufügen')
                    return
                }
                if (this.studio === 'nutrition') {
                    this.formData.nutrition = true
                } else {
                    this.formData.studio = this.studio
                }
                let createdPage = await api.createOrUpdateAnamnesePage(this.formData)
                this.$emit('saveFinished', createdPage)
            }
        },
        async deletePage() {
            if (confirm('Seite wirklich löschen?')) {
                await api.deleteAnamnesePage({ _id: this.page._id })
                this.$emit('deleted')
            }
        },
    },
}
</script>
<style scoped>
.addNewQuestion {
    width: 80%;
    border-radius: 4px;
    border: 5px dashed green;
    padding: 20px;
}
</style>