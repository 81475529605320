<template>
    <div class="leadSettings">
        <v-expansion-panels dark v-model="panel" v-if="leads && leads.length > 0">
            <v-expansion-panel v-for="(item, i) of leads" :key="i">
                <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col cols="12">
                            <v-card-title class="mx-0 px-0">Form Settings</v-card-title>
                            <v-expansion-panels class="pb-5">
                                <v-expansion-panel>
                                    <v-expansion-panel-header>Website Form Settings Manual</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col class="my-0 py-0" cols="12">
                                                <v-card-subtitle class="ma-0 pa-0">
                                                    Step 1. Add Elementor Form on Website
                                                </v-card-subtitle>
                                            </v-col>
                                            <v-col cols="12" class="ma-0 pa-0">
                                                <v-card-subtitle>Step 2. Setting up Webhook</v-card-subtitle>
                                                <v-card-text>
                                                    Go to the Elementor Form Settings and open "Actions After Submit".
                                                    Add Action and select "Webhook".
                                                    <br />
                                                </v-card-text>
                                                <v-img
                                                    max-width="500"
                                                    src="/img/websiteform/webhook_action.png"
                                                ></v-img>
                                                <v-card-text>
                                                    There should appear a new section (above Steps Settings) called
                                                    "Webhook". Open the Panel and paste the copied Webhook Url from the
                                                    "Website (Elementor) Form Settings" section of this page (Copy
                                                    Webhook URL Button).
                                                </v-card-text>
                                                <v-img
                                                    max-width="500"
                                                    src="/img/websiteform/webhook_settings.png"
                                                ></v-img>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-card-subtitle>
                                                    Step 3. Add Center Select to Elementor Form
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    Go to the Elementor Form Settings and open "Form Fields". Add a new
                                                    Field with type select. Set the Label to "center" (no quotes) and
                                                    turn on the Required Switch - Multiple Selection must be disabled.
                                                    <br />

                                                    Copy the Center Select values from the "Website (Elementor) Form"
                                                    section ("Copy Center Select" Button) and paste them in the
                                                    "Options" field.
                                                </v-card-text>
                                                <v-img
                                                    max-width="500"
                                                    src="/img/websiteform/field_center_example.png"
                                                ></v-img>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-card-subtitle>
                                                    Step 4. Add remaining form fields to Elementor Form
                                                </v-card-subtitle>
                                                <v-card-text>
                                                    Go to the Elementor Form Settings and open "Form Fields". Add a new
                                                    Field with type text. Set the Label to the copied Label from the
                                                    "Website (Elementor) Form" section (copy Button) and turn on the
                                                    Required Switch.
                                                    <br />
                                                    <br />
                                                    important: only the shown fields in the "Website (Elementor) Form"
                                                    section (name, surname, phone, email, message) are saved in roody
                                                    but you can add more fields if you want to use them in elementor.
                                                </v-card-text>
                                                <v-img
                                                    max-width="500"
                                                    src="/img/websiteform/field_additional_example.png"
                                                ></v-img>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" cols="12">
                                                <v-card-subtitle>Step 5. Test and Publish the Form</v-card-subtitle>
                                                <v-card-text>
                                                    Test the form and publish it. If you have any problems please
                                                    contact us. After Submission there should be a new entry in roody ->
                                                    Dashboard -> Weiterempfehlungen -> Alle Leads / Referrals
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                                <v-expansion-panel>
                                    <v-expansion-panel-header>
                                        Website (Elementor) Form Settings
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-btn @click="copyText(item._id)">Copy Form ID</v-btn>
                                        <v-card-title class="mx-0 px-0">Webhook URL</v-card-title>

                                        <v-btn
                                            @click="
                                                copyText('https://app.wellcomefit.ch/api/websiteleads/' + item.link)
                                            "
                                        >
                                            Copy Webhook URL
                                        </v-btn>
                                        <v-card-title class="mx-0 px-0">
                                            Center Select
                                            <br />
                                            <br />
                                        </v-card-title>
                                        <v-card-subtitle class="mx-0 px-0">
                                            important: Label of the field must be "center" (without quotes)
                                        </v-card-subtitle>

                                        <v-btn @click="copyText(createElementorCenterText(item))">
                                            Copy Center Select
                                        </v-btn>
                                        <v-card-title class="mx-0 px-0" v-if="item.leadFields.active">
                                            Additional Form Fields
                                        </v-card-title>
                                        <v-row v-if="item.leadFields.active">
                                            <v-col class="ma-0 pa-0" v-if="item.leadFields.name" cols="12">
                                                <v-card-text class="py-0 my-0">
                                                    Label: "name" (first name of the customer)
                                                    <v-btn icon class="ml-2" @click="copyText('name')">
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                            </v-col>

                                            <v-col class="ma-0 pa-0" v-if="item.leadFields.surname" cols="12">
                                                <v-card-text class="py-0 my-0">
                                                    Label: "surname" (last name of the customer)
                                                    <v-btn icon class="ml-2" @click="copyText('surname')">
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" v-if="item.leadFields.phone" cols="12">
                                                <v-card-text class="py-0 my-0">
                                                    Label: "phone" (phone number of the customer)
                                                    <v-btn icon class="ml-2" @click="copyText('phone')">
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" v-if="item.leadFields.email" cols="12">
                                                <v-card-text class="py-0 my-0">
                                                    Label: "email" (email address of the customer)
                                                    <v-btn icon class="ml-2" @click="copyText('email')">
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                            </v-col>
                                            <v-col class="ma-0 pa-0" v-if="item.leadFields.message" cols="12">
                                                <v-card-text class="py-0 my-0">
                                                    Label: "message" (message from customer)
                                                    <v-btn icon class="ml-2" @click="copyText('message')">
                                                        <v-icon>mdi-content-copy</v-icon>
                                                    </v-btn>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <v-card-text v-if="item.createdAt" class="pb-0 pt-0 px-0">
                                Created at : {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
                            </v-card-text>
                            <v-card-text v-if="item.updatedAt" class="pb-5 pt-0 px-0">
                                Edited at: {{ item.updatedAt | moment('DD.MM.YYYY HH:mm') }}
                            </v-card-text>
                            <v-switch
                                filled
                                v-model="item.active"
                                :label="item.active ? 'Aktiv' : 'Inaktiv'"
                            ></v-switch>
                            <v-text-field
                                filled
                                hint="No spaces and only lowercase"
                                @keydown.space.prevent
                                v-model="item.link"
                                label="Link"
                            />
                            <v-text-field
                                filled
                                hint="No spaces and only lowercase"
                                @keydown.space.prevent
                                v-model="item.externalLink"
                                label="externer Link (identifikation)"
                            />
                            <v-card-text class="pb-5">Links:</v-card-text>
                            <v-expansion-panels>
                                <v-expansion-panel v-for="(host, y) of hosts" :key="y + '-hostLinks'">
                                    <v-expansion-panel-header>{{ host }}/l/{{ item.link }}</v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                        <v-row>
                                            <v-col cols="12">
                                                <img :src="'/api/qrcode/image?data=' + host + '/l/' + item.link" />
                                            </v-col>
                                            <v-col cols="12">
                                                <v-btn
                                                    class="mr-5 white--text"
                                                    @click="downloadQRCode(host + '/l/' + item.link)"
                                                    color="primary"
                                                >
                                                    Download QR Code
                                                </v-btn>
                                                <v-btn
                                                    class="mr-5 white--text"
                                                    @click="copyLink(host + '/l/' + item.link)"
                                                    color="primary"
                                                >
                                                    Copy Link
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>

                            <slim-cropper
                                class="mx-auto mt-10"
                                :options="slimOptions"
                                style="width: 320px; height: 160px"
                            >
                                <img v-if="item.image" :src="'/img' + item.image" alt="" />
                                <input type="file" accept="image/*" />
                            </slim-cropper>

                            <v-text-field filled class="mt-10" v-model="item.title" label="title" />
                            <vue-editor :editorToolbar="customToolbar" v-model="item.description"></vue-editor>
                            <v-switch
                                filled
                                v-model="item.showContactInfo"
                                :label="
                                    item.showContactInfo
                                        ? 'Display studio contact informations'
                                        : 'Hide studio contact informations'
                                "
                            ></v-switch>
                            <v-switch
                                filled
                                v-model="item.leadFields.active"
                                :label="item.leadFields.active ? 'Show contact fields' : 'Hide contact fields'"
                            ></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.name"
                                label="Prename"
                            ></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.surname"
                                label="Lastname"
                            ></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.phone"
                                label="Tel Number"
                            ></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.email"
                                label="E-Mail adress"
                            ></v-switch>
                            <v-switch
                                filled
                                class="ml-5 pt-0 mt-0 mb-0"
                                v-if="item.leadFields.active"
                                v-model="item.leadFields.message"
                                label="Note / Message"
                            ></v-switch>
                            <v-select
                                filled
                                v-model="item.studios"
                                hint="Displayed in the following studios"
                                :items="studios"
                                item-text="name"
                                item-value="_id"
                                label="Studios"
                                persistent-hint
                                multiple
                                single-line
                            ></v-select>
                        </v-col>
                        <v-col cols="12" class="d-flex flex-column">
                            <v-card-title class="mx-0 px-0">Management settings</v-card-title>
                            <v-list class="px-0 mx-0">
                                <v-card-subtitle class="px-0 mx-0 mb-0">lead state</v-card-subtitle>
                                <v-list-item-group color="primary">
                                    <v-list-item
                                        @click="removeFromList(list, i)"
                                        v-for="(list, y) in item.status"
                                        :key="y + '-status'"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="list"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                            <v-text-field
                                filled
                                class="mx-5"
                                v-model="status"
                                :append-outer-icon="status ? 'mdi-plus' : 'mdi-plus'"
                                clear-icon="mdi-close-circle"
                                clearable
                                label="add"
                                type="text"
                                @click:append-outer="addToList(i)"
                                @click:clear="clearStatusInput()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center align-center mb-10">
                            <v-btn :loading="loading" color="primary" @click="saveLead(item)">Save</v-btn>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row v-else>
            <v-col><span>Es wurden keine Leadformulare gefunden.</span></v-col>
        </v-row>
        <v-row class="mt-5 mb-15">
            <v-col class="d-flex justify-center align-center">
                <v-btn @click="addLeadSetting" color="primary" class="white--text">
                    <v-icon>mdi-plus</v-icon>
                    <span class="pr-2">Create</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import api from '../../../api'
import $ from 'jquery'
import { VueEditor } from 'vue2-editor'

import Slim from '../../../plugins/slimImage/slim/slim.vue'

export default {
    components: { VueEditor, 'slim-cropper': Slim },
    data() {
        return {
            customToolbar: [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                ['blockquote', 'code-block'],
                [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                ['link', 'video'],
                ['clean'], // remove formatting button
            ],
            host: null,
            hosts: [],
            studios: null,
            tab: null,
            items: ['Liste', 'Einstellungen'],
            leads: [],
            loading: false,
            panel: [],
            status: '',

            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        }
    },
    async created() {
        this.host = window.location.host
        let studios = await api.fetchStudios()
        if (studios) this.studios = studios
        let hostnames = await api.fetchHostnames()
        if (hostnames) this.hosts = hostnames
        this.initLeads()
    },
    methods: {
        copyText(text) {
            try {
                navigator.clipboard.writeText(text)
                this.$toast.success('Text wurde in die Zwischenablage kopiert.')
            } catch (e) {
                this.$toast.error('Text konnte nicht kopiert werden.')
            }
        },
        createElementorCenterText(item) {
            let text = ''
            let count = 0
            item.studios.forEach((studioId) => {
                count++
                let studioName = this.studios.find((s) => studioId.toString() === s._id.toString())
                    ? this.studios.find((s) => studioId.toString() === s._id.toString()).name
                    : ''
                text += studioName + '|' + studioId
                if (count !== item.studios.length) text += '\n'
            })
            return text
        },
        async downloadQRCode(link) {
            api.fetchQRCode(link)
        },
        copyLink(link) {
            navigator.clipboard.writeText(link)
            this.$toast.info('Der Link wurde in die Zwischenablage kopiert.')
        },
        addToList(i) {
            if (this.status) {
                if (this.leads[i].status.includes(this.status)) {
                    this.$toast.error('Dieser Status existiert bereits.')
                } else {
                    this.leads[i].status.push(this.status)
                    this.status = ''
                }
            }
        },

        removeFromList(list, i) {
            if (list === 'Wurde angeworben') {
                this.$toast.error('Dieser Status kann nicht gelöscht werden.')
            } else {
                if (confirm('Möchten Sie den Status ' + list + ' wirklich löschen?')) {
                    this.leads[i].status = this.leads[i].status.filter((item) => item !== list)
                }
            }
        },

        async saveLead(item) {
            if (item.showContactInfo || item.leadFields.active) {
                if (item.studios.length > 0) {
                    let find = this.leads.filter((lead) => lead._id !== item._id)
                    console.log(find)
                    find = find.filter((lead) => lead.link === item.link)
                    console.log(find)
                    if (find.length === 0) {
                        this.loading = true
                        this.status = ''
                        let status = await api.saveLeadSetting(item)
                        console.log(status)
                        this.$toast.open('gespeichert')
                        this.loading = false
                        this.panel = []
                    } else {
                        this.$toast.error('Dieser Link existiert bereits.')
                    }
                } else {
                    this.$toast.error('Bitte wähle mindestens ein Studio aus')
                }
            } else {
                this.$toast.error('Aktiviere Kontaktinformationen oder Kontaktfelder')
            }
        },
        clearStatusInput() {
            this.status = ''
        },
        addLeadSetting() {
            let check = this.leads.filter((e) => e._id === null)

            if (check.length > 0) {
                this.$toast.error('Bitte speichere zuerst das Formular ' + check[0].title)
            } else {
                let leadSetting = {
                    _id: null,
                    active: false,
                    active_from: '',
                    active_till: '',
                    studios: [],
                    link: '',
                    externalLink: '',
                    title: 'Neues Leadformular',
                    showContactInfo: false,
                    leadFields: {
                        active: false,
                        name: true,
                        surname: true,
                        phone: true,
                        email: true,
                        message: true,
                    },
                    description: '',
                    image: '',
                    status: [
                        'Wurde angeworben',
                        'Kontaktiert',
                        'Probetraining vereinbart',
                        'Vertrag abgeschlossen',
                        'Kein Abschluss',
                        'Geschlossen',
                    ],
                }
                this.leads.push(leadSetting)
            }
        },
        async initLeads() {
            let leadSettings = await api.fetchLeadSettings()
            if (leadSettings) {
                this.leads = leadSettings
            }
            this.$emit('finishedloading')
        },
        uploadPicture(formdata, progress, success, failure, slim) {
            const formData = new FormData()
            formData.append('file', formdata[0])

            let uploadimg = {}
            uploadimg.art = 'lead'
            uploadimg.formData = formData
            api.uploadImage(uploadimg)
                .then((data) => {
                    if (data.status) {
                        this.leads[this.panel].image = data.name
                        success(data)
                    }
                })
                .catch((error) => {
                    failure(error)
                    console.log(error)
                })
        },
    },
    computed: {
        slimOptions() {
            return {
                ratio: '16:9',
                buttonCancelLabel: 'Abbrechen',
                buttonConfirmLabel: 'Bestätigen',
                label: 'Bild hochladen',
                labelLoading: 'Bild wird hochgeladen',
                statusUploadSuccess: 'gespeichert',
                service: this.uploadPicture,
                serviceFormat: 'file',
                dataJpegCompression: 70,
                instantEdit: true,
                push: true,
                initialImage: '/img/einmalig/imageUploadPlaceholderPFP.jpg',
            }
        },
    },
    watch: {
        panel: {
            handler: function () {
                console.log(this.panel)
                this.status = ''
            },
        },
    },
}
</script>
<style lang="scss" scoped></style>
