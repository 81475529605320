<template>
    <v-card class="fill-height d-flex flex-column justify-space-between" style="background-color: #303030">
        <v-card-title>Fragebogen</v-card-title>
        <div v-if="loading">
            <v-card-actions class="justify-content-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
        </div>
        <div v-else>
            <v-card-text v-if="anamnese && anamnese.createdAt">
                Aktueller Fragebogen vom: {{ $moment(anamnese.updatedAt).format('DD.MM.YYYY') }}
            </v-card-text>
            <v-card-text v-else>Fragebogen für Kunden aufgeschaltet</v-card-text>
            <v-card-actions class="justify-content-center">
                <v-btn @click="createEditAnamnese()">
                    {{ anamnese && anamnese._id ? 'Bearbeiten' : 'Hinzufügen' }}
                </v-btn>
            </v-card-actions>
        </div>
        <anamnese-bogen-create ref="anamneseBogenRef" @closeAnamnesebogen="closed()"></anamnese-bogen-create>
    </v-card>
</template>
<script>
import api from '../../../api'
import userService from '../../../services/userService'
import anamneseBogenCreate from '../../../components/anamneseBogenCreate'

export default {
    props: {
        user: {},
        creator: {},
    },
    components: {
        anamneseBogenCreate,
    },
    data() {
        return {
            loading: false,
            anamnese: [],
        }
    },
    async created() {
        await this.loadAnamnese()
    },
    methods: {
        async loadAnamnese() {
            this.loading = true
            this.anamnese = await api.getAnamneseNutritionForUser(this.user._id)
            this.loading = false
        },
        async closed() {
            await this.loadAnamnese()
        },
        createEditAnamnese() {
            console.log('i bims', this.creator)
            this.$refs.anamneseBogenRef.setVisible(
                this.anamnese?._id ? this.user._id : null || null,
                this.anamnese?._id,
                true,
                this.user._id,
                this.creator
            )
        },
    },
}
</script>
