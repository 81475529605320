var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"openingHours"},[_c('v-expansion-panels',{attrs:{"dark":""}},_vm._l((_vm.openingHoursStudios),function(studio,index){return _c('v-expansion-panel',{key:index},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(studio.name)+" ")]),_c('v-expansion-panel-content',[_c('v-checkbox',{attrs:{"label":"24H Checkin erlaubt?"},model:{value:(studio.alwaysCheckin),callback:function ($$v) {_vm.$set(studio, "alwaysCheckin", $$v)},expression:"studio.alwaysCheckin"}}),_vm._l((studio.openingHours),function(openingHours,index){return _c('v-row',{key:'openingHours' + index,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"no-details":"","label":"Übertitel"},model:{value:(openingHours.title),callback:function ($$v) {_vm.$set(openingHours, "title", $$v)},expression:"openingHours.title"}}),_vm._l((openingHours.hours),function(hours,key){return _c('v-row',{key:'stunden' + key,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"label":"Text"},model:{value:(hours.text),callback:function ($$v) {_vm.$set(hours, "text", $$v)},expression:"hours.text"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Von","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(hours.from),callback:function ($$v) {_vm.$set(hours, "from", $$v)},expression:"hours.from"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(hours.fromPicker),callback:function ($$v) {_vm.$set(hours, "fromPicker", $$v)},expression:"hours.fromPicker"}},[(hours.fromPicker)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(hours.from),callback:function ($$v) {_vm.$set(hours, "from", $$v)},expression:"hours.from"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bis","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(hours.to),callback:function ($$v) {_vm.$set(hours, "to", $$v)},expression:"hours.to"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(hours.toPicker),callback:function ($$v) {_vm.$set(hours, "toPicker", $$v)},expression:"hours.toPicker"}},[(hours.toPicker)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(hours.to),callback:function ($$v) {_vm.$set(hours, "to", $$v)},expression:"hours.to"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Von (2)","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(hours.from2),callback:function ($$v) {_vm.$set(hours, "from2", $$v)},expression:"hours.from2"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(hours.fromPicker2),callback:function ($$v) {_vm.$set(hours, "fromPicker2", $$v)},expression:"hours.fromPicker2"}},[(hours.fromPicker2)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(hours.from2),callback:function ($$v) {_vm.$set(hours, "from2", $$v)},expression:"hours.from2"}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bis (2)","prepend-icon":"mdi-clock-time-four-outline","readonly":""},model:{value:(hours.to2),callback:function ($$v) {_vm.$set(hours, "to2", $$v)},expression:"hours.to2"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(hours.toPicker2),callback:function ($$v) {_vm.$set(hours, "toPicker2", $$v)},expression:"hours.toPicker2"}},[(hours.toPicker2)?_c('v-time-picker',{attrs:{"full-width":"","format":"24hr"},model:{value:(hours.to2),callback:function ($$v) {_vm.$set(hours, "to2", $$v)},expression:"hours.to2"}}):_vm._e()],1)],1)],1)}),(openingHours.title)?_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.addNewOpeningHours(openingHours)}}},[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1):_vm._e()],2)],1)}),_c('v-divider',{attrs:{"dark":""}}),(
                        !studio.openingHours ||
                        !studio.openingHours.length ||
                        (studio.openingHours[studio.openingHours.length - 1].title &&
                            studio.openingHours[studio.openingHours.length - 1].hours.length)
                    )?_c('v-btn',{on:{"click":function($event){return _vm.addNewSection(studio)}}},[_vm._v(" Neuen Abschnitt hinzufügen ")]):_vm._e(),_c('v-card-actions',{staticClass:"justify-content-center"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveStudio(studio)}}},[_vm._v("Speichern")])],1)],2)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }