export const navigationStoreNotPersisted = {
	namespaced: true,
	state: {
		navigationVisible: true,
		navigationBarText: null,
		navigationBarUsage: null,
		workoutExecutions: null,
		navigationItems: [
			{
				title: 'profile',
				text: 'Profil',
				to: '/user',
				icon: '/img/lottiefiles/BottomNavigation/profileicon.png',
				excludeStudioCodes: ['ot'],
			},
			{
				title: 'home',
				text: 'Home',
				to: '/',
				icon: '/img/lottiefiles/BottomNavigation/homeicon.png',
				excludeStudioCodes: ['ot'],
			},
			{
				title: 'location',
				text: 'Standort',
				to: '/studios',
				icon: '/img/lottiefiles/BottomNavigation/locationicon.png',
			},
			/*{ 
				title: 'nfc',
				text: 'NFC',
				to: '/nfc',
				icon: '/img/lottiefiles/BottomNavigation/nfcScannericon.png',
			},*/
			{
				title: 'nutrition',
				text: 'Ernährung',
				to: '/nutrition',
				icon: '/img/lottiefiles/BottomNavigation/nutritionIcon.png',
				excludeStudioCodes: ['ot'],
			},
			{
				title: 'workout',
				text: 'Trainingsplan',
				to: '/workouts',
				icon: '/img/lottiefiles/BottomNavigation/trainingsplanicon.png',
				excludeStudioCodes: ['ot'],
			},
			{
				title: 'groupFitness',
				text: 'Kursplan',
				to: '/kurse',
				icon: '/img/lottiefiles/BottomNavigation/groupFitnessicon.png',
			},
			{
				title: 'shop',
				text: 'Shop',
				to: '/shop',
				icon: '/img/lottiefiles/BottomNavigation/shopIcon.png',

			},
			{
				title: 'archiv',
				text: 'Archiv',
				to: '/videos',
				icon: '/img/lottiefiles/BottomNavigation/archiveicon.png',
			},
			{
				title: 'settings',
				text: 'Dashboard',
				to: '/dashboard',
				icon: '/img/lottiefiles/BottomNavigation/dashboard.png',
				excludeStudioCodes: ['ot'],
				rights: [
					'af_downloadList',
					'z_downloadList',
					'm_downloadList',
					'kt_viewAll',
					'u_downloadUserList',
					'ab_view',
					'ab_edit',
					'ab_delete',
					'u_search',
					'u_edit',
					'u_delete',
					'af_leadSettings',
					'g_manageRights',
					'g_intern',
					'n_add',
					'ex_create',
					'ex_edit',
					'ex_delete',
					'af_view',
					'af_edit',
					'af_downloadList',
					'a_add',
					'a_edit',
					'a_delete',
					'n_add',
					'n_delete',
					'n_edit',
					'm_signupUser',
					'm_viewSubs',
					'm_downloadList',
					'm_createSubs',
					'gf_s_view',
					'gf_c_signupRange',
					'gf_r_viewAll',
					'gf_c_edit',
					'gf_c_delete',
					'gf_c_plan',
					'gf_c_create',
					'gf_cp_edit',
					'gf_f_courses',
					'c_create',
					'c_delete',
					'c_edit',
					'g_daycare',
					'tt_employeeManage',
					'af_cost'
				],
			},
			{
				title: 'friends',
				text: 'Freunde',
				to: '/friends',
				icon: '/img/lottiefiles/BottomNavigation/friendsicon.png',
			},
		],
	},
	getters: {
		navigationBarUsageData: (state) => {
			if (state.navigationBarUsage) return state.navigationBarUsage.data
		},
		navigationBarTextState: (state) => {
			return state.navigationBarText ? state.navigationBarText.state : null
		},
		navigationBarEndPoint: (state) => {
			return state.navigationBarText ? state.navigationBarText.endPoint : null
		},
	},
	mutations: {
		setWorkoutExecutions(state, data) {
			state.workoutExecutions = data
		},
		setNewBottomNavigationUsage(state, data) {
			state.navigationBarUsage = data
		},
		setNavigationBarText(state, data) {
			state.navigationBarText = data
		},
		setNavigationbarVisibility(state, data) {
			state.navigationVisible = data
		},
		toggleNavigationBarTextState(state) {
			state.navigationBarText.state = !state.navigationBarText.state
		},
		toggleNavigationBarVisibility(state) {
			state.navigationVisible = !state.navigationVisible
		},
	},
	actions: {
		setWorkoutExecutions({ commit }, data) {
			commit('setWorkoutExecutions', JSON.parse(JSON.stringify(data)))
		},
		toggleNavigationBarVisibility({ commit }) {
			commit('toggleNavigationBarVisibility')
		},
		setNavigationbarVisibility({ commit }, data) {
			commit('setNavigationbarVisibility', JSON.parse(JSON.stringify(data)))
		},
		setNewBottomNavigationUsage({ commit }, data) {
			commit('setNewBottomNavigationUsage', JSON.parse(JSON.stringify(data)))
		},
		setNavigationBarText({ commit }, data) {
			commit('setNavigationBarText', JSON.parse(JSON.stringify(data)))
		},
		toggleNavigationBarTextState({ commit }) {
			commit('toggleNavigationBarTextState')
		},
	},
}
