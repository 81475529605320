<template>
	<div class="leadForm mb-15" style="max-width: 500px; margin-left: auto; margin-right: auto;">
		<div v-if="selectedStudio && leadSettings">
			<v-row v-if="leadSettings.showContactInfo">
				<v-col>
					<v-img v-if="leadSettings.image && leadSettings.image.length > 0" :src="'/img' + leadSettings.image"></v-img>
					<v-card-text class="text-center h3">Melde Dich bei uns</v-card-text>
					<v-row>
						<v-col class="pb-0 mb-0 justify-center d-flex align-center" cols="12"
							><v-btn
								v-if="selectedStudio.telefon"
								:href="'tel:' + selectedStudio.telefon"
								class="mb-0 pb-0 lowercase"
								color="primary"
								text
								>{{ selectedStudio.telefon }}</v-btn
							></v-col
						>
						<v-col class="justify-center d-flex align-center pt-0" cols="12"
							><v-btn
								v-if="selectedStudio.email"
								:href="'mailto:' + selectedStudio.email"
								class="lowercase"
								stlye="text-transform: lowercase;"
								text
								color="primary"
								>{{ selectedStudio.email }}</v-btn
							></v-col
						>
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="leadSettings.leadFields.active && !submitted">
				<v-col class="mx-10">
					<v-card-text class="text-center h5" v-if="leadSettings.showContactInfo">oder lass Dich von uns kontaktieren</v-card-text>
					<v-card-text class="text-center h5" v-else>Lass Dich von uns kontaktieren</v-card-text>
					<v-text-field v-if="leadSettings.leadFields.name" label="Vorname" v-model="leadInformation.name"></v-text-field>
					<v-text-field v-if="leadSettings.leadFields.surname" label="Name" v-model="leadInformation.surname"></v-text-field>
					<v-text-field v-if="leadSettings.leadFields.phone" label="Telefonnummer" v-model="leadInformation.phone"></v-text-field>
					<v-text-field v-if="leadSettings.leadFields.email" label="E-Mail" v-model="leadInformation.email"></v-text-field>
					<v-textarea v-if="leadSettings.leadFields.message" label="Nachricht" v-model="leadInformation.message"></v-textarea>
				</v-col>
			</v-row>

			<v-row v-if="leadSettings.leadFields.active && !submitted">
				<v-col class="d-flex flex-column justify-center align-center">
					<v-btn color="primary" :loading="submitting" @click="sendLead">Absenden</v-btn>
					<v-btn v-if="!leadSettings.description && leadSettings.studios.length !== 1" class="mt-3" text @click="selectedStudio = null"
						>Zurück</v-btn
					>
				</v-col>
			</v-row>
			<v-row v-if="submitted">
				<v-col>
					<v-card-text class="text-center h5">Wir haben Deine Anfrage erhalten und werden uns umgehend bei dir melden</v-card-text>
				</v-col>
			</v-row>
			<v-row v-if="leadSettings.description" class="mt-15 mx-5">
				<v-card-text class="px-5" v-html="leadSettings.description"></v-card-text>
			</v-row>
			<v-row v-if="leadSettings.description && leadSettings.leadFields.active && leadSettings.studios.length !== 1">
				<v-col class="d-flex flex-column justify-center align-center">
					<v-btn class="mt-3 mb-15" text @click="selectedStudio = null">Zurück</v-btn>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-row v-if="!loading && leadSettings">
				<v-col v-if="selectedStudio">{{ leadSettings }}</v-col>
				<v-col v-else>
					<v-card-title class="d-flex justify-center">Wähle dein Standort aus:</v-card-title>
					<v-row class="d-flex justify-space-evenly justify-center">
						<v-col
							style="cursor: pointer; min-height: 100px"
							class="d-flex flex-column align-center justify-center "
							cols="5"
							v-for="studio of leadSettings.studios"
							:key="studio._id"
						>
							<div @click="selectedStudio = studio" class="studioSelection elevation-7">
								<span class="studioSelection__name">{{ studio.name }}</span>
							</div>
						</v-col>
					</v-row>
					<div>
						<div v-for="studio of leadSettings.studios" :key="studio._id"></div>
					</div>
				</v-col>
			</v-row>
			<v-row class="d-flex justify-center align-center" v-else-if="loading" style="height: 100vh; width: 100vw;">
				<v-progress-circular indeterminate color="primary"></v-progress-circular> </v-row
			><v-row class="d-flex justify-center align-center" v-else style="height: 100vh; width: 100vw;">
				<v-col class="d-flex justify-center align-center flex-column"
					><v-card-title>Seite wurde nicht gefunden</v-card-title
					><v-btn class="mt-5" color="primary" @click="toHomepage">zur Startseite</v-btn></v-col
				>
			</v-row>
		</div>
	</div>
</template>
<script>
import api from '../../../../api'
import $ from 'jquery'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
	components: {},
	data() {
		return {
			loading: true,
			submitting: false,
			submitted: false,
			leadSettings: null,
			selectedStudio: null,
			leadInformation: {
				name: '',
				surname: '',
				phone: '',
				email: '',
				message: '',
			},
		}
	},

	created() {
		this.setNavigationbarVisibility(false)
		this.init()
	},
	beforeDestroy() {
		this.setNavigationbarVisibility(true)
	},
	methods: {
		...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility', 'toggleNavigationBarVisibility']),
		toHomepage() {
			window.location.href = '/'
		},
		async sendLead() {
			if (this.leadSettings.leadFields.active) {
				if (this.leadSettings.leadFields.name && !this.leadInformation.name) {
					this.$toast.error('Bitte gib einen Namen ein')
					return
				}
				if (this.leadSettings.leadFields.surname && !this.leadInformation.surname) {
					this.$toast.error('Bitte gib einen Nachnamen ein')
					return
				}
				if (this.leadSettings.leadFields.phone && !this.leadInformation.phone) {
					this.$toast.error('Bitte gib eine Telefonnummer ein')
					return
				}
				if (this.leadSettings.leadFields.email && !this.leadInformation.email) {
					this.$toast.error('Bitte gib eine E-Mail Adresse ein')
					return
				}

				this.error = false
				this.submitting = true
				let lead = this.leadInformation
				lead.studio = this.selectedStudio._id
				let status = await api.createLeadPublic(this.$route.params.link, lead).catch((e) => {
					this.error = true
					this.$toast.error('Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.')
				})
				this.submitted = true
				this.submitting = false
				this.$toast.success('Deine Anfrage wurde erfolgreich versendet')
				console.log(this.leadInformation)
			}
		},
		async init() {
			let leadSettings = await api.fetchLeadSettingsPublic(this.$route.params.link).catch((err) => {
				this.loading = false
			})

			if (leadSettings) this.leadSettings = leadSettings
			if (leadSettings.studios.length === 1) this.selectedStudio = leadSettings.studios[0]
			this.loading = false

			console.log(leadSettings)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .lowercase span {
	text-transform: lowercase !important;
}
.leadForm {
	.studioSelection {
		display: flex;
		height: 100%;
		width: 100%;
		border-radius: 8px;
		justify-content: center;
		align-content: center;
		align-items: center;
		background-color: var(--v-primary);

		&__name {
			//font-weight: bold;
			font-size: 1rem;
			text-transform: uppercase;
			color: white;
			font-weight: bold;
			text-align: center;
			letter-spacing: 1.2px;
			margin: 5px;
		}
	}
}
</style>
