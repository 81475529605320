var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3"},[_c('v-form',{ref:"pageDialogForm"},[_c('v-text-field',{attrs:{"label":"Angezeigter übertitel im Anamnesebogen","filled":""},model:{value:(_vm.formData.displayedTitle),callback:function ($$v) {_vm.$set(_vm.formData, "displayedTitle", $$v)},expression:"formData.displayedTitle"}}),_vm._l((_vm.formData.questions),function(question,index){return _c('v-card',{key:'addedQuestion' + question._id},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","icon":"","color":"red"},on:{"click":function($event){_vm.formData.questions = _vm.formData.questions.filter(
                        function (origQuestion) { return origQuestion._id !== question._id; }
                    )}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v("Frage "+_vm._s(index + 1))]),_c('v-card-title',[_vm._v(_vm._s(question.title))]),_c('v-divider')],1)}),_c('div',[_c('v-menu',{attrs:{"close-on-content-click":false,"dark":"","offset-y":"","absolute":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"addNewQuestion ma-auto"},'div',attrs,false),on),[_c('v-card-title',{staticClass:"font-weight-bold text-center"},[_vm._v("Neue Frage Hinzufügen")])],1)]}}]),model:{value:(_vm.addQuestionMenu),callback:function ($$v) {_vm.addQuestionMenu=$$v},expression:"addQuestionMenu"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-card-title',[_vm._v("Frage auswählen")]),_c('v-divider'),_c('v-list',{staticStyle:{"max-height":"60vh"},attrs:{"dark":""}},_vm._l((_vm.questions.filter(
                                function (question) { return !_vm.formData.questions.find(function (origQuestion) { return origQuestion._id === question._id; }); }
                            )),function(question){return _c('v-list-item',{key:question._id,staticClass:"dark",on:{"click":function($event){return _vm.addQuestion(question)}}},[_c('v-list-item-title',[_vm._v(_vm._s(question.title))])],1)}),1)],1)],1)],1),_c('v-switch',{attrs:{"label":"Im Express Anamnesebogen anzeigen","color":"primary","inset":""},model:{value:(_vm.formData.showInExpress),callback:function ($$v) {_vm.$set(_vm.formData, "showInExpress", $$v)},expression:"formData.showInExpress"}})],2),_c('v-card-actions',{staticClass:"justify-content-center"},[(_vm.formData._id)?_c('v-btn',{staticClass:"ma-auto",attrs:{"color":"red"},on:{"click":function($event){return _vm.deletePage()}}},[_vm._v("löschen")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }