<template>
    <v-dialog v-model="visible" fullscreen dark close-on-back>
        <!--
    
    * Rating schöner mache
    * bi 2 frage nöd so viel abstand

    
     -->
        <v-card dark>
            <v-row class="justify-content-center px-0 ma-0">
                <v-col cols="auto">
                    <div class="app-bar__image">
                        <lottie-player
                            mode="normal"
                            style="height: 60px; width: 80px"
                            name="roody_icon"
                            autoplay
                            src="/img/lottiefiles/roody_animated.json"
                        ></lottie-player>
                    </div>
                </v-col>
                <v-card-title class="pb-1 font-weight-bold">
                    | {{ nutrition ? 'Fragebogen Ernährung' : 'Anamnesebogen' }}
                </v-card-title>
            </v-row>
            <v-form
                @submit.prevent
                ref="anamneseForm"
                class="ma-auto"
                style="max-width: 800px"
                lazy-validation
                novalidate
            >
                <v-progress-linear
                    class="rounded mx-auto my-3"
                    style="background: grey; max-width: 800px"
                    :value="Math.round(((anamneseFormStepper - 1) / (filteredPages.length + 3)) * 100)"
                    buffer-value="0"
                    height="20"
                ></v-progress-linear>
                <v-window v-model="anamneseFormStepper" :touchless="!viewOnly" :continuous="false">
                    <v-window-item :value="1">
                        <v-card-title style="word-break: break-word">
                            {{
                                nutrition
                                    ? 'Wähle den Ernährungscoach und einen Kunden um mit dem Fragebogen zu beginnen.'
                                    : 'Wähle einen Trainer und einen Kunden aus um mit dem Anamnesebogen zu beginnen.'
                            }}
                        </v-card-title>
                        <v-col v-if="!disableUserFields" cols="12" class="mt-5 px-4 mx-auto">
                            <user-search
                                style="max-width: 80%"
                                class="mx-auto"
                                :value="formData.creator"
                                ignoreRights
                                :disabled="viewOnly || disableUserFields"
                                instructor
                                :key="formData.creator"
                                label="Visum (Anamnesebogen ersteller)"
                                @update="updateUser($event, 'creator')"
                            ></user-search>
                        </v-col>
                        <v-col cols="12" class="mt-5 px-4 mx-auto" v-if="formData.creator && !disableUserFields">
                            <user-search
                                :key="formData.customer"
                                style="max-width: 80%"
                                class="mx-auto"
                                ignoreRights
                                :value="formData.customer"
                                :disabled="viewOnly || disableUserFields"
                                label="Kunde"
                                @update="updateUser($event, 'customer')"
                            ></user-search>
                        </v-col>
                        <v-card-actions class="justify-content-center">
                            <v-btn
                                v-if="!viewOnly && !formData.customer && formData.creator"
                                color="primary"
                                @click="showRegisterDialog = true"
                            >
                                Neuen Account erstellen
                            </v-btn>

                            <v-btn
                                rounded
                                v-if="formData.customer && !viewOnly && !nutrition"
                                class="mx-0"
                                color="primary"
                                @click="resetCustomer"
                            >
                                Kunde ändern
                            </v-btn>
                        </v-card-actions>
                        <v-card-actions v-if="!nutrition" class="justify-content-center">
                            <v-btn
                                x-large
                                rounded
                                color="primary"
                                class="ma-auto"
                                v-if="!viewOnly"
                                @click="validateStepAndGoNext(true)"
                                :disabled="!formData.customer || !formData.creator"
                            >
                                Express anamnesebogen
                            </v-btn>
                        </v-card-actions>
                    </v-window-item>
                    <v-window-item
                        :value="2 + pageIndex"
                        :key="'page' + pageIndex"
                        v-for="(page, pageIndex) in filteredPages"
                    >
                        <div
                            v-if="anamneseFormStepper === 2 + pageIndex"
                            style="min-height: 60vh; display: flex; flex-direction: column"
                        >
                            <v-card-title v-if="page.displayedTitle" style="word-break: break-word">
                                {{ page.displayedTitle }}
                            </v-card-title>
                            <v-row
                                class="flex-column mx-0 px-5"
                                :key="'page' + questionIndex"
                                v-for="(question, questionIndex) in page.questions"
                            >
                                <v-card-title class="pb-0" style="word-break: break-word">
                                    {{ question.title }}
                                </v-card-title>

                                <v-textarea
                                    filled
                                    auto-grow
                                    rows="1"
                                    :disabled="viewOnly"
                                    rounded
                                    :rules="question.required ? rules.required : []"
                                    placeholder="TEXT EINGEBEN"
                                    v-if="question.type === 'text'"
                                    v-model="question.answer"
                                ></v-textarea>
                                <v-text-field
                                    filled
                                    rounded
                                    :disabled="viewOnly"
                                    :rules="question.required ? rules.required : []"
                                    placeholder="ZAHL EINGEBEN"
                                    v-if="question.type === 'number'"
                                    v-model.number="question.answer"
                                    :suffix="question.isPercent ? '%' : ''"
                                ></v-text-field>

                                <div
                                    class="d-flex flex-column justify-content-center"
                                    v-if="question.type === 'rating'"
                                >
                                    <v-rating
                                        x-large
                                        :disabled="viewOnly"
                                        v-model="question.answer"
                                        :length="question.maxStarCount"
                                        color="yellow darken-3"
                                        background-color="grey darken-1"
                                        empty-icon="$ratingFull"
                                        half-increments
                                    ></v-rating>
                                </div>
                                <div class="ma-5" v-if="question.type === 'select'">
                                    <div :key="'selectOption' + index" v-for="(option, index) in question.options">
                                        <input
                                            :class="{ selectsDisabled: viewOnly }"
                                            :disabled="viewOnly"
                                            style="z-index: 1000"
                                            :type="question.allowMultiple ? 'checkbox' : 'radio'"
                                            :name="question._id"
                                            :id="question._id + index"
                                            @change="test(question)"
                                            v-model="question.answer"
                                            :value="option"
                                        />
                                        <label style="z-index: 1000" :for="question._id + index">{{ option }}</label>
                                        <div
                                            class="ml-3"
                                            v-if="
                                                question.answer &&
                                                question.answer.length &&
                                                ((Array.isArray(question.answer) &&
                                                    question.answer.includes(option) &&
                                                    getAdditionalField(question, option)) ||
                                                    (question.answer === option &&
                                                        getAdditionalField(question, option)))
                                            "
                                        >
                                            <v-textarea
                                                filled
                                                auto-grow
                                                rows="1"
                                                :disabled="viewOnly"
                                                rounded
                                                :rules="
                                                    getAdditionalField(question, option).required ? rules.required : []
                                                "
                                                :label="getAdditionalField(question, option).title"
                                                placeholder="TEXT EINGEBEN"
                                                v-if="getAdditionalField(question, option).type === 'text'"
                                                v-model="question.additionalFieldAnswer[option]"
                                            ></v-textarea>
                                            <v-text-field
                                                :disabled="viewOnly"
                                                filled
                                                rounded
                                                :rules="
                                                    getAdditionalField(question, option).required ? rules.required : []
                                                "
                                                :label="getAdditionalField(question, option).title"
                                                placeholder="ZAHL EINGEBEN"
                                                v-if="getAdditionalField(question, option).type === 'number'"
                                                v-model.number="question.additionalFieldAnswer[option]"
                                                :suffix="question.isPercent ? '%' : ''"
                                            ></v-text-field>
                                            <div v-if="getAdditionalField(question, option).type === 'select'">
                                                <div
                                                    :key="'additionalSelectOption' + additionalIndex"
                                                    v-for="(additionalOption, additionalIndex) in getAdditionalField(
                                                        question,
                                                        option
                                                    ).options"
                                                >
                                                    <input
                                                        :class="{ selectsDisabled: viewOnly }"
                                                        :disabled="viewOnly"
                                                        style="z-index: 1000"
                                                        :type="
                                                            getAdditionalField(question, option).allowMultiple
                                                                ? 'checkbox'
                                                                : 'radio'
                                                        "
                                                        :name="question._id + 'additional'"
                                                        :id="question._id + 'additional' + additionalIndex + 1"
                                                        @change="test()"
                                                        v-model="question.additionalFieldAnswer[option]"
                                                        :value="additionalOption"
                                                    />
                                                    <label
                                                        style="z-index: 1000"
                                                        :for="question._id + 'additional' + additionalIndex + 1"
                                                    >
                                                        {{ additionalOption }}
                                                    </label>
                                                </div>
                                                <div v-if="getAdditionalField(question, option).allowOwnInput">
                                                    <input
                                                        :disabled="viewOnly"
                                                        :class="{ selectsDisabled: viewOnly }"
                                                        style="z-index: 1000"
                                                        :type="
                                                            getAdditionalField(question, option).allowMultiple
                                                                ? 'checkbox'
                                                                : 'radio'
                                                        "
                                                        :name="question._id + 'additional'"
                                                        :id="question._id + 'additional' + additionalIndex + 1001"
                                                        @change="test(question, true)"
                                                        v-model="question.additionalFieldAnswer[option]"
                                                        :value="{
                                                            ownEntry: getValueForAdditionalField(
                                                                question,
                                                                option,
                                                                getAdditionalField(question, option).allowMultiple
                                                            ),
                                                        }"
                                                    />
                                                    <label
                                                        style="z-index: 1000"
                                                        :for="question._id + 'additional' + additionalIndex + 1001"
                                                    >
                                                        {{ 'Eigene Eingabe' }}
                                                    </label>
                                                    <v-textarea
                                                        filled
                                                        rounded
                                                        auto-grow
                                                        rows="1"
                                                        :disabled="viewOnly"
                                                        placeholder="EIGENE EINGABE"
                                                        :rules="rules.required"
                                                        v-if="
                                                            question.additionalFieldAnswer[option] &&
                                                            question.additionalFieldAnswer[option]['ownEntry']
                                                        "
                                                        v-model="question.additionalFieldAnswer[option]['ownEntry']"
                                                    ></v-textarea>
                                                    <v-textarea
                                                        filled
                                                        rounded
                                                        rows="1"
                                                        auto-grow
                                                        :disabled="viewOnly"
                                                        placeholder="EIGENE EINGABE"
                                                        :rules="rules.required"
                                                        v-if="
                                                            question.additionalFieldAnswer[option] &&
                                                            question.additionalFieldAnswer[option].some((obj) =>
                                                                Object.keys(obj).includes('ownEntry')
                                                            )
                                                        "
                                                        v-model="
                                                            question.additionalFieldAnswer[option][
                                                                question.additionalFieldAnswer[option].findIndex(
                                                                    (obj) => Object.keys(obj).includes('ownEntry')
                                                                )
                                                            ].ownEntry
                                                        "
                                                    ></v-textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </v-row>
                        </div>
                    </v-window-item>
                    <v-window-item :value="filteredPages.length + 2">
                        <v-card-title class="justify-content-center" v-if="settings && settings.disclaimer">
                            Haftungsausschluss
                        </v-card-title>
                        <v-card-text class="text-center">{{ settings && settings.disclaimer }}</v-card-text>
                        <v-card-title class="justify-content-center">Unterschrift Kunde</v-card-title>
                        <signature-pad
                            :key="updateSignature"
                            class="ma-auto"
                            darkMode
                            autoSaveSignature
                            :disabled="viewOnly"
                            :signatureData="viewOnly ? formData.customerSignature : null"
                            @resetDrawing="formData.customerSignature = null"
                            @saveSignature="(signature) => (formData.customerSignature = signature)"
                        ></signature-pad>
                    </v-window-item>
                    <v-window-item v-if="!disableUserFields" :value="filteredPages.length + 3">
                        <v-card-title class="justify-content-center">Unterschrift Trainer</v-card-title>
                        <signature-pad
                            :key="updateSignature"
                            class="ma-auto"
                            darkMode
                            :disabled="viewOnly"
                            :signatureData="viewOnly ? formData.creatorSignature : null"
                            autoSaveSignature
                            @resetDrawing="formData.creatorSignature = null"
                            @saveSignature="(signature) => (formData.creatorSignature = signature)"
                        ></signature-pad>
                    </v-window-item>
                </v-window>
                <v-card-actions class="justify-content-center">
                    <v-btn
                        v-if="anamneseFormStepper > 1"
                        x-large
                        rounded
                        color="grey"
                        class="ma-auto"
                        @click="anamneseFormStepper--"
                    >
                        zurück
                    </v-btn>
                    <v-btn
                        x-large
                        rounded
                        color="primary"
                        class="ma-auto"
                        :loading="submittingAnamnese"
                        v-if="
                            !(
                                viewOnly &&
                                (anamneseFormStepper === filteredPages.length + 3 ||
                                    (disableUserFields && anamneseFormStepper === filteredPages.length + 2))
                            )
                        "
                        @click="validateStepAndGoNext(anamneseFormStepper === 1 ? false : expressAnamnese)"
                        :disabled="!stepFinished"
                    >
                        {{
                            anamneseFormStepper === filteredPages.length + 3 ||
                            (disableUserFields && anamneseFormStepper === filteredPages.length + 2)
                                ? 'Abschliessen'
                                : 'weiter'
                        }}
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
        <register
            @register="newAccountRegistered()"
            :auto-login="false"
            :requirePassword="false"
            @close="showRegisterDialog = false"
            :active="showRegisterDialog"
        ></register>
        <v-btn icon color="grey" @click="setVisibleFalse()" x-large fixed bottom left right class="mx-auto">
            <v-icon x-large>mdi-close-circle-outline</v-icon>
        </v-btn>
    </v-dialog>
</template>

  <script>
import register from '@/views/launchScreen/register.vue'
import userSearch from '@/components/userSearch.vue'
import signaturePad from '@/components/signaturePad/signaturePad.vue'
import api from '@/api'

import { mapActions } from 'vuex'

export default {
    components: {
        register,
        userSearch,
        signaturePad,
    },
    data() {
        return {
            disableUserFields: false,
            updateSignature: 0,
            viewOnly: false,
            settings: {},
            nutrition: false,
            expressAnamnese: false,
            visible: false,
            showRegisterDialog: false,
            anamneseFormStepper: 1,
            submittingAnamnese: false,
            pages: [],
            formData: {
                customerSignature: null,
                creator: null,
                customer: null,
                selection: null,
            },
            rules: {
                required: [(v) => !!v || 'Dieses Feld ist erforderlich'],
                requiredArray: [(v) => !!v.length || 'Mindestens eine Option auswählen'],
            },
        }
    },
    computed: {
        stepFinished() {
            if (this.anamneseFormStepper === 1 && (!this.formData.creator || !this.formData.customer)) return false
            return true
            /*  if (this.registrationFormStepper === 2 && !this.formData.vorname) return false
            if (this.registrationFormStepper === 3 && !this.formData.nachname) return false
            if (
                this.registrationFormStepper === 4 &&
                (!this.formData.adresse_1 || !this.formData.plz || !this.formData.stadt || !this.formData.telefon)
            )
                return false
            if (
                this.registrationFormStepper === 5 &&
                (!this.formData.geschlecht || !this.formData.gewicht || !this.formData.groesse || !this.formData.geburtstag)
            )
                return false
                if (this.registrationFormStepper === 6 && !this.formData.rulesAccepted) return false
                return this.$refs.registerForm.validate() */
        },
        filteredPages() {
            return this.pages.filter((page) => {
                if (this.expressAnamnese) {
                    return page.showInExpress
                }
                return true
            })
        },
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationbarVisibility']),
        validateStepAndGoNext(express) {
            this.expressAnamnese = express
            console.log('hallo')
            if (this.viewOnly) return this.anamneseFormStepper++
            // VALIDATION FOR DEFAULT FORM FIELDS
            if (!this.$refs.anamneseForm.validate()) {
                setTimeout(() => {
                    let errorElement = document.getElementsByClassName('error--text')
                    if (errorElement && errorElement.length > 0) {
                        errorElement[0].scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }, 100)
                return
            }
            console.log('do bini no')
            // VALIDATION FOR SELECTS

            let error = false
            let activePage = this.filteredPages[this.anamneseFormStepper - 2]

            if (
                (this.anamneseFormStepper === this.filteredPages.length + 2 && !this.formData.customerSignature) ||
                (this.anamneseFormStepper === this.filteredPages.length + 3 && !this.formData.creatorSignature)
            ) {
                alert('Bitte unterschreiben Sie das Formular')
                error = true
            }

            if (activePage && activePage.questions) {
                let activeSelectQuestions = activePage.questions.filter((question) => question.type === 'select')
                if (activeSelectQuestions.length > 0) {
                    activeSelectQuestions.forEach((question) => {
                        if (question.required && !question.answer) {
                            alert('Bitte beantworte die Frage: ' + question.title)
                            error = true
                        }
                        if (question.additionalFields) {
                            let additionalAnswerForQuestion = question.additionalFields.find(
                                (field) => field.selection === question.answer
                            )
                            if (
                                additionalAnswerForQuestion &&
                                additionalAnswerForQuestion.required &&
                                !question.additionalFieldAnswer[additionalAnswerForQuestion.selection]
                            ) {
                                alert('Bitte beantworte die Zusatzfrage: ' + additionalAnswerForQuestion.title)
                                error = true
                            }

                            if (
                                additionalAnswerForQuestion &&
                                additionalAnswerForQuestion.required &&
                                question.additionalFieldAnswer[additionalAnswerForQuestion.selection] &&
                                question.additionalFieldAnswer[additionalAnswerForQuestion.selection]['ownEntry'] ===
                                    ' '
                            ) {
                                alert(
                                    'Bitte gib deine eigene Angabe für die Zusatzfrage : ' +
                                        additionalAnswerForQuestion.title +
                                        'an.'
                                )
                                error = true
                            }
                        }
                    })
                }
            }

            if (!error) {
                if (
                    this.anamneseFormStepper === this.filteredPages.length + 3 ||
                    (this.disableUserFields && this.anamneseFormStepper === this.filteredPages.length + 2)
                ) {
                    this.submitAnamnese()
                    return
                }
                this.anamneseFormStepper++
            }
        },
        submitAnamnese() {
            let data = {
                nutrition: this.nutrition,
                customer: this.formData.customer,
                creator: this.formData.creator,
                customerSignature: this.formData.customerSignature,
                creatorSignature: this.formData.creatorSignature,
                selection: this.formData.selection,
                pages: this.filteredPages,
                _id: this.formData._id,
            }
            this.submittingAnamnese = true
            api.submitAnamnese(data).then((response) => {
                this.$toast.success('Der Anamnesebogen wurde erfolgreich erstellt.')

                this.$emit('submitted', this.formData.customer)
                this.setVisibleFalse()
            })
        },
        setVisibleFalse() {
            this.visible = false
            this.anamneseFormStepper = 1
            this.formData = {
                customerSignature: null,
                creator: null,
                customer: null,
                selection: null,
            }
            this.updateSignature++
            this.submittingAnamnese = false
            if (!this.nutrition) {
                this.setNavigationbarVisibility(true)
            }
            this.nutrition = false

            this.$emit('closeAnamnesebogen')
        },
        getAdditionalField(question, option) {
            return question.additionalFields && question.additionalFields.find((field) => field.selection === option)
        },
        getValueForAdditionalField(question, option, multiple = false) {
            if (multiple) {
                return question.additionalFieldAnswer[option] &&
                    question.additionalFieldAnswer[option].some((obj) => Object.keys(obj).includes('ownEntry'))
                    ? question.additionalFieldAnswer[option][
                          question.additionalFieldAnswer[option].findIndex((obj) =>
                              Object.keys(obj).includes('ownEntry')
                          )
                      ].ownEntry
                    : ' '
            } else {
                return question.additionalFieldAnswer[option] && question.additionalFieldAnswer[option]['ownEntry']
                    ? question.additionalFieldAnswer[option]['ownEntry']
                    : ' '
            }
        },
        test(question = null, ownEntry = false) {
            if (question && ownEntry) {
            } else if (question && question.additionalFields.length) {
                console.log('test')
                /*     question.additionalFields.forEach((field) => {
                    question.answer === field.selection && field.allowMultiple
                        ? (question.additionalFieldAnswer[field.selection] = [])
                        : (question.additionalFieldAnswer[field.selection] = null)
                }) */
            }
            this.$forceUpdate()
        },
        resetCustomer() {
            this.formData.customer = null
        },

        async setVisible(id = null, editMode = false, nutrition = false, userID = null, coachID = null) {
            if (nutrition) this.nutrition = true
            this.setNavigationbarVisibility(false)
            this.visible = true
            let data = null
            if (nutrition) {
                data = id ? await api.getAnamneseNutritionForUser(id) : await api.getAnamneseNutrition()
            } else {
                data = id ? await api.getAnamneseForUser(id) : await api.getAnamneseForStudio()
            }
            if (userID) {
                this.formData.customer = userID
            }
            if (coachID) {
                this.formData.creator = coachID
                if (nutrition) {
                    this.disableUserFields = true
                    this.anamneseFormStepper = 2
                }
            }
            if (!data.pages || !data.pages.length)
                alert('Bitte prüfe dein Studio. In diesem Studio ist kein Anamnesebogen hinterlegt.')
            this.pages = data.pages
            if (id) {
                this.formData = {
                    _id: id,
                    customer: data.customer,
                    creator: data.creator,
                    customerSignature: data.customerSignature,
                    creatorSignature: data.creatorSignature,
                }
                if (editMode) {
                    delete this.formData.customerSignature
                    delete this.formData.creatorSignature
                } else {
                    this.viewOnly = true
                }
            } else {
                this.pages.forEach((page) => {
                    page.questions.forEach((question) => {
                        question.additionalFieldAnswer = {}
                        if (question.allowMultiple) question.answer = []
                    })
                })
            }
            this.settings = data.settings
        },
        newAccountRegistered() {
            this.showRegisterDialog = false
            this.$toast.success('Neuer Account erfolgreich erstellt. Du kannst den Kunden nun auswählen')
        },
        updateUser(id, type) {
            this.formData[type] = id
        },
    },
}
</script>
  
  <style scoped lang="scss">
$clouds: #ecf0f1;
$midnight: var(--v-primary);
$wisteria: white;

body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: $wisteria;
    color: white;
}
header {
    position: fixed;
    top: 0;
    padding: 1em;
    background: var(--v-primary);
    a {
        color: $wisteria;
        font-weight: bold;
    }
}
h1 {
    margin: 0 0 1em;
}
.group {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
}
input[type='checkbox'],
input[type='radio'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
}
label {
    position: relative;
    margin-right: 1em;
    padding-left: 2em;
    padding-right: 1em;
    line-height: 2;
    cursor: pointer;
    &:before {
        box-sizing: border-box;
        content: ' ';
        position: absolute;
        top: 0.3em;
        left: 0;
        display: block;
        width: 1.4em;
        height: 1.4em;
        border: 2px solid var(--v-primary);
        border-radius: 0.25em;
        z-index: -1;
    }
}
input[type='radio'] + label::before {
    border-radius: 1em;
}
.selectsDisabled + label {
    cursor: not-allowed;
    &:before {
        border: 2px solid white;
        background: grey !important;
    }
}
/* Checked */
input[type='checkbox']:checked + label,
input[type='radio']:checked + label {
    padding-left: 1em;
    color: $wisteria;
    &:before {
        top: 0;
        width: 100%;
        height: 2em;
        background: var(--v-primary);
    }
}

/* Transition */
label,
label::before {
    -webkit-transition: 0.25s all ease;
    -o-transition: 0.25s all ease;
    transition: 0.25s all ease;
}
</style>