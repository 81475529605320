<template>
    <div>
        <v-img class="recipe__image" :src="recipe.media.preview[0]" height="200"></v-img>
        <v-row no-gutters v-if="recipe">
            <v-col cols="12">
                <v-card-title class="mb-1 h4">
                    <span>
                        Nährwerte
                        <span class="h6 font-weight-bold">(Portion)</span>
                    </span>
                </v-card-title>
            </v-col>
            <v-col
                cols="4"
                class="text-center pa-3 pt-0 rounded-xl d-flex flex-column align-items-center justify-space-around"
            >
                <v-progress-circular
                    :value="getRecipeNutritionValues('protein', 'Eiweiß (Protein)')"
                    size="80"
                    width="6"
                    color="protein"
                >
                    <span style="font-size: 11px">
                        {{
                            recipe.substances.find((sub) => sub.substance === 'Eiweiß (Protein)').portion.amount +
                            ' / ' +
                            optimalSubstances[this.substanceMapper['protein']].value
                        }}
                    </span>
                </v-progress-circular>
                <span class="protein--text">Protein</span>
            </v-col>
            <v-col
                cols="4"
                class="text-center pa-3 pt-0 rounded-xl d-flex flex-column align-items-center justify-space-around"
            >
                <v-progress-circular width="6" :value="getRecipeNutritionValues('fat', 'Fett')" size="80" color="fat">
                    <span style="font-size: 11px">
                        {{
                            recipe.substances.find((sub) => sub.substance === 'Fett').portion.amount +
                            ' / ' +
                            optimalSubstances[this.substanceMapper['fat']].value
                        }}
                    </span>
                </v-progress-circular>
                <span class="fat--text">Fett</span>
            </v-col>
            <v-col
                cols="4"
                class="text-center pa-3 pt-0 rounded-xl d-flex flex-column align-items-center justify-space-around"
            >
                <v-progress-circular
                    width="6"
                    :value="getRecipeNutritionValues('carbohydrates,available', 'Kohlenhydrate, resorbierbar')"
                    size="80"
                    color="carbs"
                >
                    <span style="font-size: 11px">
                        {{
                            recipe.substances.find((sub) => sub.substance === 'Kohlenhydrate, resorbierbar').portion
                                .amount +
                            ' / ' +
                            optimalSubstances[this.substanceMapper['carbohydrates,available']].value
                        }}
                    </span>
                </v-progress-circular>
                <span class="carbs--text">Carbs</span>
            </v-col>
        </v-row>
        <v-row no-gutters v-if="recipe && recipe.ingredients">
            <v-col cols="6">
                <v-card-title class="mb-1 h4">
                    <span>Zutaten</span>
                </v-card-title>
            </v-col>
            <v-col cols="6" class="align-self-center">
                <div>
                    <v-btn icon @click="serving--"><v-icon>mdi-minus</v-icon></v-btn>
                    {{ serving }} Portionen
                    <v-btn icon @click="serving++"><v-icon>mdi-plus</v-icon></v-btn>
                </div>
            </v-col>
            <v-col class="pa-1" cols="auto" :key="'+' + index" v-for="(ingredient, index) of recipe.ingredients">
                <span class="h6">
                    <v-chip>
                        {{
                            ingredient.quantity1
                                ? Math.round(ingredient.quantity1 * portionMultiplicator * 100) / 100
                                : ''
                        }}
                        {{
                            ingredient.unit_short +
                            ' ' +
                            ingredient.item_attribute +
                            ' ' +
                            ingredient.product +
                            ' ' +
                            ingredient.item_addition
                        }}
                    </v-chip>
                </span>
            </v-col>
            <v-col cols="12">
                <v-sheet class="mx-auto" max-width="800">
                    <v-slide-group v-model="slideGroup" class="pa-4" center-active :show-arrows="false">
                        <v-slide-item
                            v-for="(step, index) of recipe.steps"
                            :key="'step' + index"
                            v-slot="{ active, toggle }"
                        >
                            <v-card
                                :color="active ? 'grey lighten-1' : 'grey lighten-3'"
                                class="ma-4 pa-2 rounded-xl"
                                heimin-height="200"
                                width="250"
                                @click="toggle"
                            >
                                <v-row class="fill-height" align="center" justify="center">
                                    <v-scale-transition>
                                        <div class="d-flex fill-height flex-column justify-space-between">
                                            <v-card-title>{{ 'Schritt ' + (index + 1) }}</v-card-title>
                                            <v-card-text>{{ step.step_text }}</v-card-text>
                                            <div></div>
                                        </div>
                                    </v-scale-transition>
                                </v-row>
                            </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </v-col>
            <v-card-actions style="width: 100%" class="justify-content-center d-flex flex-column">
                <span>
                    Nährwerte
                    <v-btn icon x-small @click="adjustNutrientsActive = !adjustNutrientsActive">
                        <v-icon :color="adjustNutrientsActive ? 'red' : 'blue'">
                            {{ adjustNutrientsActive ? 'mdi-close' : 'mdi-pencil' }}
                        </v-icon>
                    </v-btn>
                </span>
                <div v-if="adjustNutrientsActive">
                    <v-text-field
                        label="Protein"
                        filled
                        type="number"
                        rounded
                        v-model="recipe.substances.find((sub) => sub.substance === 'Eiweiß (Protein)').portion.amount"
                        suffix="g"
                    ></v-text-field>
                    <v-text-field
                        label="Carbs"
                        filled
                        type="number"
                        rounded
                        v-model="
                            recipe.substances.find((sub) => sub.substance === 'Kohlenhydrate, resorbierbar').portion
                                .amount
                        "
                        suffix="g"
                    ></v-text-field>
                    <v-text-field
                        label="Fett"
                        filled
                        type="number"
                        rounded
                        v-model="recipe.substances.find((sub) => sub.substance === 'Fett').portion.amount"
                        suffix="g"
                    ></v-text-field>
                </div>
                <v-text-field
                    v-model="portionSize"
                    label="Portionen"
                    type="number"
                    filled
                    rounded
                    :suffix="Math.round(calories) + 'kcal'"
                ></v-text-field>

                <v-btn x-large class="rounded-xl" @click="addRecipeEntry" color="primary">Hinzufügen</v-btn>
            </v-card-actions>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import api from '../../api'

export default {
    props: {
        date: {},
        recipe: {},
        optimalSubstances: {},
        mealValue: {},
    },
    name: 'nutritionMealDialogSelectedRecipe',
    components: {},
    data() {
        return {
            portionSize: 1,
            adjustNutrientsActive: false,
            slideGroup: null,
            steps: null,
            serving: 0,
            substanceMapper: {
                'carbohydrates,available': 'carbs',
                fat: 'fat',
                protein: 'protein',
            },
        }
    },
    created() {
        if (this.recipe && this.recipe.recipe && this.recipe.recipe.serving) {
            this.serving = JSON.parse(JSON.stringify(this.recipe.recipe.serving))
        }
    },
    computed: {
        calories() {
            let nutritions = {
                carbohydrates: Number(
                    this.recipe.substances.find((sub) => sub.substance === 'Kohlenhydrate, resorbierbar').portion.amount
                ),
                proteins: Number(
                    this.recipe.substances.find((sub) => sub.substance === 'Eiweiß (Protein)').portion.amount
                ),
                fat: Number(this.recipe.substances.find((sub) => sub.substance === 'Fett').portion.amount),
            }
            return (nutritions.carbohydrates * 4 + nutritions.proteins * 4 + nutritions.fat * 9) * this.portionSize
        },
        portionMultiplicator() {
            if (this.recipe && this.recipe.recipe && this.recipe.recipe.serving) {
                return this.serving / this.recipe.recipe.serving
            } else {
                return 1
            }
        },
    },
    methods: {
        async addRecipeEntry() {
            let recipeToSave = {}
            recipeToSave.meal = this.mealValue
            recipeToSave.title = this.recipe.recipe.title

            recipeToSave.img = this.recipe.media.preview[0]

            recipeToSave.nutritions = {
                carbohydrates: Math.round(
                    Number(
                        this.recipe.substances.find((sub) => sub.substance === 'Kohlenhydrate, resorbierbar').portion
                            .amount
                    ) * this.portionSize
                ),
                proteins:
                    Math.round(
                        Number(
                            this.recipe.substances.find((sub) => sub.substance === 'Eiweiß (Protein)').portion.amount
                        )
                    ) * this.portionSize,
                fat: Math.round(
                    Number(this.recipe.substances.find((sub) => sub.substance === 'Fett').portion.amount) *
                        this.portionSize
                ),
            }
            recipeToSave.date = this.date
            let data = await api.addNewNutritionEntry(recipeToSave)
            recipeToSave.time = this.$moment().format('HH:mm:ss')
            recipeToSave.calories =
                recipeToSave.nutritions.carbohydrates * 4 +
                recipeToSave.nutritions.proteins * 4 +
                recipeToSave.nutritions.fat * 9

            this.$toast.success('Eintrag erfolgreich hinzugefügt')
            this.$emit('close', recipeToSave)
        },
        getRecipeNutritionValues(substance, subGer) {
            return Math.round(
                (100 / Number(this.optimalSubstances[this.substanceMapper[substance]].value)) *
                    this.recipe.substances.find((sub) => sub.substance === subGer).portion.amount
            )
        },
        getRecipeStars() {
            if (this.recipe && this.recipe.recipe && this.recipe.recipe.difficulty) {
                return this.recipe.recipe.difficulty === 'einfach' ? 1 : recipe.difficulty === 'schwierig' ? 3 : 2
            }
            return 0
        },
    },
}
</script>
<style lang="scss" scoped>
.recipe {
    &__image {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}
</style>