import { render, staticRenderFns } from "./nutritionRecipes.vue?vue&type=template&id=c146a14a&scoped=true&"
import script from "./nutritionRecipes.vue?vue&type=script&lang=js&"
export * from "./nutritionRecipes.vue?vue&type=script&lang=js&"
import style0 from "./nutritionRecipes.vue?vue&type=style&index=0&id=c146a14a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c146a14a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VBtn,VCard,VCardSubtitle,VCardTitle,VCol,VDialog,VIcon,VImg,VRating,VRow})
