var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items-per-page":40,"no-data-text":"Keine Einkäufe gefunden","headers":_vm.tableHeaders,"hide-default-footer":"","custom-filter":_vm.filterPurchases,"expanded":_vm.expanded,"item-key":'_id',"dark":"","search":_vm.search,"show-expand":"","single-expand":true,"items":_vm.purchases},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.user.firstName",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.$router.push('/dashboard/user/search/' + item.user._id)}}},[_vm._v(" "+_vm._s(item.user.firstName)+" ")])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.amount + ' CHF')+" ")])]}},{key:"item.inTAC",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.products.find(function (product) { return !product.inTac; }) ? 'orange' : 'primary'}},[_vm._v(" "+_vm._s(item.products.find(function (product) { return !product.inTac; }) ? 'Übertragung OFFEN' : 'Abgeschlossen')+" ")])]}},{key:"item.redeemed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.products.find(function (product) { return !product.redeemed; }) ? 'orange' : 'success'}},[_vm._v(" "+_vm._s(item.products.filter(function (product) { return product.redeemed; }).length + '/' + item.products.length)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.tableHeaders.length}},[_c('v-data-table',{attrs:{"items-per-page":10,"no-data-text":"Keine Produkte gefunden","headers":_vm.innerTableHeaders,"hide-default-footer":"","dark":"","items":item.products},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{on:{"click":function($event){return _vm.copyCode(item.code)}}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.inTac",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"disabled":item.inTac,"color":item.inTac ? 'success' : 'orange'},on:{"click":function($event){return _vm.startTACSavingDialog(item)}}},[_vm._v(" "+_vm._s(item.inTac ? 'Übertragen' : 'jetzt Übertragen')+" ")])]}},{key:"item.tacCode",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{on:{"click":function($event){return _vm.copyCode(item.tacCode)}}},[_vm._v(" "+_vm._s(item.tacCode ? item.tacCode : '-')+" ")])]}},{key:"item.redeemed",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":item.redeemed ? 'success' : 'orange'},on:{"click":function($event){return _vm.savedItemInTac(item, 'redeemed')}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.redeemed ? 'Eingelöst' : 'jetzt einlösen')+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.email)+" ")])]}}],null,true)})],1)]}}])}),_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"justify-content-center"},[_vm._v("Speichern...")]),_c('v-progress-circular',{attrs:{"size":100,"width":10,"color":"primary","indeterminate":""}})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.tacSavingDialog),callback:function ($$v) {_vm.tacSavingDialog=$$v},expression:"tacSavingDialog"}},[_c('v-card',{attrs:{"dark":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"justify-content-center"},[_vm._v(" Gebe die Nummer des Gutscheins im TAC ein. ")]),_c('v-text-field',{attrs:{"label":"TAC Gutschein Code"},model:{value:(_vm.tacVoucherCode),callback:function ($$v) {_vm.tacVoucherCode=$$v},expression:"tacVoucherCode"}}),_c('v-btn',{on:{"click":function($event){return _vm.savedItemInTac(_vm.presavedItem, 'inTac', _vm.tacVoucherCode)}}},[_vm._v("Speichern")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }