<template>
    <v-card v-if="!createdTACContract" max-width="900px" class="mx-auto mt-5" dark flat>
        <v-row>
            <v-col v-show="false" align="center" cols="12">
                <v-btn text @click="fetchMemberCategories">TAC Daten fetchen</v-btn>
                <v-btn text @click="setStudioIds">TAC IDS setzen für studios</v-btn>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="12" class="pb-0" v-if="showRoodyContractInfos">
                <v-card-title class="pb-0">Roody Vertrag</v-card-title>

                <v-card-subtitle class="py-0 my-0">
                    {{ contract.category.selection.title }}
                </v-card-subtitle>

                <v-card-subtitle class="py-0 my-0">
                    {{ contract.membership.membershipName }}
                </v-card-subtitle>

                <v-card-subtitle class="py-0 my-0">
                    {{ $moment(contract.startDate).format('DD.MM.YYYY') }} bis
                    {{
                        $moment(contract.startDate)
                            .add(parseInt(contract.duration.months), 'months')
                            .add(parseInt(contract.duration.years), 'years')
                            .format('DD.MM.YYYY')
                    }}
                </v-card-subtitle>

                <v-card-subtitle class="py-0 mt-0 mb-2">
                    Zusatzpakete: {{ contract.packages.map((e) => e.amount + 'x ' + e.title).join(', ') }}
                </v-card-subtitle>

                <v-card-subtitle v-if="contract.membership.giftSignUpFee" class="py-0 my-0">
                    Aktivierungs-Tarif geschenkt
                </v-card-subtitle>

                <v-card-subtitle v-if="contract.membership.renewalDiscount" class="py-0 my-0">
                    Wiedereinschreibe-Rabatt
                    <span v-if="contract.membership.renewalDiscount">
                        CHF {{ contract.membership.renewalDiscountAmount }}.-
                    </span>
                </v-card-subtitle>

                <v-card-subtitle v-if="contract.category.isAffiliate" class="pb-0 pt-2 my-0">
                    Weiterempfehlung: {{ contract.category.affiliateInfos.firstName }}
                    {{ contract.category.affiliateInfos.lastName }} ({{ contract.category.affiliateInfos.email }})
                </v-card-subtitle>

                <v-card-subtitle v-if="contract.category.isTakeover" class="py-0 my-0">
                    Übernahmevertrag!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! Domi Fragen
                </v-card-subtitle>

                <v-card-subtitle v-if="contract.hasPurchasedPersonalTraining" class="py-0 my-0">
                    Personal Training gekauft
                </v-card-subtitle>

                <v-card-subtitle class="pt-2 pb-0">
                    Visum: {{ contract.users.creatorInfos.firstName }} {{ contract.users.creatorInfos.lastName }} ({{
                        contract.users.creatorInfos.email
                    }})
                </v-card-subtitle>
            </v-col>

            <v-col cols="12">
                <v-window v-model="step">
                    <v-window-item :value="1">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-col v-if="contract && contract.users && contract.users.customerInfos" cols="12">
                                    <v-row>
                                        <v-col class="px-0 pt-0" cols="12">
                                            <v-card-title class="pt-0 pb-0">
                                                Kundeninformationen vom Vertrag
                                            </v-card-title>
                                            <v-alert class="mt-2 mx-3" dense type="info">
                                                Kundeninfos anklicken, um den Text zu kopieren
                                            </v-alert>
                                            <v-card-subtitle class="my-0 py-0">
                                                Geschlecht: {{ contract.users.customerInfos.gender }}
                                            </v-card-subtitle>
                                            <v-card-subtitle class="my-0 py-0">
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.firstName)"
                                                >
                                                    {{ contract.users.customerInfos.firstName }}
                                                </span>
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.lastName)"
                                                >
                                                    {{ contract.users.customerInfos.lastName }}
                                                </span>
                                            </v-card-subtitle>
                                            <v-card-subtitle class="my-0 py-0">
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.email)"
                                                >
                                                    {{ contract.users.customerInfos.email }}
                                                </span>
                                            </v-card-subtitle>
                                            <v-card-subtitle class="my-0 py-0">
                                                {{
                                                    $moment(contract.users.customerInfos.birthday).format('DD.MM.YYYY')
                                                }}
                                            </v-card-subtitle>

                                            <v-card-subtitle
                                                v-if="contract.users.customerInfos.legalRepresentativeText"
                                                class="my-0 py-0"
                                            >
                                                <span
                                                    class="copyable"
                                                    @click="
                                                        copyText(contract.users.customerInfos.legalRepresentativeText)
                                                    "
                                                >
                                                    {{ contract.users.customerInfos.legalRepresentativeText }}
                                                </span>
                                            </v-card-subtitle>

                                            <v-card-subtitle class="my-0 py-0">
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.phone)"
                                                >
                                                    {{ contract.users.customerInfos.phone }}
                                                </span>
                                            </v-card-subtitle>
                                            <v-card-subtitle class="my-0 py-0">
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.street)"
                                                >
                                                    {{ contract.users.customerInfos.street }}
                                                </span>
                                            </v-card-subtitle>
                                            <v-card-subtitle class="my-0 py-0">
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.zip)"
                                                >
                                                    {{ contract.users.customerInfos.zip }}
                                                </span>
                                                <span
                                                    class="copyable"
                                                    @click="copyText(contract.users.customerInfos.city)"
                                                >
                                                    {{ contract.users.customerInfos.city }}
                                                </span>
                                            </v-card-subtitle>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-divider></v-divider>
                            </v-col>

                            <v-col cols="12">
                                <profile-handler
                                    class="mx-5"
                                    :contractMapMode="true"
                                    v-if="step === 1 && contract && contract.users"
                                    :userInfosToCompare="contract.users.customerInfos"
                                    :user="userDataForSearch"
                                    :mode="profileHandlerMode"
                                    :userTACId="userTACId"
                                    @createNewUser="profileHandlerMode = 'createNewUser'"
                                    @newAccountRegistered="profileHandlerMode = 'searchProfileOrCreate'"
                                    @openProfileForEdit="profileHandlerMode = 'editProfile'"
                                    @userSelectedReturnId="userSelectedForContract"
                                    @userSelectedReturnAllInfo="userSelectedForContractAllInfo"
                                ></profile-handler>
                            </v-col>
                            <v-col v-if="userTACId || profileHandlerMode === 'editProfile'" align="center" cols="12">
                                <v-btn
                                    text
                                    @click="
                                        () => {
                                            userTACId = null
                                            profileHandlerMode = 'searchProfileOrCreate'
                                        }
                                    "
                                >
                                    Kunde ändern
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-subtitle class="pb-0 pt-0">Mitgliedschaft auswählen</v-card-subtitle>
                        <v-row>
                            <v-col v-if="formData.membership.selectedMembershipKey" cols="12">
                                <v-row>
                                    <v-col align="center" cols="12">
                                        <v-btn text @click="initFormData()">zurück</v-btn>
                                    </v-col>
                                    <v-col cols="12">
                                        <v-alert type="info">
                                            Ausgewählte Kategorie: {{ formData.membership.selectedMembershipKey }}
                                        </v-alert>
                                    </v-col>
                                    <v-col v-if="tacOptions" cols="12">
                                        <v-list dense>
                                            <v-list-item-group color="primary">
                                                <v-list-item
                                                    v-for="(membership, i) of tacOptions.tacMembershipOptions[
                                                        formData.membership.selectedMembershipKey
                                                    ]"
                                                    :key="i + '-membershipOptions'"
                                                    @click="selectMembership(membership)"
                                                >
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="membership.name"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-else cols="12">
                                <v-list v-if="tacOptions" dense>
                                    <v-list-item-group color="primary">
                                        <v-list-item
                                            v-for="(membershipKey, i) of Object.keys(tacOptions.tacMembershipOptions)"
                                            :key="i + '-membershipOptions'"
                                            @click="formData.membership.selectedMembershipKey = membershipKey"
                                        >
                                            <v-list-item-content>
                                                <v-list-item-title v-text="membershipKey"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-divider></v-divider>

                        <v-row no-gutters>
                            <v-col cols="12" class="py-0">
                                <v-card-title class="py-0">Aktuelles Abonemment</v-card-title>
                            </v-col>
                            <v-col v-if="step === 3 && userTACId" cols="12">
                                <view-current-member-details :tacID="userTACId"></view-current-member-details>
                            </v-col>
                            <v-col v-if="showRoodyContractInfos" cols="12" class="mb-0 py-0">
                                <v-divider></v-divider>
                                <v-card-title class="pb-0 pt-0">TAC Vertrag (wird erstellt)</v-card-title>
                            </v-col>
                            <v-col cols="12" v-if="contract.category.isAffiliate && !affiliateTACId">
                                <v-row no-gutters>
                                    <v-col cols="12" class="mb-3 px-5 pt-5">
                                        <v-alert type="info">
                                            Weiterempfehlung: {{ contract.category.affiliateInfos.firstName }}
                                            {{ contract.category.affiliateInfos.lastName }} ({{
                                                contract.category.affiliateInfos.email
                                            }})
                                        </v-alert>
                                        <v-card-text>
                                            Bitte wähle den Versender der Weiterempfehlung aus dem TAC aus:
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12">
                                        <profile-handler
                                            class="mx-5"
                                            :user="{
                                                FirstName: contract.category.affiliateInfos.firstName,
                                                LastName: contract.category.affiliateInfos.lastName,
                                            }"
                                            mode="searchProfileAndReturnAllData"
                                            :userTACId="affiliateTACId"
                                            @userSelectedReturnAllInfo="userSelectedForContractAffiliate"
                                        ></profile-handler>
                                    </v-col>
                                    <v-col cols="12" align="center">
                                        <v-btn @click="disableAffiliate" color="orange">
                                            keine Weiterempfehlung hinterlegen
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="!contract.category.isAffiliate || affiliateTACId" no-gutters>
                            <v-col cols="12">
                                <v-card-subtitle class="font-weight-bold pb-0 pt-0">Kunde:</v-card-subtitle>
                            </v-col>
                            <v-col cols="12">
                                <v-card-text class="pt-0">
                                    {{ mapTACDataReadable(userTACIdAllInfos).id }}
                                    <br />
                                    {{ mapTACDataReadable(userTACIdAllInfos).FirstName }}
                                    {{ mapTACDataReadable(userTACIdAllInfos).LastName }}
                                    <br />
                                    {{ mapTACDataReadable(userTACIdAllInfos).AccountName }}
                                    <br />
                                    {{ mapTACDataReadable(userTACIdAllInfos).street }}
                                    <br />
                                    {{ mapTACDataReadable(userTACIdAllInfos).zip }}
                                    {{ mapTACDataReadable(userTACIdAllInfos).city }}
                                    <br />
                                </v-card-text>
                            </v-col>
                            <v-col v-if="affiliateTACId" cols="12">
                                <v-card-subtitle class="font-weight-bold">Weiterempfehlung:</v-card-subtitle>
                            </v-col>

                            <v-col class="d-flex" v-if="affiliateTACId" cols="3">
                                <v-btn class="ma-auto" color="orange" @click="affiliateTACId = null">Ändern</v-btn>
                            </v-col>
                            <v-col class="d-flex" v-if="affiliateTACId && affiliateUserInfos" cols="9">
                                <span class="my-auto">
                                    Ausgewählt aus dem TAC: {{ affiliateTACId }} {{ affiliateUserInfos.FirstName }}
                                    {{ affiliateUserInfos.LastName }}
                                </span>
                            </v-col>
                            <v-col
                                class="d-flex mt-5 mb-0 px-5"
                                v-if="affiliateTACId && affiliateMemberCategoryName"
                                cols="12"
                            >
                                <v-card-subtitle class="mb-0 pb-0" v-if="affiliateMemberCategoryName">
                                    Mitgliedschaft des Werbers: {{ affiliateMemberCategoryName }}
                                </v-card-subtitle>
                            </v-col>
                            <v-col
                                class="d-flex my-0 px-5"
                                v-if="affiliateTACId && affiliateMemberResortName"
                                cols="12"
                            >
                                <v-card-subtitle v-if="affiliateMemberResortName">
                                    Studio des Werbers: {{ affiliateMemberResortName }}
                                </v-card-subtitle>
                            </v-col>
                            <v-col class="d-flex mt-5" v-if="affiliateTACId" cols="12">
                                <v-text-field
                                    class="px-5"
                                    type="number"
                                    v-model="affiliateAmount"
                                    label="Betrag zur Aufbuchung für den Werber"
                                ></v-text-field>
                            </v-col>
                            <v-col class="d-flex mb-2" v-if="false && affiliateTACId" cols="12">
                                <v-select
                                    class="px-5"
                                    :items="reasons"
                                    v-model="affiliateTransactionReason"
                                    label="Kategorie der Aufbuchung"
                                    item-text="text"
                                    item-value="id"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-card-subtitle class="font-weight-bold pb-0 pt-0">Mitgliedschaft:</v-card-subtitle>

                                <v-card-subtitle class="my-0 pt-0 py-0">
                                    {{ formData.membership.selectedMembershipName }}

                                    <v-btn icon color="orange" @click="initFormData"><v-icon>mdi-pencil</v-icon></v-btn>
                                </v-card-subtitle>
                                <v-card-subtitle class="my-0 py-0">
                                    Laufzeit: {{ $moment(tacContract.ContractStartDate).format('DD.MM.YYYY') }} bis
                                    {{
                                        $moment(tacContract.ContractStartDate)
                                            .add(formData.membership.commitmentPeriod, 'months')
                                            .format('DD.MM.YYYY')
                                    }}
                                </v-card-subtitle>
                            </v-col>

                            <v-col class="pb-0 pt-3" cols="12">
                                <v-card-subtitle class="my-0 py-0">
                                    Zusatzpakete
                                    <v-btn icon color="orange" @click="editPackageMappingDialog = true">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-card-subtitle>
                            </v-col>
                            <v-col class="px-2 pt-2" cols="12">
                                <v-chip class="mx-1" v-for="packageId of formData.packages" :key="packageId">
                                    {{
                                        tacOptions.tacPackageOptions.find((e) => e.memberCategoryID === packageId)
                                            .name || 'Paket nicht gefunden'
                                    }}
                                </v-chip>
                            </v-col>
                            <v-col v-if="false" align="center" cols="12">
                                <v-btn text>Zusatzpakete bearbeiten (mapping)</v-btn>
                            </v-col>

                            <v-col class="pt-3" cols="12">
                                <v-select
                                    class="mx-5"
                                    v-model="formData.membership.selectedPromotion"
                                    :items="
                                        [
                                            {
                                                name: '--- Keine Promotion ---',
                                                MemberPromotionID: null,
                                            },
                                        ].concat(formData.membership.promotions)
                                    "
                                    item-text="name"
                                    item-value="MemberPromotionID"
                                    label="Promotionen"
                                    chips
                                    persistent-hint
                                ></v-select>
                            </v-col>
                            <v-col cols="12" class="px-7">
                                <v-switch
                                    v-model="activateContract"
                                    color="primary"
                                    label="Vertrag im TAC aktivieren"
                                ></v-switch>
                            </v-col>
                        </v-row>
                    </v-window-item>
                </v-window>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn :disabled="step === 1" text @click="backStepHandler">Zurück</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="step === 1 || (step === 3 && contract.category.isAffiliate && !affiliateTACId)"
                        color="primary"
                        :loading="savingTAC"
                        depressed
                        @click="nextStepHandler"
                    >
                        <span v-if="step === 3">Vertrag im TAC erstellen</span>
                        <span v-else>Weiter</span>
                    </v-btn>
                </v-card-actions>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col align="center" cols="12">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </v-col>
        </v-row>
        <v-dialog persistent max-width="500px" v-model="editPackageMappingDialog">
            <v-card v-if="editPackageMappingDialog" light>
                <v-card-title style="background-color: var(--v-primary)" class="white--text">
                    Zusatzpakete bearbeiten (mapping)
                </v-card-title>

                <v-card-text class="mt-5">Bitte wähle hier die Übereinstimmenden Zusatzpakete aus.</v-card-text>

                <v-row no-gutters>
                    <v-col
                        v-for="(pck, index) of contract.packages"
                        :key="index + '-packageMapIndex'"
                        class="mt-0 mb-3 py-0"
                        cols="12"
                    >
                        <v-row no-gutters>
                            <v-col cols="4" class="px-5 d-flex">
                                <span class="my-auto">{{ pck.amount }}x {{ pck.title }}</span>
                            </v-col>
                            <v-col cols="8">
                                <v-select
                                    hide-details
                                    class="mx-5 py-0"
                                    v-model="pck.memberCategoryID"
                                    :items="
                                        tacOptions.tacPackageOptions
                                            .filter((e) => e.commitmentPeriod === formData.membership.commitmentPeriod)
                                            .map((e) => {
                                                return {
                                                    name: e.name,
                                                    memberCategoryID: e.memberCategoryID,
                                                }
                                            })
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                    "
                                    item-text="name"
                                    item-value="memberCategoryID"
                                    label="Zusatzpaket TAC"
                                    chips
                                    persistent-hint
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

                <v-card-actions>
                    <v-btn disabled text @click="editPackageMappingDialog = false">Abbrechen</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="savePackageMapping" color="primary">Stimmt so</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="showCreationUserIdInfo" max-width="500px">
            <v-card flat>
                <v-card-title class="white--text" style="background-color: var(--v-primary)">
                    TAC Sync Problem
                </v-card-title>
                <v-card-text class="mt-5">
                    Bitte prüfe, ob im TAC bei deinem Profil im Kundenkonto die gleiche E-Mail wie in roody hinterlegt
                    ist. Falls nicht, passe bitte die E-Mail an und klicke auf neuladen.
                </v-card-text>
                <v-card-actions class="d-flex">
                    <v-btn class="mx-auto" color="primary" @click="reload()">Neuladen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
    <v-card v-else max-width="900px" class="mx-auto mt-5" dark flat>
        <v-row>
            <v-col cols="12">
                <v-card-title label="Mail Text">Abschluss</v-card-title>
            </v-col>

            <v-col cols="12">
                <v-alert class="mx-5" type="info">
                    Der Vertrag wurde erfolgreich im TAC angelegt, du kannst diesen Tab nun schliessen
                </v-alert>
            </v-col>
            <v-col cols="12" class="px-5">
                <v-text-field class="mx-5" label="Betreff" v-model="mailSubject"></v-text-field>
            </v-col>
            <v-col cols="12" class="px-5">
                <v-text-field type="email" class="mx-5" label="E-Mail" v-model="mailAddress"></v-text-field>
            </v-col>
            <v-col cols="12" class="px-5">
                <v-textarea class="mx-5" label="Mail Inhalt" v-model="mailText"></v-textarea>
            </v-col>
            <v-col cols="12">
                <pdf-merger></pdf-merger>
            </v-col>
        </v-row>
        <v-card-actions>
            <v-btn @click="createdTACContract = false">Zurück</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="sendMail" color="primary">Outlook öffnen</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import api from '@/api'
import userService from '@/services/userService.js'
import pdfMerger from '@/views/tools/pdfMerger.vue'
import profileHandler from '@/views/dashboard/tac/components/profile/profileHandler.vue'
import viewCurrentMemberDetails from '@/views/dashboard/tac/components/contract/viewCurrentMemberDetails.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
export default {
    components: {
        profileHandler,
        viewCurrentMemberDetails,
        pdfMerger,
    },
    data() {
        return {
            userService,
            step: 1,
            formData: {},
            showRoodyContractInfos: false,
            loading: true,
            tacContract: null,
            contract: null,
            tacOptions: null,
            userDataForSearch: null,
            profileHandlerMode: 'searchProfileOrCreate',
            userTACId: null,
            userTACIdAllInfos: null,
            affiliateTACId: null,
            affiliateContractID: null,
            affiliateAccountID: null,
            affiliateUserInfos: null,
            cachedPackages: null,
            editPackageMappingDialog: false,
            affiliateAmount: 50,
            activateContract: true,
            createdTACContract: false,
            creationUserId: null,
            showCreationUserIdInfo: false,
            fetchingProfile: false,
            userMemberDetails: null,
            mailText: '',
            mailSubject: '',
            mailAddress: '',
            savingTAC: false,
            affiliateMemberCategoryName: null,
            affiliateMemberResortId: null,
            affiliateMemberResortName: null,
            //reasons: [],
            //  affiliateTransactionReason: null,
        }
    },
    created() {
        let creationUserId = this.userService.getTACUserId()

        if (!creationUserId) {
            this.showCreationUserIdInfo = true
        } else {
            this.creationUserId = creationUserId
        }
        this.init()
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
    },
    watch: {
        step() {
            if (this.step === 3) {
                this.checkPackages()
            }

            if (this.step === 2) {
                if (this.formData.membership.selectedMembershipID) {
                    this.step = 3
                }
            }

            this.showRoodyContractInfos = this.step !== 1
        },
    },
    methods: {
        disableAffiliate() {
            if (
                confirm(
                    'Möchtest du die Weiterempfehlung deaktivieren? Muss anschliessend manuell ins TAC eingepflegt werden'
                )
            ) {
                this.contract.category.isAffiliate = false
                this.affiliateTACId = null
                this.affiliateContractID = null
            }
        },
        async copyText(text) {
            try {
                await navigator.clipboard.writeText(text)
                this.$toast.success('Text in die Zwischenablage kopiert')
            } catch (error) {
                console.error(error.message)
            }
        },
        sendMail() {
            const subject = encodeURIComponent(this.mailSubject)
            const body = encodeURIComponent(this.mailText)
            window.location.href = `mailto:${this.mailAddress}?subject=${subject}&body=${body}`
        },
        setStudioIds() {},
        reload() {
            location.reload()
        },
        nextStepHandler() {
            if (this.step === 3) {
                this.saveTACContract()
            } else {
                this.step++
            }
        },
        async saveTACContract() {
            this.savingTAC = true

            this.mailSubject = 'Herzlich Willkommen bei uns ' + this.contract.users.customerInfos.firstName
            this.mailText = 'Danke für dein Abo Kauf bezahle bitte die Rechnung im Anhang.'
            this.mailAddress = this.contract.users.customerInfos.email

            let data = {
                ProfileIDs: {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: this.userTACId,
                        },
                    ],
                },
                activateContract: this.activateContract,
                proRata: false,
                CreationUserID: {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: '1513',
                        },
                    ],
                },
                MemberCategoryID: {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: this.formData.membership.selectedMembershipID,
                        },
                    ],
                },
            }

            delete this.tacContract.SubMemberCategories
            if (this.formData.packages?.length > 0) {
                data.SubMemberCategories = {
                    MemberCategoryID: this.formData.packages.map((e) => {
                        return {
                            UniqueID: [
                                {
                                    source: 'TAC',
                                    value: e,
                                },
                            ],
                        }
                    }),
                }
            }

            delete this.tacContract.AdvertiserProfileID
            if (this.affiliateTACId) {
                data.AdvertiserProfileID = {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: this.affiliateTACId,
                        },
                    ],
                }
            }

            delete this.tacContract.AdvertiserContractID
            if (this.affiliateContractID) {
                data.AdvertiserContractID = {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: this.affiliateContractID,
                        },
                    ],
                }
            }

            delete this.tacContract.MemberPromotionID
            if (this.formData.membership.selectedPromotion) {
                data.MemberPromotionID = {
                    UniqueID: [
                        {
                            source: 'TAC',
                            value: this.formData.membership.selectedPromotion,
                        },
                    ],
                }
            }

            let overwriteKeys = [
                'MemberPromotionID',
                'AdvertiserProfileID',
                'AdvertiserContractID',
                'SubMemberCategories',
                'MemberCategoryID',
                'CreationUserID',
                'ProfileIDs',
                'activateContract',
                'proRata',
            ]

            for (let key of overwriteKeys) {
                if (Object.keys(data).includes(key)) {
                    this.tacContract[key] = data[key]
                }
            }

            let contractData = {
                contract: this.tacContract,
                affiliate: {
                    isAffiliate: this.affiliateTACId ? true : false,
                    id: this.affiliateTACId || null,
                    amount: this.affiliateAmount,
                    resortId: this.affiliateMemberResortId || null,
                    personName: '',
                    //reasonId: this.affiliateTransactionReason || null,
                    accountId: this.affiliateAccountID || null,
                },
            }

            if (contractData.affiliate.isAffiliate) {
                let firstName = this.contract?.users?.customerInfos?.firstName || ''
                let lastName = this.contract?.users?.customerInfos?.lastName || ''
                let name = firstName + ' ' + lastName
                contractData.affiliate.personName = name
            }

            try {
                let status = await api.createTACContract(contractData)
                this.createdTACContract = true
                this.$toast.success('Vertrag erstellt')
            } catch (e) {
                console.log(e)
                this.$toast.error('Es gab einen Fehler beim Erstellen')
            }
            this.savingTAC = false
        },
        mapTACDataReadable(tacInfos) {
            let data = {
                id: tacInfos?.IDs?.UniqueID?.find((e) => e.source === 'TAC').value || null,
                FirstName: tacInfos?.Customer?.PersonName?.FirstName || null,
                LastName: tacInfos?.Customer?.PersonName?.LastName || null,
                AccountName: tacInfos?.Customer?.AccountName || null,
                street:
                    tacInfos?.Addresses?.NameAddress?.find((e) => e.primary && e.addressType === 'HOME')
                        ?.AddressLine1 || null,
                city:
                    tacInfos?.Addresses?.NameAddress?.find((e) => e.primary && e.addressType === 'HOME')?.CityName ||
                    null,
                zip:
                    tacInfos?.Addresses?.NameAddress?.find((e) => e.primary && e.addressType === 'HOME')?.PostalCode ||
                    null,
            }
            return data
        },
        async savePackageMapping() {
            // { type: 'package', amount: 1, roodyName: "Getränke", durationInMonths: 12, tacMemberCategoryID: "1863", studio: req.body.contract.studioId }

            let packageCacheEntries = []
            let notAllPackagesSet = false

            let durationInMonths = parseInt(this.contract.duration.years) * 12 + parseInt(this.contract.duration.months)

            for (let contractPackage of this.contract.packages) {
                if (!contractPackage.memberCategoryID) {
                    notAllPackagesSet = true
                    break
                }
                packageCacheEntries.push({
                    type: 'package',
                    amount: contractPackage.amount,
                    roodyName: contractPackage.title,
                    tacMemberCategoryID: contractPackage.memberCategoryID,
                    studio: this.contract.studioId,
                    durationInMonths,
                })
            }

            if (notAllPackagesSet) {
                alert('Bitte hinterlege für jedes Paket ein Zusatzpaket')
                return
            }

            this.formData.packages = packageCacheEntries.map((e) => e.tacMemberCategoryID)

            this.editPackageMappingDialog = false

            let cacheUpdateEntries = packageCacheEntries

            try {
                let status = await api.createCacheUpdateEntriesPackages({ cacheUpdateEntries })
            } catch (e) {
                this.$toast.error('Fehler beim Mapping der Zusatzpakete')
            }
        },
        async checkPackages() {
            let allPackagesFound = true

            this.contract.packages.forEach((e) => {
                e.memberCategoryID = null
                this.cachedPackages.forEach((l) => {
                    if (e.amount === l.amount && e.title === l.roodyName) {
                        e.memberCategoryID = l.tacMemberCategoryID
                    }
                })
                if (!e.memberCategoryID) {
                    allPackagesFound = false
                }
            })

            if (this.formData.packages.length !== this.contract.packages.length) allPackagesFound = false
            if (!allPackagesFound) this.editPackageMappingDialog = true
        },
        async userSelectedForContract(Ids) {
            this.userTACId = Ids.UniqueID?.find((e) => e.source === 'TAC')?.value || null

            this.step = 2
        },
        userSelectedForContractAllInfo(infos) {
            this.userTACIdAllInfos = infos
        },

        async userSelectedForContractAffiliate(infos) {
            this.affiliateContractID = null
            this.affiliateAccountID = null
            this.affiliateMemberCategoryName = null
            this.affiliateMemberResortId = null
            this.affiliateMemberResortName = null
            this.affiliateTACId = infos?.IDs?.UniqueID?.find((e) => e.source === 'TAC')?.value || null
            if (this.affiliateTACId) {
                // fetch ContractID here
                let profile = await api.fetchCurrentTACMemberDetailsByTACId(this.affiliateTACId)
                if (profile?.Result?.resultStatusFlag === 'SUCCESS') {
                    this.affiliateAccountID =
                        profile.MemberDetail?.Accounts?.Account?.find(
                            (e) => e.type === 'SECONDARYSPEND'
                        )?.AccountID?.UniqueID?.find((e) => e.source === 'TAC')?.value || null

                    this.affiliateMemberCategoryName = profile.MemberDetail?.MemberCategoryName || null

                    let propertyID =
                        profile.MemberDetail?.PropertyID?.UniqueID?.find((e) => e.source === 'TAC')?.value || null

                    if (propertyID) {
                        this.affiliateMemberResortId =
                            this.storeStudios
                                .filter((e) => Object.keys(e).includes('tacPropertyId'))
                                .find((e) => e.tacPropertyId === propertyID)?.tacResortId || null
                        this.affiliateMemberResortName =
                            this.storeStudios
                                .filter((e) => Object.keys(e).includes('tacPropertyId'))
                                .find((e) => e.tacPropertyId === propertyID)?.name || null
                    }
                    this.affiliateContractID =
                        profile.MemberDetail?.ContractID?.UniqueID?.find((e) => e.source === 'TAC')?.value || null
                } else {
                    if (profile.Result.code === '301') {
                        this.$toast.error(
                            'Fehler beim Laden des Vertrags - Weiterempfehlung wird beim Werber ohne Vertrag hinterlegt'
                        )
                    } else {
                        this.$toast.error(
                            'Fehler beim Laden des Vertrags - Weiterempfehlung wird beim Werber ohne Vertrag hinterlegt'
                        )
                    }
                }
            }
            this.affiliateUserInfos = infos.Customer.PersonName
        },
        async fetchMemberCategories() {
            try {
                let memberCategories = await api.fetchTACMembercategories()

                let counter = 0
                for (let memberGroup of memberCategories.MemberGroups.MemberGroup) {
                    let status = await api.saveTACMembercategoriesSeperate({ data: memberGroup })

                    counter++
                }
            } catch (e) {
                console.log(e)
                this.$toast.error('Fehler beim fetchen der Member cats')
            }
        },
        async init() {
            this.loading = true
            this.initFormData(false)
            this.initTACContract()
            if (this.$route.params.userId && this.$route.params.index) {
                let user = await api.fetchUserContract({
                    userId: this.$route.params.userId,
                })
                if (user?.contracts[this.$route.params.index]) {
                    let contract = user.contracts[this.$route.params.index]
                    let mappedContract = this.mapContractForBackend(contract)
                    this.contract = mappedContract

                    this.userDataForSearch = {
                        FirstName: mappedContract.users.customerInfos.firstName,
                        LastName: mappedContract.users.customerInfos.lastName,
                        EMail: mappedContract.users.customerInfos.email,
                    }
                    this.fetchTACContractMapInfos(mappedContract)
                }
            }
            this.loading = false
        },
        initFormData(setStep = true) {
            this.formData = {
                membership: {
                    selectedMembershipKey: null,
                    selectedMembershipID: null,
                    selectedMembershipName: null,
                    commitmentPeriod: null,
                    promotions: null,
                    selectedPromotion: null,
                },
                packages: [],
            }
            if (setStep) this.step = 2
        },

        backStepHandler() {
            if (this.step === 3) {
                this.step = 1
                //this.initFormData()
            } else {
                this.step--
            }
        },
        async selectMembership(membership) {
            let promotions = membership.promotions
                .map((e) => {
                    return {
                        name: e.Names?.TextElement?.find((e) => e.language === 'de').value || 'kein Name hinterlegt',
                        MemberPromotionID: e.MemberPromotionID?.UniqueID?.find((e) => e.source === 'TAC').value || null,
                    }
                })
                .sort((a, b) => a.name.localeCompare(b.name))

            this.formData.membership = {
                selectedMembershipID: membership.memberCategoryID,
                selectedMembershipName: membership.name,
                commitmentPeriod: membership.commitmentPeriod,
                promotions,
            }

            // update membership cache here
            let durationInMonths = parseInt(this.contract.duration.years) * 12 + parseInt(this.contract.duration.months)
            let cacheUpdateEntry = {
                type: 'membership',
                roodyName: this.contract.membership.membershipName,
                tacMemberCategoryID: membership.memberCategoryID,
                studio: this.contract.studioId,
                durationInMonths,
            }

            try {
                let status = await api.createCacheUpdateEntryMembership({ cacheUpdateEntry })
            } catch (e) {
                this.$toast.error('Fehler beim Mapping der Zusatzpakete')
            }

            this.step++
        },
        async fetchTACContractMapInfos(contract) {
            try {
                let options = await api.fetchTACContractMapInfos({ contract })
                this.tacContract = options.tacContract
                this.tacOptions = options.tacOptions

                this.cachedPackages = options.packagesCache

                if (options.formData?.packages?.length > 0) {
                    this.formData.packages = options.formData.packages
                }

                if (
                    options.formData?.membership?.selectedMembershipKey &&
                    options.formData?.membership?.selectedMembershipID
                ) {
                    this.formData.membership = options.formData.membership
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden der TAC Optionen')
            }

            if (false && this.tacContract?.ResortId) {
                try {
                    let reasons = await api.getTACReasons(this.tacContract.ResortId, 'CREDIT_REASON_SECONDARYSPEND')

                    if (reasons?.Reasons?.Reason) {
                        let mappedReasons = reasons.Reasons.Reason.map((e) => {
                            return {
                                id: e.ReasonID?.UniqueID?.find((f) => f.source === 'TAC')?.value || null,
                                text: e.Reasons?.TextElement?.find((t) => t.language === 'de')?.value || '',
                                timeSpan: e.ValidTimes?.TimeSpan || null,
                            }
                        })

                        this.reasons = mappedReasons.filter((e) => {
                            if (e.timeSpan) {
                                let currentDate = this.$moment()
                                let isActive = e.timeSpan.some((timeSpan) => {
                                    let startDate = this.$moment(timeSpan.Start)
                                    let endDate = this.$moment(timeSpan.End)
                                    return currentDate.isBetween(startDate, endDate)
                                })
                                return isActive
                            } else {
                                return false
                            }
                        })
                        let appReason = this.reasons.find((e) => e.text.includes('App'))
                        if (appReason && appReason.id) {
                            this.affiliateTransactionReason = appReason.id
                        }
                    }
                } catch (e) {
                    console.log(e)
                    this.$toast.error('Fehler beim laden der Kategorie für Aufbuchung der Weiterempfehlung')
                }
            }
        },
        mapContractForBackend(contract) {
            let contractInfos = {}
            contractInfos.studioId = contract.studio?._id || null
            let duration = {}
            if (contract.membership.selectedDuration) {
                let selectedDuration = contract.membership.selectedDuration
                duration.years = parseInt(selectedDuration.years)
                duration.months = parseInt(selectedDuration.months)
                duration.days = parseInt(selectedDuration.days)
            }

            contractInfos.duration = duration

            let startDate = this.$moment(contract.startDate).format('YYYY-MM-DD')
            contractInfos.startDate = startDate

            let membershipName = contract.membership.selection.title

            let giftSignUpFee = contract.membership.giftSignUpFee
            let renewalDiscount = contract.membership.renewalDiscount
            let renewalDiscountAmount = contract.membership.renewalDiscountAmount

            contractInfos.membership = {
                membershipName,
                giftSignUpFee,
                renewalDiscount,
                renewalDiscountAmount,
            }

            let packages = contract.packages.map((e) => {
                return {
                    title: e.title,
                    amount: e.frontEndAmount,
                }
            })

            contractInfos.packages = packages

            let customerInfos = contract.customer.infos
            if (contract.customer.isUnderage && contract.customer.legalRepresentative) {
                customerInfos.legalRepresentativeText =
                    'ges. Vertreter: ' +
                    contract.customer.legalRepresentative.firstName +
                    ' ' +
                    contract.customer.legalRepresentative.lastName +
                    ', ' +
                    contract.customer.legalRepresentative.email
            }

            let creatorInfos = contract.customer.contractCreatorInfos

            contractInfos.users = {
                customerInfos,
                creatorInfos,
            }

            let payment = contract.paymentOptions
            contractInfos.payment = payment

            let category = contract.category
            contractInfos.category = category

            return contractInfos
        },
        initTACContract() {},
    },
}
</script>

<style lang="scss" scoped>
.copyable {
    -ms-user-select: text !important;
    -webkit-user-select: text !important;
    user-select: text !important;
    cursor: pointer;
}
</style>