var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"persistent":"","dark":"","width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"text":""},on:{"click":function($event){return _vm.fetchEntries()}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"white--text pr-2"},[_vm._v("mdi-pencil")]),_vm._v(" Korrektur beantragen ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-data-table',{attrs:{"disable-pagination":"","hide-default-footer":"","items-per-page":40,"no-data-text":"Keine Anwesenheits Einträge gefunden","headers":_vm.tableHeaders,"items":_vm.tableItems},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Einträge vom "+_vm._s(_vm.$moment(_vm.selectedDate).format('DD.MM.YYYY'))+" ")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Neuer Eintrag ")])]}}]),model:{value:(_vm.formDialog),callback:function ($$v) {_vm.formDialog=$$v},expression:"formDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-time-picker',{key:_vm.editedItem,attrs:{"full-width":"","format":"24hr"},model:{value:(_vm.editedItem.time),callback:function ($$v) {_vm.$set(_vm.editedItem, "time", $$v)},expression:"editedItem.time"}})],1),_c('v-row',[_c('v-select',{attrs:{"items":[
                                                    { text: 'Check-In', value: 'checkin' },
                                                    { text: 'Check-Out', value: 'checkout' } ],"label":"Typ"},model:{value:(_vm.editedItem.oldType),callback:function ($$v) {_vm.$set(_vm.editedItem, "oldType", $$v)},expression:"editedItem.oldType"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Speichern")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Willst du diesen Eintrag wirklich löschen? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Löschen")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.type",fn:function(ref){
                                                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColorForChip(item)}},[_c('span',[_vm._v(_vm._s(_vm.mappedEntryTypes[item.realType]))])])]}},{key:"item.actions",fn:function(ref){
                                                var item = ref.item;
return [(item.correction !== 'open')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.correction !== 'open')?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")]):_vm._e()]}}])}),_c('v-divider'),_c('v-card-actions',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Fertig")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }