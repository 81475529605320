<template>
    <div class="px-3">
        <v-form lazy-validation ref="questionDialogForm">
            <v-text-field label="Titel" :rules="rules" filled v-model="formData.title"></v-text-field>
            <v-select filled label="Typ" :rules="rules" :items="questionOptions" v-model="formData.type"></v-select>

            <!-- TEXT & NUMBER -->
            <v-text-field
                filled
                v-if="formData.type === 'text' || formData.type === 'number'"
                :label="formData.type === 'text' ? 'max. Anzahl Zeichen' : 'max. Zahl'"
                v-model="formData.maxCharCount"
            ></v-text-field>

            <!-- ONLY NUMBER -->
            <v-switch
                filled
                inset
                color="primary"
                v-if="formData.type === 'number'"
                label="angabe als %"
                v-model="formData.isPercent"
            ></v-switch>

            <!-- DATE -->
            <v-switch
                filled
                inset
                color="primary"
                v-if="formData.type === 'date'"
                label="Datum mit Uhrzeit"
                v-model="formData.isDateTime"
            ></v-switch>

            <!-- RATING -->
            <v-text-field
                filled
                disabled
                v-if="formData.type === 'rating'"
                label="max. Anzahl Sterne"
                v-model.number="formData.maxStarCount"
            ></v-text-field>
            <!-- SELECT -->

            <v-combobox
                v-if="formData.type === 'select'"
                filled
                chips
                :rules="rules"
                deletable-chips
                multiple
                label="Auswahlmöglichkeiten"
                v-model="formData.options"
            ></v-combobox>
            <v-switch
                filled
                color="primary"
                v-if="formData.type === 'select'"
                inset
                label="mehrere erlauben"
                v-model="formData.allowMultiple"
            ></v-switch>
            <v-expansion-panels dark multiple v-model="panel">
                <v-card-title v-if="formData.additionalFields && formData.additionalFields.length">
                    Zusatzfelder
                </v-card-title>
                <v-expansion-panel v-for="(option, i) of formData.additionalFields" :key="'additionalFields' + i">
                    <v-expansion-panel-header class="primary">{{ option.selection }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-select
                            class="mt-2"
                            disabled
                            filled
                            label="Typ für Zusatzfeld auswählen"
                            :rules="rules"
                            :items="additionalFieldOptions"
                            v-model="option.type"
                        ></v-select>

                        <!-- TEXT & NUMBER -->
                        <v-text-field filled disabled label="Titel" v-model="option.title"></v-text-field>
                        <v-text-field
                            filled
                            disabled
                            v-if="option.type === 'text' || option.type === 'number'"
                            :label="option.type === 'text' ? 'max. Anzahl Zeichen' : 'max. Zahl'"
                            v-model="option.maxCharCount"
                        ></v-text-field>

                        <!-- ONLY NUMBER -->
                        <v-switch
                            filled
                            disabled
                            inset
                            color="primary"
                            v-if="option.type === 'number'"
                            label="angabe als %"
                            v-model="option.isPercent"
                        ></v-switch>

                        <!-- DATE -->
                        <v-switch
                            filled
                            inset
                            disabled
                            color="primary"
                            v-if="option.type === 'date'"
                            label="Datum mit Uhrzeit"
                            v-model="option.isDateTime"
                        ></v-switch>

                        <v-combobox
                            v-if="option.type === 'select'"
                            filled
                            disabled
                            chips
                            :rules="rules"
                            deletable-chips
                            multiple
                            label="Auswahlmöglichkeiten"
                            v-model="option.options"
                        ></v-combobox>
                        <v-switch
                            filled
                            color="primary"
                            v-if="option.type === 'select'"
                            inset
                            disabled
                            label="mehrere erlauben"
                            v-model="option.allowMultiple"
                        ></v-switch>

                        <v-switch
                            filled
                            color="primary"
                            v-if="option.type === 'select'"
                            inset
                            disabled
                            label="Eigene Eingabe erlauben"
                            v-model="option.allowOwnInput"
                        ></v-switch>

                        <v-switch
                            disabled
                            filled
                            class="white--text"
                            label="Ist das Feld ein Pflichtfeld?"
                            color="primary"
                            inset
                            v-model="option.required"
                        ></v-switch>
                        <v-btn text color="red" @click="deleteAdditionalFieldForSelection(i)">
                            Zusatzfeld für Auswahl löschen
                        </v-btn>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>

            <v-btn
                text
                @click="newAdditionalFieldForSelection()"
                v-if="
                    formData.type === 'select' &&
                    formData.options.filter(
                        (option) => !formData.additionalFields.find((field) => field.selection === option)
                    ).length &&
                    !additionalFieldFormData.active
                "
            >
                Zusatzfeld für Auswahl hinzufügen
            </v-btn>
            <div v-if="additionalFieldFormData.active" class="px-2" style="border: 1px dashed white">
                <v-card-title>Zusatzfeld für Auswahlmöglichkeit</v-card-title>

                <v-select
                    filled
                    label="Auswahlmöglichkeit auswählen"
                    :rules="rules"
                    :items="
                        formData.options.filter(
                            (option) => !formData.additionalFields.find((field) => field.selection === option)
                        )
                    "
                    v-model="additionalFieldFormData.selection"
                ></v-select>
                <v-select
                    filled
                    label="Typ für Zusatzfeld auswählen"
                    :rules="rules"
                    :items="additionalFieldOptions"
                    v-model="additionalFieldFormData.type"
                ></v-select>

                <v-text-field
                    label="Titel"
                    :rules="rules"
                    filled
                    v-model="additionalFieldFormData.title"
                ></v-text-field>
                <!-- TEXT & NUMBER -->
                <v-text-field
                    filled
                    v-if="additionalFieldFormData.type === 'text' || additionalFieldFormData.type === 'number'"
                    :label="additionalFieldFormData.type === 'text' ? 'max. Anzahl Zeichen' : 'max. Zahl'"
                    v-model="additionalFieldFormData.maxCharCount"
                ></v-text-field>

                <!-- ONLY NUMBER -->
                <v-switch
                    filled
                    inset
                    color="primary"
                    v-if="additionalFieldFormData.type === 'number'"
                    label="angabe als %"
                    v-model="additionalFieldFormData.isPercent"
                ></v-switch>

                <!-- DATE -->
                <v-switch
                    filled
                    inset
                    color="primary"
                    v-if="additionalFieldFormData.type === 'date'"
                    label="Datum mit Uhrzeit"
                    v-model="additionalFieldFormData.isDateTime"
                ></v-switch>

                <v-combobox
                    v-if="additionalFieldFormData.type === 'select'"
                    filled
                    chips
                    :rules="rules"
                    deletable-chips
                    multiple
                    label="Auswahlmöglichkeiten"
                    v-model="additionalFieldFormData.options"
                ></v-combobox>
                <v-switch
                    filled
                    color="primary"
                    v-if="additionalFieldFormData.type === 'select'"
                    inset
                    label="mehrere erlauben"
                    v-model="additionalFieldFormData.allowMultiple"
                ></v-switch>

                <v-switch
                    filled
                    color="primary"
                    v-if="additionalFieldFormData.type === 'select'"
                    inset
                    label="Eigene Eingabe erlauben"
                    v-model="additionalFieldFormData.allowOwnInput"
                ></v-switch>
                <v-switch
                    filled
                    class="white--text"
                    label="Ist das Feld ein Pflichtfeld?"
                    color="primary"
                    inset
                    v-model="additionalFieldFormData.required"
                ></v-switch>
                <v-card-actions class="justify-content-center">
                    <v-btn text color="grey" @click="additionalFieldFormData = { active: false }">Abbrechen</v-btn>
                    <v-btn text color="primary" @click="saveAdditionalFormFieldData">Speichern</v-btn>
                </v-card-actions>
            </div>
            <!-- REQUIRED -->
            <v-switch
                filled
                class="white--text"
                label="Ist das Feld ein Pflichtfeld?"
                color="primary"
                inset
                v-model="formData.required"
            ></v-switch>
            <v-switch
                v-if="formData.type !== 'text'"
                filled
                class="white--text"
                label="In den Statistiken anzeigen"
                color="primary"
                inset
                v-model="formData.showInStats"
            ></v-switch>

            <!--  <v-switch
            filled
            class="white--text"
            label="Im Express Ananmesebogen anezeigen?"
            color="primary"
            inset
            v-model="formData.showInExpress"></v-switch> -->
        </v-form>
        <v-card-actions class="justify-content-center">
            <v-btn color="red" v-if="question && question._id" @click="deleteQuestion()">Löschen</v-btn>
        </v-card-actions>
    </div>
</template>

<script>
import api from '@/api'
import basicDialog from '@/components/tools/basicDialog'
export default {
    components: {
        basicDialog,
    },
    props: {
        studio: {
            required: true,
        },
        question: {
            type: Object,
            required: false,
        },
    },
    data() {
        return {
            panel: [],
            additionalFieldFormData: { active: false },
            additionalFieldOptions: [
                { text: 'Text', value: 'text' },
                { text: 'Zahl', value: 'number' },
                /*    { text: 'Datum', value: 'date' },*/
                { text: 'Auswahl', value: 'select' },
            ],
            questionOptions: [
                { text: 'Text', value: 'text' },
                { text: 'Zahl', value: 'number' },
                /* { text: 'Datum', value: 'date' }, */

                { text: 'Bewertung', value: 'rating' },
                { text: 'Auswahl', value: 'select' },
            ],
            formData: {
                title: '',
                type: '',
                options: [],
                additionalFields: [],
                required: true,
            },
            rules: [(v) => !!v || 'Dieses Feld ist ein Pflichtfeld'],
        }
    },
    async created() {
        if (this.question && this.question._id) {
            this.formData = JSON.parse(JSON.stringify(this.question))
        } else {
            this.formData = {
                title: '',
                type: '',
                options: [],
                required: true,
                additionalFields: [],
            }
        }
    },

    methods: {
        deleteAdditionalFieldForSelection(index) {
            this.formData.additionalFields.splice(index, 1)
        },
        saveAdditionalFormFieldData() {
            this.formData.additionalFields.push(this.additionalFieldFormData)
            this.additionalFieldFormData = { active: false }
        },
        newAdditionalFieldForSelection() {
            this.additionalFieldFormData = {
                active: true,
                selection: null,
                type: '',
                options: [],
                additionalFields: [],
                required: true,
            }
        },
        async createQuestion() {
            if (this.$refs.questionDialogForm.validate()) {
                if (this.studio === 'nutrition') {
                    this.formData.nutrition = true
                } else {
                    this.formData.studio = this.studio
                }
                let createdQuestion = await api.createOrUpdateAnamneseQuestion(this.formData)
                this.$emit('saveFinished', createdQuestion)
            }
        },
        async deleteQuestion() {
            if (confirm('Frage wirklich löschen?')) {
                await api.deleteAnamneseQuestion({ _id: this.question._id })
                this.$emit('deleted')
            }
        },
    },
}
</script>
