<template>
    <div style="max-width: 500px" class="ma-auto">
        <div
            class="mainpage-top elevation-5 primary"
            :style="{ height: 80 + 'px', width: '100vw', 'max-width': '520px' }"
        >
            <v-row no-gutters>
                <v-app-bar color="transparent" width="100%" fixed elevation="0" class="justify-space-between py-2">
                    <v-col cols="2">
                        <v-btn icon @click="$router.push('/nutrition')">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <v-img height="50px" @click="$router.push('/')" contain src="/img/roody_logo.png"></v-img>
                    </v-col>
                </v-app-bar>
            </v-row>
        </div>
        <div style="padding-top: 80px" v-if="!createdList">
            <v-card-title>Einkaufsliste erstellen</v-card-title>
            <v-text-field hide-details dense label="Suche" v-model="search" filled rounded></v-text-field>
            <v-chip-group column multiple value="value" v-model="activeChips" active-class="primary">
                <v-chip :value="filter.value" small v-for="filter of getRecipeFilters" :key="'filter' + filter.text">
                    {{ filter.text }}
                </v-chip>
            </v-chip-group>

            <v-row no-gutters>
                <v-col
                    style="height: 90px"
                    v-for="(recipe, index) of filteredRecipes"
                    cols="12"
                    color="transparent"
                    class="mr-2 my-1 fill-height"
                    :key="'recipe' + index"
                >
                    <v-card class="rounded-lg fill-height">
                        <v-row no-gutters>
                            <v-col cols="3">
                                <v-img class="rounded-lg" :src="recipe.media.preview[0]" height="90px" cover></v-img>
                            </v-col>
                            <v-col cols="7">
                                <v-card-title class="black--text h6 font-weight-bold" v-if="recipe.recipe">
                                    <span
                                        style="
                                            text-wrap: nowrap;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            display: block;
                                            width: 100%;
                                        "
                                    >
                                        {{ recipe.recipe.title }}
                                    </span>
                                </v-card-title>
                                <v-card-subtitle>
                                    <v-chip
                                        class="mr-1 white--text"
                                        x-small
                                        :color="recipe.day === 'trainingDay' ? 'grey darken-2' : '#fb7683'"
                                    >
                                        {{ recipe.dayText }}
                                    </v-chip>
                                    <v-chip x-small>{{ recipe.distributionText }}</v-chip>
                                </v-card-subtitle>
                            </v-col>
                            <v-col cols="2" class="align-content-center">
                                <v-btn plain icon @click="addRecipe(recipe)" :ripple="false">
                                    <v-icon color="primary">mdi-plus-circle-outline</v-icon>
                                    <span>
                                        ({{
                                            addedRecipes.find(
                                                (addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title
                                            )
                                                ? addedRecipes.find(
                                                      (addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title
                                                  ).portions
                                                : '0'
                                        }})
                                    </span>
                                </v-btn>
                                <v-btn
                                    @click="removeRecipe(recipe)"
                                    plain
                                    :ripple="false"
                                    icon
                                    :disabled="
                                        !addedRecipes.find((addedRec) => addedRec.recipe.title === recipe.recipe.title)
                                    "
                                >
                                    <v-icon color="error">mdi-minus-circle-outline</v-icon>
                                    <span style="visibility: hidden">
                                        ({{
                                            addedRecipes.find(
                                                (addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title
                                            )
                                                ? addedRecipes.find(
                                                      (addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title
                                                  ).portions
                                                : '1'
                                        }})
                                    </span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div style="padding-top: 80px" v-else>
            <v-btn icon absolute right @click="deleteExistingShoppingList">
                <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
            <v-btn icon absolute style="right: 45px" @click="addProduct = true">
                <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
            <v-card-title>
                Deine Einkaufsliste
                <v-switch
                    class="ml-2"
                    :label="showGrouped ? 'Gruppiert' : 'pro Rezept'"
                    v-model="showGrouped"
                ></v-switch>
            </v-card-title>
            <v-row no-gutters v-if="showGrouped">
                <v-col cols="12" :key="recipe.title" v-for="(recipe, index) of mappedRecipes">
                    <v-divider v-if="index !== 0"></v-divider>
                    <v-data-table
                        dense
                        hide-default-footer
                        disable-filtering
                        disable-pagination
                        :items-per-page="1000"
                        mobile-breakpoint="0"
                        :headers="tableHeaders(recipe)"
                        :items="recipe.ingredients"
                    >
                        <template v-slot:item.amount="{ item }">
                            <v-tooltip bottom>
                                <div class="d-flex flex-column">
                                    <span>{{ item.item_addition }}</span>
                                </div>
                                <template v-slot:activator="{ props, on }">
                                    <span v-bind="props" v-on="item.item_addition ? on : null">
                                        {{ item.amount }}
                                        <v-icon v-if="item.item_addition" x-small color="gray">mdi-information</v-icon>
                                    </span>
                                </template>
                            </v-tooltip>
                        </template>
                    </v-data-table>

                    <v-divider v-if="index === mappedRecipes.length - 1"></v-divider>
                </v-col>
            </v-row>
            <v-row no-gutters v-else>
                <transition-group name="fade" tag="tbody">
                    <v-data-table
                        class="mb-10"
                        style="width: 100%"
                        dense
                        key="dataTable"
                        item-key="item.product"
                        hide-default-footer
                        disable-filtering
                        disable-pagination
                        :sort-by="sorting"
                        :items-per-page="1000"
                        mobile-breakpoint="0"
                        :headers="tableHeaders()"
                        :items="fullTableItems"
                    >
                        <template v-slot:item.amount="{ item }">
                            <v-tooltip bottom>
                                <div class="d-flex flex-column">
                                    <span>{{ item.item_addition }}</span>
                                </div>
                                <template v-slot:activator="{ props, on }">
                                    <span class="d-flex" v-bind="props" v-on="item.item_addition ? on : null">
                                        <v-text-field
                                            @blur="changeCheckedStatus('quantity')"
                                            v-if="!item.checked"
                                            solo
                                            flat
                                            hide-details
                                            dense
                                            :suffix="item.unit_short"
                                            v-model="item.quantity1"
                                        ></v-text-field>
                                        <span class="pl-3" v-else>{{ item.quantity1 + ' ' + item.unit_short }}</span>
                                        <v-icon v-if="item.item_addition" x-small color="gray">mdi-information</v-icon>
                                    </span>
                                </template>
                            </v-tooltip>
                        </template>
                        <template v-slot:item.checked="{ item }">
                            <v-checkbox
                                :key="item.product + item.checked"
                                @click="changeCheckedStatus()"
                                plain
                                v-model="item.checked"
                                solo
                                flat
                                hide-details
                                dense
                            ></v-checkbox>
                        </template>
                        <template v-slot:item.product="{ item }">
                            <span :style="item.checked ? 'text-decoration: line-through' : ''">{{ item.product }}</span>
                        </template>
                    </v-data-table>
                </transition-group>
            </v-row>
        </div>

        <v-dialog v-model="addProduct">
            <v-card>
                <v-card-title>Produkt hinzufügen</v-card-title>
                <v-text-field v-model="newProduct.product" class="mx-6" label="Produkt"></v-text-field>
                <v-text-field v-model="newProduct.quantity1" class="mx-6" label="Menge"></v-text-field>
                <v-text-field v-model="newProduct.unit_short" class="mx-6" label="Einheit"></v-text-field>
                <v-card-actions class="justify-content-center">
                    <v-btn @click="addProductToList" color="primary" rounded>Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="chooseServingCountDialog">
            <v-card v-if="chooseServingCountRecipe.recipe">
                <v-card-title>Anzahl Portionen</v-card-title>
                <v-alert type="info">Bei Anpassungen können Produkte mit Kommastellen entstehen.</v-alert>
                <v-card-text class="text-center">
                    <v-btn
                        @click="chooseServingCountRecipe.recipe.serving -= 1"
                        plain
                        large
                        :ripple="false"
                        icon
                        :disabled="chooseServingCountRecipe.recipe.serving < 2"
                    >
                        <v-icon color="error">mdi-minus-circle-outline</v-icon>
                    </v-btn>

                    <span class="h4">{{ chooseServingCountRecipe.recipe.serving }}</span>
                    <v-btn large @click="chooseServingCountRecipe.recipe.serving += 1" plain :ripple="false" icon>
                        <v-icon color="success">mdi-plus-circle-outline</v-icon>
                    </v-btn>
                </v-card-text>
                <v-card-actions class="justify-content-center">
                    <v-btn color="primary" @click="addChooseServingCountRecipe">Hinzufügen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn
            :loading="creatingList"
            fixed
            class="ma-auto rounded-xl"
            left
            v-if="!createdList"
            right
            bottom
            max-width="300px"
            color="primary"
            @click="createList"
        >
            Aus ( {{ addedRecipes.length }} ) Rezepten erstellen
        </v-btn>
    </div>
</template>
<script>
import api from '@/api'
import nutritionMealDialogSelectedRecipe from './nutritionMealDialogSelectedRecipe.vue'

export default {
    components: { nutritionMealDialogSelectedRecipe },
    data() {
        return {
            showGrouped: false,
            createdList: false,
            addedRecipes: [],
            loading: true,
            data: null,
            selectedMeal: null,
            activeChips: [],
            selectedRecipe: null,
            search: '',
            sorting: 'checked',
            creatingList: false,
            mappedRecipes: null,
            fullTableItems: null,
            addProduct: false,
            newProduct: {},
            chooseServingCountDialog: false,
            chooseServingCountRecipe: {},
        }
    },
    props: {
        date: {},
    },
    computed: {
        filteredRecipes() {
            if (this.loading) return []
            let allRecipes = this.data.sort((a, b) => a.recipe.title < b.recipe.title)
            if (this.search) {
                allRecipes = allRecipes.filter((recipe) =>
                    recipe.recipe.title.toLowerCase().includes(this.search.toLowerCase())
                )
            }
            return allRecipes.filter((recipeDat) => {
                let value = recipeDat
                if (this.activeChips) {
                    for (let chip of this.activeChips) {
                        value = recipeDat.day === chip || recipeDat.distributionValue === chip
                        if (!value) break
                    }
                }
                return value
            })
        },
        getRecipeFilters() {
            if (this.loading) return []
            let filters = []
            this.data.forEach((recipe) => {
                let distriFilter = {
                    text: recipe.distributionText,
                    value: recipe.distributionValue,
                    or: 'distri',
                }

                let dayFilter = {
                    text: recipe.dayText,
                    value: recipe.day,
                    or: 'day',
                }

                let distriExists = filters.some(
                    (filter) => filter.value === distriFilter.value && filter.text === distriFilter.text
                )
                let dayExists = filters.some(
                    (filter) => filter.value === dayFilter.value && filter.text === dayFilter.text
                )

                if (!distriExists) {
                    filters.push(distriFilter)
                }
                if (!dayExists) {
                    filters.unshift(dayFilter)
                }
            })

            return filters
        },
    },
    async created() {
        let existingShoppingList = await api.fetchNutritionUserShoppingList()

        if (existingShoppingList && existingShoppingList.shoppingList) {
            this.fullTableItems = existingShoppingList.shoppingList.fullTableItems
            this.mappedRecipes = existingShoppingList.shoppingList.mappedRecipes

            this.createdList = true
        }
        this.data = await api.fetchAllRecipesShoppingList()
        this.loading = false
    },
    methods: {
        async addProductToList() {
            try {
                if (!this.newProduct.product) return this.$toast.error('Was für ein Produkt möchtest du hinzufügen?')
                this.fullTableItems.unshift(this.newProduct)
                this.$toast.success(this.newProduct.product + ' hinzugefügt')
                this.addProduct = false
                await api.updateNutritionShoppingListFullTableItems(this.fullTableItems)
                this.newProduct = {}
            } catch (e) {
                this.$toast.error('Fehler beim hinzufügen')
            }
        },
        async changeCheckedStatus(quantity = false) {
            if (quantity) this.$toast.success('Menge wurde angepasst')
            await api.updateNutritionShoppingListFullTableItems(this.fullTableItems)
            this.$forceUpdate()
        },
        async deleteExistingShoppingList() {
            try {
                if (confirm('Möchtest du deine Einkaufsliste löschen?')) {
                    await api.updateNutritionShoppingList(null)
                    this.fullTableItems = null
                    this.mappedRecipes = null
                    this.createdList = false
                }
            } catch (e) {
                this.$toast.error('Fehler beim löschen.')
            }
        },
        tableHeaders(recipe = null) {
            let amountText = 'Menge'
            if (recipe) {
                if (recipe.portions > 1)
                    amountText +=
                        ' (' + recipe.portions + ' Rezepte = ' + recipe.serving * recipe.portions + ' Portionen)'
                if (recipe.portions === 1)
                    amountText += ' (' + recipe.portions + ' Rezept = ' + recipe.serving + ' Portionen)'
            }

            let headers = [
                {
                    text: recipe ? recipe.title : 'Produkte',
                    value: 'product',
                    width: '45%',
                },
                {
                    text: amountText,
                    value: 'amount',
                    width: '35%',
                },
            ]
            if (!recipe) headers.push({ text: '✓', value: 'checked', width: '20%' })
            return headers
        },
        addRecipe(recipe) {
            let recipeExists = this.addedRecipes.find((addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title)
            if (recipeExists) return recipeExists.portions++

            recipe.portions = 1

            if (recipe.recipe.serving !== 1) {
                recipe.startingServing = JSON.parse(JSON.stringify(recipe.recipe.serving))
                this.chooseServingCountRecipe = recipe
                this.chooseServingCountDialog = true
                return
            }

            this.addedRecipes.push(recipe)
        },
        addChooseServingCountRecipe() {
            let addingRecipe = JSON.parse(JSON.stringify(this.chooseServingCountRecipe))

            for (let ingredient of addingRecipe.ingredients) {
                ingredient.quantity1 =
                    Math.round(
                        (ingredient.quantity1 / addingRecipe.startingServing) * addingRecipe.recipe.serving * 10
                    ) / 10
            }
            this.chooseServingCountRecipe = {}
            this.chooseServingCountDialog = false
            this.addedRecipes.push(addingRecipe)
        },
        removeRecipe(recipe) {
            let recipeExist = this.addedRecipes.find((addedRecipe) => addedRecipe.recipe.title === recipe.recipe.title)
            if (recipeExist.portions > 1) return recipeExist.portions--

            this.addedRecipes = this.addedRecipes.filter(
                (addedRecipe) => addedRecipe.recipe.title !== recipe.recipe.title
            )
        },
        async createList() {
            this.mappedRecipes = []
            this.fullTableItems = []
            if (!this.addedRecipes.length) {
                return this.$toast.error('Füge mindestens 1 Rezept hinzu')
            }
            this.mappedRecipes = this.addedRecipes.map((recipe) => {
                return {
                    title: recipe.recipe.title,
                    ingredients: recipe.ingredients.map((ingredient) => {
                        ingredient.amount = ingredient.quantity1 + ' ' + ingredient.unit_short
                        return ingredient
                    }),
                    serving: recipe.recipe.serving,
                    portions: recipe.portions,
                }
            })

            this.fullTableItems = this.mappedRecipes.reduce((accumulator, recipe) => {
                for (let ingredient of recipe.ingredients) {
                    if (ingredient.quantity1) {
                        ingredient.quantity1 *= recipe.portions
                    }
                    ingredient.checked = false
                    let existingIng = accumulator.find(
                        (ing) => ingredient.product === ing.product && ing.unit === ingredient.unit
                    )
                    if (existingIng) {
                        existingIng.quantity1 += ingredient.quantity1
                    } else {
                        accumulator.push(ingredient)
                    }
                }
                return accumulator
            }, [])
            let updated = await api.updateNutritionShoppingList({
                fullTableItems: this.fullTableItems,
                mappedRecipes: this.mappedRecipes,
            })

            //  return this.$toast.error('Funktion noch nicht verfügbar.')
            this.createdList = true
        },
        getRecipeStars(recipe) {
            if (recipe.difficulty) {
                return recipe.difficulty === 'einfach' ? 1 : recipe.difficulty === 'schwierig' ? 3 : 2
            }
            return 0
        },
    },
}
</script>
<style scoped>
::v-deep .nutritionMealDialog {
    position: absolute;
    max-height: 70vh;
    bottom: 0 !important;
    margin: 0;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.loader-div {
    position: relative;
    top: 40vh;
    left: 0;
    right: 0;
    width: 80px;
    margin: auto;
}
.mainpage-top {
    position: fixed;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: -15px;
    z-index: 100;
    max-width: 100%;
    width: 100%;
    transition: height 0.05 ease;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>