var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.filteredHabbitList.length)?_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column py-0",attrs:{"cols":"2"}},[_c('v-img',{attrs:{"src":"/img/lottiefiles/sunset.png"}}),_c('div',{staticStyle:{"flex":"1 1 auto","height":"100%","width":"50%","border-right":"2px dashed lightgray"}}),_c('v-img',{attrs:{"src":"/img/lottiefiles/moon.png"},on:{"click":_vm.test}})],1),_c('v-col',{attrs:{"cols":"9"}},[_c('swipe-list',{ref:"list",staticClass:"my-2",attrs:{"items":_vm.filteredHabbitList,"item-key":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item)?_c('v-card',{staticClass:"rounded-lg d-flex flex-row my-2",class:{ habitCompleted: item.completed, habitDelayed: item.delayed },staticStyle:{"width":"98%","margin-left":"1%","margin-right":"1%"},on:{"click":function($event){return _vm.setHabitInExecution(item)}}},[_c('v-col',{staticClass:"d-flex flex-column pa-2",attrs:{"cols":"3"}},[_c('v-img',{attrs:{"contain":"","height":"52px","src":'/img/lottiefiles/' + item.icon}})],1),(item && item.label)?_c('v-col',{staticClass:"d-flex flex-column self-center",attrs:{"cols":"7"}},[(!item.workout)?_c('span',[_vm._v(_vm._s(item.label))]):_vm._e(),(item.amount)?_c('span',[_vm._v(" "+_vm._s(_vm.getExecutionsForHabit(item) || 0)+" von "+_vm._s(item.amount)+" "+_vm._s(_vm.getHabitAmountEnding(item))+" ")]):(item.workout && item.workout.form)?_c('span',[_vm._v(_vm._s(item.workout.form.title))]):(item.time)?_c('span',[_vm._v(" "+_vm._s(item.time)+" ")]):_vm._e()]):_vm._e(),(_vm.getExecutionsForHabit(item) >= item.amount)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"2"}},[_c('v-img',{attrs:{"src":"/img/lottiefiles/streakMedal.png","width":"45","height":"45"}})],1):_vm._e()],1):_vm._e()]}},{key:"left",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('div',{staticClass:"red my-3 d-flex flex-column rounded mr-1",on:{"click":function($event){return _vm.deleteHabit(item)}}},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-delete")]),_c('span',{staticClass:"px-1 white--text"},[_vm._v("Löschen")])],1),_c('div',{staticClass:"blue my-3 d-flex flex-column mr-1 rounded",on:{"click":function($event){return _vm.editHabit(item, close)}}},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-pencil-circle")]),_c('span',{staticClass:"px-1 white--text"},[_vm._v("Bearbeiten")])],1)]}},{key:"right",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"primary my-3 d-flex flex-column ml-1 rounded",on:{"click":function($event){_vm.habitInStatistic = item}}},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v("mdi-chart-timeline-variant-shimmer")]),_c('span',{staticClass:"px-1 white--text"},[_vm._v("Statistik")])],1)]}}],null,false,3783184163)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('habit-create-button',{staticClass:"py-2"})],1)],1):_c('v-row',{staticClass:"d-flex justify-center pt-5"},[_c('v-col',{staticClass:"d-flex flex-column justify-center align-items-center",attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Keine Gewohnheiten vorhanden")]),_c('v-card-text',{staticClass:"text-center"},[_vm._v("Füge jetzt deine erste Gewohnheit hinzu.")]),_c('habit-create-button')],1),_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12"}},[_c('v-img',{attrs:{"height":"150","src":"/img/lottiefiles/empty.gif"}})],1)],1),(_vm.habitInEdit)?_c('habit-edit',{attrs:{"selectedHabit":_vm.habitInEdit},on:{"closeEdit":function($event){_vm.habitInEdit = null}}}):_vm._e(),(_vm.habitInExecution && _vm.habitInExecution.type === 'drinkHabit')?_c('habit-drink-execution',{attrs:{"date":_vm.date,"habit":_vm.habitInExecution},on:{"closeExecutionForm":function($event){_vm.habitInExecution = null}}}):_vm._e(),(_vm.habitInExecution && _vm.habitInExecution.type === 'wakeUpHabit')?_c('habit-wake-up-execution',{attrs:{"date":_vm.date,"habit":_vm.habitInExecution},on:{"closeExecutionForm":function($event){_vm.habitInExecution = null}}}):_vm._e(),(_vm.habitInStatistic)?_c('habit-statistic',{attrs:{"selectedHabit":_vm.habitInStatistic},on:{"closeStatistic":function($event){_vm.habitInStatistic = null}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }