<template xmlns="">
    <div>
        <swiper ref="aliBeySwiper" :options="aliBeySwiperOptions">
            <swiper-slide class="ali-bey-booking__swiper my-5">
                <div v-if="!faultyBirthday">
                    <div>
                        <v-card-title class="workingOut__swiper-card-title h5 alibey--text justify-center">
                            Buchungsinformationen
                        </v-card-title>

                        <v-form v-if="form.persons.length" ref="personForm" lazy-validation>
                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-menu
                                        ref="menu"
                                        v-model="rangePicker"
                                        :close-on-content-click="false"
                                        :return-value.sync="bookingDateRange"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="formattedBookingDateRange"
                                                label="Zeitraum"
                                                prepend-icon="mdi-calendar"
                                                required
                                                :rules="requiredField"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>

                                        <v-date-picker
                                            locale="de-DE"
                                            min="2025-05-31"
                                            max="2025-06-09"
                                            no-title
                                            :picker-date.sync="shownDates"
                                            range
                                            v-model="bookingDateRange"
                                        >
                                            <v-spacer></v-spacer>
                                            <v-btn text color="alibey" @click="rangePicker = false">Abbrechen</v-btn>
                                            <v-btn text color="alibey" @click="$refs.menu.save(bookingDateRange)">
                                                OK
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12">
                                    <v-btn text @click="rangePicker = true">verlängern</v-btn>
                                </v-col>
                            </v-row>

                            <v-row v-for="(person, index) in form.persons" :key="index">
                                <v-col cols="12" class="d-flex flex-row space-between">
                                    <v-card-title class="alibey--text pa-0">
                                        Person {{ index + 1 }}
                                        {{ checkIfChild(person.birthDayPicker.date) }}
                                    </v-card-title>
                                    <v-btn @click="deletePerson(index)" icon>
                                        <lottie-player
                                            mode="normal"
                                            style="width: 32px; height: 32px"
                                            class="person-delete-lottie"
                                            name="deletePersonIcon"
                                            autoplay
                                            src="/img/lottiefiles/deletePerson.json"
                                        ></lottie-player>
                                    </v-btn>
                                </v-col>

                                <v-col cols="12" md="3" lg="3" xl="3">
                                    <v-text-field
                                        v-model="person.firstName"
                                        :rules="requiredField"
                                        label="Vorname"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3" lg="3" xl="3">
                                    <v-text-field
                                        v-model="person.lastName"
                                        :rules="requiredField"
                                        label="Nachname"
                                        required
                                    ></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3" lg="3" xl="3">
                                    <v-menu
                                        :ref="'birthDayPicker' + index"
                                        v-model="person.birthDayPicker.activeMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                @click="setPickerToYear(index)"
                                                v-model="person.birthDayPicker.date"
                                                label="Geburtstag"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                :rules="requiredField"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            locale="de-CH"
                                            v-model="person.birthDayPicker.date"
                                            :active-picker.sync="activeBirthDayPicker"
                                            :max="
                                                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                                                    .toISOString()
                                                    .substr(0, 10)
                                            "
                                            min="1920-01-01"
                                            @change="saveDatePicker($event, index)"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="3" lg="3" xl="3">
                                    <v-select
                                        v-model="person.shirtSize"
                                        :items="tshirtSizes"
                                        label="T-Shirt"
                                        required
                                        :rules="requiredField"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <v-btn class="alibey white--text" @click="addNewPerson">
                                        Person hinzufügen
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>

                        <div v-else>
                            Es gab einen Fehler, bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es
                            erneut.
                        </div>
                    </div>

                    <v-card-actions class="justify-center">
                        <v-btn @click="exitAlibey()" text>zurück</v-btn>
                        <v-btn color="alibey" rounded class="text-white" @click="slideToSelection">
                            Angebote Ansehen
                        </v-btn>
                    </v-card-actions>
                </div>
                <div v-else>
                    <v-card-title>Fehlerhaftes Gebursdatum</v-card-title>
                    <v-card-text>
                        Dein hinterlegter Geburtstag ist ungültig. Du kannst dein Geburtsdatum gleich hier anpassen:
                    </v-card-text>
                    <v-text-field
                        style="max-width: 200px"
                        class="pa-0 mx-auto"
                        type="date"
                        v-model="userBirthday"
                    ></v-text-field>
                    <v-card-actions class="justify-center">
                        <v-btn color="alibey" rounded class="text-white" @click="saveBirthday()">Speichern</v-btn>
                    </v-card-actions>
                </div>
            </swiper-slide>

            <swiper-slide class="">
                <div>
                    <v-card-title class="workingOut__swiper-card-title h5 alibey--text justify-center">
                        Angebote für Dich
                    </v-card-title>

                    <div style="display: flex; overflow: scroll" v-if="filteredRooms">
                        <v-col v-for="(room, index) in filteredRooms" lg="4" xl="4" cols="10" :key="index" class="px-2">
                            <v-card :color="room.selected ? 'white' : 'notSelected'" @click="selectRoom(index)">
                                <v-row class="justify-center">
                                    <v-col cols="12" class="py-0">
                                        <v-img
                                            height="250px"
                                            class="ali-bey-booking__swiper-card-content-img"
                                            :src="room.img"
                                            alt="kein Bild gefunden"
                                        ></v-img>
                                    </v-col>

                                    <v-col class="d-flex flex-row justify-space-between" cols="auto">
                                        <div class="justify-space-between d-flex flex-column">
                                            <div>
                                                <v-card-title
                                                    v-if="room.subtitle"
                                                    class="font-weight-bold h6 pb-0 mb-0"
                                                >
                                                    {{ room.subtitle }}
                                                </v-card-title>
                                                <v-card-title class="h5 alibey--text pb-0">
                                                    {{ room.title }}
                                                </v-card-title>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col cols="auto">
                                        <div class="align-self-end align-center">
                                            <v-card-text class="pb-0">
                                                <p class="mb-0">{{ duration }} Übernachtungen</p>
                                                <p class="mb-0">
                                                    {{
                                                        Math.round(
                                                            room.possibility.price +
                                                                (aerobicPrice * adultCount) / duration
                                                        )
                                                    }}
                                                    CHF pro Tag
                                                </p>
                                            </v-card-text>
                                            <v-card-text class="primary--text">
                                                <span class="h4">
                                                    {{
                                                        formatPrice(
                                                            Math.round(
                                                                room.possibility.price +
                                                                    (aerobicPrice * adultCount) / duration
                                                            ) * duration
                                                        )
                                                    }}
                                                    CHF
                                                </span>
                                                *zzgl. Flug
                                            </v-card-text>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-card-actions class="justify-content-center">
                                    <v-btn @click.prevent="showEquipment(index)" text color="alibey">
                                        <div class="d-flex flex-column">
                                            <span>Ausstattung</span>
                                            <v-icon>mdi-chevron-down</v-icon>
                                        </div>
                                    </v-btn>
                                </v-card-actions>
                                <v-expand-transition>
                                    <div v-show="room.showEquipment">
                                        <v-divider></v-divider>
                                        <v-card-title class="p-3">Ausstattung</v-card-title>

                                        <span
                                            class="px-1"
                                            inline-block
                                            v-for="(equipment, i) in room.equipment"
                                            :key="i"
                                        >
                                            {{ equipment }},
                                        </span>
                                    </div>
                                </v-expand-transition>
                            </v-card>
                        </v-col>
                    </div>
                    <div v-else>Für Ihre Suche wurden keine Treffer gefunden.</div>
                    <v-card-actions class="justify-center">
                        <v-btn color="alibey" rounded class="text-white" @click="slideTo(0)">Zurück</v-btn>
                        <v-btn color="alibey" rounded class="text-white" :disabled="!selectedRoom" @click="confirmRoom">
                            Auswahl bestätigen
                        </v-btn>
                    </v-card-actions>
                </div>
            </swiper-slide>

            <swiper-slide style="max-height: 100vh">
                <div v-if="selectedRoom" style="max-height: 100vh">
                    <div>
                        <v-img max-height="400px" min-height="125px" :src="selectedRoom.img"></v-img>
                        <v-card-title class="font-weight-bold h6 pb-0 mb-0 justify-center">
                            {{ selectedRoom.subtitle }}
                        </v-card-title>
                        <v-card-title class="workingOut__swiper-card-title h5 alibey--text justify-center pb-0 mb-0">
                            {{ selectedRoom.title }}
                        </v-card-title>
                    </div>

                    <v-card-title class="alibey--text justify-center py-0 my-0">
                        <span
                            class="px-1"
                            style="font-size: 14px"
                            inline-block
                            v-for="(equipment, y) in selectedRoom.equipment"
                            :key="y"
                        >
                            {{ equipment }},
                        </span>
                    </v-card-title>

                    <div>
                        <v-card-title class="justify-center mb-0 pb-0">Gesamtpreis:</v-card-title>
                        <v-card-text>
                            <p class="mb-0">{{ duration }} Übernachtungen</p>
                            <p class="mb-0">
                                {{
                                    Math.round(selectedRoom.possibility.price + (aerobicPrice * adultCount) / duration)
                                }}
                                CHF pro Tag
                            </p>
                        </v-card-text>
                        <v-card-text class="py-0">
                            <span class="h4">
                                {{
                                    formatPrice(
                                        Math.round(
                                            selectedRoom.possibility.price + (aerobicPrice * adultCount) / duration
                                        ) * duration
                                    )
                                }}
                                CHF
                            </span>
                            *zzgl. Flug
                        </v-card-text>
                    </div>

                    <div>
                        <v-card-actions class="justify-center flex-wrap">
                            <v-btn color="alibey" rounded class="text-white" @click="slideTo(1)">Zurück</v-btn>
                            <v-btn
                                color="alibey"
                                rounded
                                class="text-white"
                                :loading="bookingLoading"
                                @click="createBooking"
                            >
                                Reservieren
                            </v-btn>
                        </v-card-actions>
                    </div>
                </div>
            </swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <v-snackbar color="error" v-model="showSnackBar">
            {{ snackBarMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import api from '../../api'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import { mapState, mapActions } from 'vuex'
import 'swiper/css/swiper.css'

export default {
    name: 'aliBeyBooking',
    components: {
        Swiper,
        SwiperSlide,
    },
    directives: {
        swiper: directive,
    },
    data() {
        return {
            bookingLoading: false,
            userBirthday: null,
            faultyBirthday: false,
            showSnackBar: false,
            adultCount: 0,
            snackBarMessage: 'Fehler!',
            aerobicPrice: 119,
            activeBirthDayPicker: '',
            bookingDateRange: ['2025-05-31', '2025-06-07'],
            tshirtSizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
            rangePicker: false,
            shownDates: '2025-05',
            childrenAge: [
                {
                    start: 0,
                    end: 2,
                },
                {
                    start: 0,
                    end: 6,
                },
                {
                    start: 0,
                    end: 12,
                },
                {
                    start: 3,
                    end: 12,
                },
                {
                    start: 7,
                    end: 12,
                },
            ],
            form: {
                persons: [],
            },
            requiredField: [(v) => !!v || 'Dies ist ein Pflichtfeld'],
            standardRooms: [
                {
                    title: 'Standardzimmer',
                    selected: false,
                    showEquipment: false,
                    img: '/img/einmalig/alibeyStandardRoom.jpg',
                    equipment: [
                        '26 m2',
                        'Kabelloses Internet (Wi-Fi)',
                        'SAT LCD TV',
                        'Musikkanäle',
                        'Telefon',
                        'Minibar',
                        'Klimaanlage',
                        'Safe',
                        'Dusche / WC',
                        'Föhn',
                        'Keramikboden',
                        'Möblierter Balkon',
                        'Ultra All inclusive',
                    ],
                    possibilities: [
                        {
                            adult: 1,
                            children: {
                                count: 0,
                            },
                            price: 194.0,
                        },
                        {
                            adult: 1,
                            children: {
                                count: 1,
                                age: 1,
                            },
                            price: 194.0,
                        },
                        {
                            adult: 1,
                            children: {
                                count: 1,
                                age: 4,
                            },
                            price: 258.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 0,
                            },
                            price: 258.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 0,
                            },
                            price: 258.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 3,
                            },
                            price: 350.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 0,
                            },
                            price: 350.0,
                        },
                    ],
                },
                {
                    title: 'Standardzimmer',
                    subtitle: 'Superior',
                    selected: false,
                    showEquipment: false,
                    img: '/img/einmalig/alibeySuperiorStandardRoom.jpg',
                    equipment: [
                        '26 m2',
                        'Kabelloses Internet (Wi-Fi)',
                        'SAT LCD TV',
                        'Musikkanäle',
                        'Telefon',
                        'Minibar',
                        'Klimaanlage',
                        'Safe',
                        'Dusche / WC',
                        'Föhn',
                        'Marmorboden',
                        'Möblierter Balkon',
                        'Ultra All inclusive',
                    ],
                    possibilities: [
                        {
                            adult: 1,
                            children: {
                                count: 0,
                            },
                            price: 216.0,
                        },
                        {
                            adult: 1,
                            children: {
                                count: 1,
                                age: 0,
                            },
                            price: 216.0,
                        },
                        {
                            adult: 1,
                            children: {
                                count: 1,
                                age: 3,
                            },
                            price: 286.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 0,
                            },
                            price: 286.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 0,
                            },
                            price: 286.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 3,
                            },
                            price: 357.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 0,
                            },
                            price: 357.0,
                        },
                    ],
                },
            ],
            familyRooms: [
                {
                    title: 'Familienzimmer',
                    selected: false,
                    img: '/img/einmalig/alibeyFamilyRoom.jpg',
                    showEquipment: false,
                    equipment: [
                        '40 m2',
                        'Kabelloses Internet (Wi-Fi)',
                        'SAT LCD TV',
                        'Musikkanäle',
                        'Telefon',
                        'Minibar',
                        'Klimaanlage',
                        'Safe',
                        'Dusche / WC',
                        'Föhn',
                        'Keramikboden',
                        'Möblierter Balkon',
                        'Ultra All inclusive',
                    ],
                    possibilities: [
                        {
                            adult: 2,
                            children: {
                                count: 0,
                            },
                            price: 386.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 2,
                            },
                            price: 386.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 2,
                                age: 2,
                            },
                            price: 386.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 0,
                            },
                            price: 386.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 1,
                                age: 2,
                            },
                            price: 386.0,
                        },
                        {
                            adult: 4,
                            children: {
                                count: 0,
                            },
                            price: 466.0,
                        },
                    ],
                },
                {
                    title: 'Familienzimmer',
                    subtitle: 'superior',
                    selected: false,
                    img: '/img/einmalig/alibeySuperiorFamilyRoom.jpg',
                    showEquipment: false,
                    equipment: [
                        '40 m2',
                        'Kabelloses Internet (Wi-Fi)',
                        'SAT LCD TV',
                        'Musikkanäle',
                        'Telefon',
                        'Minibar',
                        'Klimaanlage',
                        'Safe',
                        'Dusche / WC',
                        'Föhn',
                        'Keramikboden',
                        'Marmorboden Balkon',
                        'Ultra All inclusive',
                    ],
                    possibilities: [
                        {
                            adult: 2,
                            children: {
                                count: 0,
                            },
                            price: 459.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 1,
                                age: 2,
                            },
                            price: 459.0,
                        },
                        {
                            adult: 2,
                            children: {
                                count: 2,
                                age: 2,
                            },
                            price: 459.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 0,
                            },
                            price: 459.0,
                        },
                        {
                            adult: 3,
                            children: {
                                count: 1,
                                age: 2,
                            },
                            price: 459.0,
                        },
                        {
                            adult: 4,
                            children: {
                                count: 0,
                            },
                            price: 560.0,
                        },
                    ],
                },
            ],
            filteredRooms: [],
            possibleRooms: {
                standard: false,
                family: false,
            },
        }
    },
    created() {
        if (this.navigationVisible) this.toggleNavigationBarVisibility()
        this.initialSetup()
    },
    beforeDestroyed() {
        if (!this.navigationVisible) this.toggleNavigationBarVisibility()
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'setNavigationBarText',
            'toggleNavigationBarVisibility',
            'setNavigationbarVisibility',
        ]),
        initialSetup() {
            api.getUser().then((data) => {
                this.faultyBirthday = false
                if (!this.$moment(data.user.geburtstag, 'YYYY-MM-DD', true).isValid()) {
                    this.faultyBirthday = true
                }
                if (!this.$moment(data.user.geburtstag, 'YYYY-MM-DD').isBefore('2010-05-24')) {
                    this.faultyBirthday = true
                }
                if (data.user.geburtstag === '--') {
                    data.user.geburtstag = null
                    this.faultyBirthday = true
                }
                if (!this.faultyBirthday) this.userBirthday = data.user.geburstag
                this.form.persons = []
                this.form.persons.push({
                    email: data.user.email,
                    firstName: data.user.vorname,
                    lastName: data.user.nachname,
                    birthDayPicker: {
                        activeMenu: false,
                        date: data.user.geburtstag,
                    },
                    shirtSize: null,
                })
            })
        },
        exitAlibey() {
            if (!this.navigationVisible) this.toggleNavigationBarVisibility()
            this.$router.push('/alibey')
        },
        saveBirthday() {
            if (this.$moment(this.userBirthday, 'YYYY-MM-DD', true).isValid()) {
                console.log('VALID DATE', this.userBirthday)
                api.setUserBirthday(this.userBirthday).then((data) => {
                    this.initialSetup()
                })
            } else {
                alert('Bitte gebe ein gültiges Geburtsdatum ein')
            }
        },
        forwardToProfile() {
            if (!this.navigationVisible) this.toggleNavigationBarVisibility()
            this.$router.push('/user/edit')
        },
        formatPrice(x, places = 2, country = 'de-CH') {
            return Number(x || 0).toLocaleString(country, {
                maximumFractionDigits: places,
                minimumFractionDigits: places,
            })
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            })
        },
        selectRoom(index) {
            this.filteredRooms.forEach((room, i) => {
                index === i ? (room.selected = true) : (room.selected = false)
            })
        },
        showEquipment(index) {
            this.filteredRooms.forEach((room, i) => {
                index === i ? (room.showEquipment = !room.showEquipment) : (room.showEquipment = false)
            })
        },
        deletePerson(index) {
            if (this.form.persons.length > 1) {
                this.form.persons.splice(index, 1)
            } else {
                this.showSnackBarWithMessage('Mindestens eine Person muss bestehen bleiben.')
            }
        },
        formIsValid() {
            return false
        },
        checkIfChild(date) {
            let returnString = ''
            if (this.bookingDateRange && this.bookingDateRange.length && date) {
                returnString = this.$moment(date).isBefore('2010-05-25') ? 'Erwachsener' : 'Ermässigt'
                return (returnString += ' (' + this.$moment(this.bookingDateRange[0]).diff(date, 'years') + ')')
            }
        },
        saveDatePicker(date, index) {
            this.$refs['birthDayPicker' + index][0].save(date)
        },
        createBooking() {
            this.bookingLoading = true
            let newListEntry = {
                category: 'alibey',
                content: {
                    adults: this.adultCount,
                    form: this.form,
                    duration: this.duration,
                    dateRange: this.bookingDateRange,
                    room: this.selectedRoom,
                },
            }
            api.createListEntry(newListEntry).then((data) => {
                alert(
                    'Deine Buchung wurde gespeichert.  Die Rechnung werden wir Dir in den nächsten Tagen zustellen. Wir freuen uns auf Dich.'
                )

                this.$router.push({ name: 'Home' })
            })
        },
        showSnackBarWithMessage(message) {
            this.snackBarMessage = message
            this.showSnackBar = true
        },
        addNewPerson() {
            if (this.form.persons.length >= 4) {
                this.showSnackBarWithMessage('max. 4 Personen')
            } else {
                this.form.persons.push({
                    firstName: null,
                    lastName: null,
                    birthDayPicker: {
                        activeMenu: false,
                        date: null,
                    },
                    shirtSize: null,
                })
            }
        },
        setPickerToYear(index) {
            this.form.persons[index] && setTimeout(() => (this.activeBirthDayPicker = 'YEAR'))
        },
        slideToSelection() {
            // return this.$toast.info('Die Buchung ist noch nicht freigeschaltet. Infos folgen bald!')
            if (this.$moment(this.bookingDateRange[1]).isBefore(this.$moment(this.bookingDateRange[0]))) {
                this.showSnackBarWithMessage('Das Datum wurde in der falschen Reihe angegeben.')
                return
            }
            if (this.$refs.personForm.validate()) {
                this.calculateOptions()
                this.slideTo(1)
            } else {
                this.showSnackBarWithMessage('Alle Pflichtfelder müssen ausgefüllt sein')
            }
        },
        confirmRoom() {
            this.selectedRoom && this.slideTo(2)
            this.scrollToTop()
        },
        calculateOptions() {
            this.filteredRooms = []
            let adults = 0
            let children = {
                count: 0,
                age: null,
            }
            this.form.persons.forEach((person) => {
                let personAge = this.$moment(this.bookingDateRange[0]).diff(person.birthDayPicker.date, 'years')
                person.age = personAge
                if (person.age > 12) {
                    adults++
                } else {
                    children.count++
                    children.age = personAge
                }
            })
            // check if family / standard rooms are possible
            let standard = false
            let family = false
            if (this.form.persons.length >= 2) family = true
            if (this.form.persons.length <= 3) standard = true

            if (standard) {
                this.standardRooms.forEach((room) => {
                    room.possibility = room.possibilities.find((possibility) => {
                        if (possibility.adult === adults && children.count === possibility.children.count) {
                            if (children.count === 2 || children.count === 0) {
                                return true
                            } else if (
                                children.count === 1 &&
                                this.childrenAge[possibility.children.age].start <= children.age &&
                                this.childrenAge[possibility.children.age].end >= children.age
                            ) {
                                return true
                            }
                            return false
                        }
                    })
                    room.possibility && this.filteredRooms.push(room)
                })
            }
            if (family) {
                this.familyRooms.forEach((room) => {
                    room.possibility = room.possibilities.find((possibility) => {
                        return possibility.adult === adults && children.count === possibility.children.count
                    })
                    room.possibility && this.filteredRooms.push(room)
                })
            }
            this.adultCount = adults
        },
        slideTo(slide) {
            this.$refs.aliBeySwiper.$swiper.slideTo(slide, 1000, false)
        },
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
        formattedBookingDateRange() {
            if (this.bookingDateRange)
                return (
                    'von ' +
                    this.$moment(this.bookingDateRange[0]).format('DD.MM.YYYY') +
                    ' bis ' +
                    this.$moment(this.bookingDateRange[1]).format('DD.MM.YYYY')
                )
        },
        duration: function () {
            return this.$moment(this.bookingDateRange[1]).diff(this.$moment(this.bookingDateRange[0]), 'days')
        },
        selectedRoom: function () {
            return this.filteredRooms.find((room) => room.selected)
        },
        aliBeySwiperOptions: function () {
            return {
                allowTouchMove: false,
                pagination: {
                    el: '.swiper-pagination',
                    dynamicBullets: true,
                },
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.notSelected {
    opacity: 0.5;
    filter: blur(1px);
}
.equipment-button {
    position: absolute;
    bottom: 0;
}

.person-delete-lottie {
    display: unset;
}

.h5 {
    text-transform: uppercase;
    font-weight: bold;
}

.v-card {
    transition: opacity 0.4s ease-in-out;
}

.swiper-pagination {
    bottom: 5px;
}

.ali-bey-booking {
    &__swiper {
        width: 90% !important;
        height: 90% !important;
        margin-left: 5%;
        margin-right: 5%;

        &-card {
            text-align: -webkit-center;
            height: 80vh;
            min-height: 500px;
            overflow: auto;

            &-selected-room {
                &-image {
                    border-top-left-radius: 25px;
                    border-top-right-radius: 25px;
                }
                &-chips {
                    max-height: 150px;
                    overflow-y: auto;
                }
            }
            &-content {
                width: 90%;
                height: 62vh;
                overflow-y: auto;
                overflow-x: hidden;
            }
            &-image {
                margin: 0 10%;
                height: 40%;
                background-size: contain;
            }

            &-title {
                word-break: break-word;
            }

            &-delete {
                position: absolute;
                right: 20px;
            }

            &-steps {
                width: unset;

                &__wrapper {
                    width: 80%;
                    height: 60%;
                    text-align: center;
                    margin-left: 10%;
                }
            }
        }
    }
    &__new-exercise-card {
        bottom: 0;
        opacity: 1 !important;
        position: absolute;
        width: 100%;
        height: 100%;
    }
}
</style>
