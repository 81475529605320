<template>
    <v-dialog persistent max-width="500px" v-model="showDialog">
        <v-card>
            <v-card-title class="px-5 primary white--text">Kundeninteraktion</v-card-title>
            <v-stepper v-model="step">
                <v-stepper-items>
                    <v-stepper-content step="1">
                        <v-row no-gutters>
                            <v-col v-if="!employeeInfos._id" cols="12">
                                <user-search
                                    class="px-5 mb-2 mt-5"
                                    :value="employeeInfos._id"
                                    ignoreRights
                                    instructor
                                    label="Trainer"
                                    @update="updateEmployee"
                                ></user-search>
                            </v-col>
                            <v-col class="d-flex flex-column" cols="12" v-else>
                                <v-card-subtitle class="mt-0 pt-0 font-weight-bold">Trainer</v-card-subtitle>
                                <v-card-text>
                                    {{ employeeInfos.firstName }} {{ employeeInfos.lastName }}
                                    <br />
                                    {{ employeeInfos.email }}
                                </v-card-text>
                                <v-btn
                                    @click="employeeInfos = { _id: null }"
                                    outlined
                                    color="primary"
                                    class="mx-auto mb-5"
                                >
                                    Trainer wechseln
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn @click="close" text>Schließen</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(1)" color="primary">Weiter</v-btn>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-content step="2">
                        <v-row no-gutters>
                            <v-col v-if="!customerInfos._id" cols="12">
                                <user-search
                                    class="px-5 my-2"
                                    :value="customerInfos._id"
                                    ignoreRights
                                    label="Kunde"
                                    @update="updateCustomer"
                                ></user-search>
                            </v-col>
                            <v-col v-else cols="12" class="d-flex flex-column">
                                <v-card-subtitle class="mt-0 pt-0 font-weight-bold">Kunde</v-card-subtitle>
                                <v-card-text>
                                    {{ customerInfos.firstName }} {{ customerInfos.lastName }}
                                    <br />
                                    {{ customerInfos.email }}
                                </v-card-text>
                                <v-btn
                                    @click="customerInfos = { _id: null }"
                                    outlined
                                    color="primary"
                                    class="mx-auto mb-5"
                                >
                                    Kunde wechseln
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn @click="prevStep" text>Zurück</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(2)" color="primary">Weiter</v-btn>
                        </v-card-actions>
                    </v-stepper-content>

                    <v-stepper-content step="3">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-select
                                    class="mt-2"
                                    v-model="reason"
                                    hide-details
                                    outlined
                                    :items="items"
                                    label="Was wurde besprochen?"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    v-model="location"
                                    hide-details
                                    outlined
                                    class="mt-5"
                                    :items="itemsLocation"
                                    label="Besprechungsort"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="notes"
                                    hide-details
                                    outlined
                                    class="mt-5 mb-2"
                                    label="Notizen"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-btn @click="prevStep" text>Zurück</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn :loading="saving" @click="save" color="primary">Speichern</v-btn>
                        </v-card-actions>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '@/api'
import userSearch from '@/components/userSearch.vue'

export default {
    components: { userSearch },
    data() {
        return {
            saving: false,
            step: 1,
            items: [
                'Alibey Sportwoche empfohlen',
                'Alltagsintegration von Fitness',
                'Ausführung',
                'Erfahrungen & Feedback',
                'Ernährung & Diät',
                'Gesundheit & Wohlbefinden',
                'roody Account erstellt',
                'Sportwissenschaftliche Grundlagen',
                'Technik & Ausrüstung',
                'Ziele & Motivation',
                'Weiteres',
            ],
            itemsLocation: ['Trainingsfläche', 'Empfang', 'Aufenthaltszone (Lounge)'],
            showDialog: true,
            reason: null,
            location: null,
            notes: null,
            employeeInfos: { _id: null },
            customerInfos: { _id: null },
        }
    },
    created() {
        this.employeeInfos._id = '5f55fdad6716c132adf93baa'
        this.customerInfos._id = '5f55fdad6716c132adf93baa'
        this.updateEmployee('5f55fdad6716c132adf93baa')
        this.updateCustomer('5f55fdad6716c132adf93baa')
    },
    methods: {
        validateStep1() {
            return this.employeeInfos._id && this.employeeInfos._id.length > 4
        },
        validateStep2() {
            return this.customerInfos._id && this.customerInfos._id.length > 4
        },
        validateStep3() {
            return this.reason && this.reason.length > 2 && this.location && this.location.length > 2
        },
        nextStep(currentStep) {
            if (currentStep === 1 && this.validateStep1()) {
                this.step = 2
            } else if (currentStep === 2 && this.validateStep2()) {
                this.step = 3
            }
        },
        prevStep() {
            this.step -= 1
        },
        async save() {
            this.saving = true
            if (this.validateStep3()) {
                let entry = {
                    user: this.customerInfos._id,
                    employee: this.employeeInfos._id,
                    date: this.$moment().format(),
                    reason: this.reason,
                    location: this.location,
                    notes: this.notes,
                }

                try {
                    let status = await api.saveCustomerInteraction(entry)

                    this.$toast.success('Gespeichert')
                    this.close()
                } catch (e) {
                    this.$toast.error('Fehler beim Speichern')
                }
            } else {
                this.$toast.error('Bitte fülle alle Felder aus')
            }
            this.saving = false
        },
        reset() {
            //this.employeeInfos = { _id: null }
            this.customerInfos = { _id: null }
            this.reason = null
            this.notes = null
            this.location = null
            this.step = 1
        },
        close() {
            this.reset()
            this.showDialog = false
        },
        async updateEmployee(id) {
            try {
                let employeeManager = await api.getUserForManager(id)

                this.employeeInfos = {
                    _id: id,
                    firstName: employeeManager.vorname,
                    lastName: employeeManager.nachname,
                    email: employeeManager.email,
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden des Accounts')
            }

            this.$forceUpdate()
        },
        async updateCustomer(id) {
            try {
                let employeeManager = await api.getUserForManager(id)

                this.customerInfos = {
                    _id: id,
                    firstName: employeeManager.vorname,
                    lastName: employeeManager.nachname,
                    email: employeeManager.email,
                }
            } catch (e) {
                this.$toast.error('Fehler beim Laden des Accounts')
            }

            this.$forceUpdate()
        },
    },
}
</script>
