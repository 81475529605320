<template>
    <v-row>
        <v-col cols="12">
            <customerInteraction />
        </v-col>
    </v-row>
</template>

<script>
import api from '@/api'

import customerInteraction from '@/components/employee/customerInteraction.vue'

export default {
    components: { customerInteraction },
    data() {
        return {}
    },
    async created() {},
    methods: {},
}
</script>
