<template>
    <div>
        <v-row>
            <v-col cols="4"
                ><v-card class="anamnese-statistics__add-question">
                    <v-responsive :aspect-ratio="1 / 1"> <v-card-title>Frage hinzufügen</v-card-title></v-responsive>
                </v-card></v-col
            >
        </v-row>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import homeDashboardTrainerStatisticsDialog from './homeDashboardTrainerStatisticsDialog.vue'
export default {
    name: 'dashboard',
    props: {
        studios: {
            type: Array,
        },
        datePickerRange: {
            type: Array,
        },
        selectedStudioSingle: {
            type: String,
        },
    },
    components: {
        homeDashboardTrainerStatisticsDialog,
    },
    data() {
        return {}
    },
    created() {
        if (this.selectedStudioSingle) {
            api.fetchAnamneseDashboardQuestions({
                range: this.datePickerRange,
                selectedStudios: this.studios.map((studio) => studio._id),
                studios: this.studios.map((studio) => {
                    return studio.anamneseSettings && studio.anamneseSettings.copiedStudio
                        ? studio.anamneseSettings.copiedStudio
                        : studio._id
                }),
            })
        }
    },
    methods: {},
    computed: {},
    watch: {
        studios: function (studios) {
            api.fetchAnamneseDashboardQuestions({
                range: this.datePickerRange,
                studios: studios.map((studio) => {
                    return studio.anamneseSettings && studio.anamneseSettings.copiedStudio
                        ? studio.anamneseSettings.copiedStudio
                        : studio._id
                }),
                selectedStudios: this.studios.map((studio) => studio._id),
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.anamnese-statistics {
    &__add-question {
        background: transparent;
        border: 5px dashed var(--v-primary);
    }
}
</style>
