var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authenticated)?_c('div',{staticClass:"Home"},[(false)?_c('div',{staticClass:"Home__greeting"},[_c('v-card-subtitle',{staticClass:"Home__greeting-subtitle text-black"},[_vm._v("Hey "+_vm._s(_vm.user.vorname)+",")]),(!_vm.alibeyStudio || _vm.alibeyDate <= 0)?_c('v-card-title',{staticClass:"Home__greeting-title h6 pb-1"},[_vm._v(" Bist Du bereit für "),_c('br'),_vm._v(" dein heutiges "),_c('span',{staticClass:"Home__greeting-title-training"},[_vm._v("Training")]),_vm._v(" ? ")]):_c('v-card-title',{staticClass:"Home__greeting-title h6 pb-1"},[_vm._v("bald ist es soweit ...")]),(!_vm.alibeyStudio)?_c('div',{staticClass:"Home__greeting-gift"},[_c('v-img',{attrs:{"height":"90px","width":"90px","src":"/img/icons/giftGreen.gif"},on:{"click":function($event){return _vm.pushToReferralRoute()}}})],1):_vm._e()],1):_vm._e(),_c('div',{staticClass:"Home__diverse mt-5"},[_c('diverse-slider',{attrs:{"data-v-step":"1"}})],1),(_vm.alibeyStudio)?_c('v-row',{staticClass:"mt-2 mb-5"},[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.alibeyDate > 0)?_c('vue-countdown',{staticClass:"countdownAlibey",attrs:{"time":_vm.alibeyDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.days;
var hours = ref.hours;
var minutes = ref.minutes;
var seconds = ref.seconds;
return [_c('div',{staticClass:"tage date"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(days)+" ")]),_c('span',{staticClass:"down"},[_vm._v("Tage")])]),_c('div',{staticClass:"stunden date"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(hours)+" ")]),_c('span',{staticClass:"down"},[_vm._v("Stunden")])]),_c('div',{staticClass:"minuten date"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(minutes)+" ")]),_c('span',{staticClass:"down"},[_vm._v("Minuten")])]),_c('div',{staticClass:"sekunden date"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(seconds)+" ")]),_c('span',{staticClass:"down"},[_vm._v("Sekunden")])])]}}],null,false,2828063284)}):_vm._e()],1)],1):_vm._e(),_c('div',[_c('payment-home',{on:{"foundOpenPayments":function($event){_vm.openPayments = true}}})],1),(!_vm.alibeyStudio && !_vm.hideMyTraining)?_c('div',{staticClass:"Home__training"},[_c('my-training',{attrs:{"data-v-step":"0"}})],1):_vm._e(),(false && _vm.alibeyStudio)?_c('v-row',{staticClass:"personalTraining"},[_c('v-btn',{staticClass:"white--text mx-auto py-4 my-5",attrs:{"x-large":"","color":"#ffa500"},on:{"click":function($event){return _vm.$router.push('/terminept')}}},[_c('span',{staticClass:"py-5"},[_vm._v(" Dein "),_c('br'),_vm._v(" Willkommensgeschenk ")])])],1):_vm._e(),_c('membercard',{attrs:{"firstName":_vm.user.vorname,"email":_vm.user.email,"membercard":_vm.user.membercard},on:{"hideTraining":function($event){_vm.hideMyTraining = true}}}),(_vm.tendays_date != null && _vm.tendays_date > 0 && !_vm.user.abo)?_c('router-link',{staticClass:"link",attrs:{"to":"/membercard","tag":"div"}},[_c('div',{staticClass:"membercard-wrapper"},[_c('div',{staticClass:"countdown-wrapper"},[_c('span',{staticClass:"time_left"},[_vm._v("Verbleibende Zeit")]),_c('vue-countdown',{staticClass:"countdown",attrs:{"time":_vm.tendays_date},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var days = ref.days;
var hours = ref.hours;
var minutes = ref.minutes;
var seconds = ref.seconds;
return [_c('div',{staticClass:"tage"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(days)+" ")]),_c('span',{staticClass:"down"},[_vm._v("TAGE")])]),_c('div',{staticClass:"stunden"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(hours)+" ")]),_c('span',{staticClass:"down"},[_vm._v("STUNDEN")])]),_c('div',{staticClass:"minuten"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(minutes)+" ")]),_c('span',{staticClass:"down"},[_vm._v("MINUTEN")])]),_c('div',{staticClass:"sekunden"},[_c('span',{staticClass:"up"},[_vm._v(" "+_vm._s(seconds)+" ")]),_c('span',{staticClass:"down"},[_vm._v("SEKUNDEN")])])]}}],null,false,327049876)})],1),_c('span',{staticClass:"mcard-span"},[_vm._v("MEMBERCARD")])])]):_vm._e(),_c('div',{staticClass:"Home__groupFitness pb-5 mb-10"},[_c('next-courses-home',{attrs:{"data-v-step":"2"}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }