<template>
    <v-card flat>
        <v-row v-if="!loading && studioList">
            <v-btn
                v-if="$route && $route.query && $route.query.previousRoute"
                fab
                dark
                class="mt-2"
                color="white"
                @click="$router.push($route.query.previousRoute)"
            >
                <v-icon color="black">mdi-chevron-left</v-icon>
            </v-btn>
            <v-col v-if="selectedStudio">
                <v-row>
                    <v-col cols="12">
                        <v-card-title class="d-flex justify-center">{{ selectedStudio.name }}</v-card-title>
                    </v-col>
                    <v-col cols="12" v-if="showPreSaleMessage">
                        <v-alert type="info" v-if="selectedStudio.code !== 'arbon'">
                            Jetzt ein Abo lösen und bis zur Eröffnung
                            {{
                                selectedStudio.code === 'stgallenost' ? 'am Montag, 08. April 2024' : 'im Frühjahr 2024'
                            }}
                            an allen Standorten der well come FIT Gruppe kostenlos trainieren.

                            {{
                                selectedStudio.code === 'stgallenost'
                                    ? 'Profitiere zusätzlich von einem Preisvorteil in Höhe von CHF 99.-, wenn du dein Abo jetzt löst.'
                                    : ''
                            }}
                        </v-alert>
                    </v-col>
                    <v-dialog persistent v-model="showPreSaleCityDialog" width="500">
                        <v-card>
                            <v-card-title class="text-h5 primary text-white mb-5">
                                {{ selectedStudio.code === 'arbon' ? 'Baustellenvorverkauf' : 'Vorverkauf' }}
                            </v-card-title>

                            <v-card-text>
                                {{
                                    selectedStudio.code === 'arbon'
                                        ? 'Teste hier, ob Du von unserem Vorverkaufsangebot profitieren kannst: Gib die Postleitzahl deines Wohnortes ein und sichere dir die geschenkte Aktivierungsgebühr im Wert von CHF 99.-'
                                        : 'Teste hier, ob Du in den Genuss des Vorverkaufs-Angebot kommst und gebe die Postleizahl deines Wohnortes ein:'
                                }}
                            </v-card-text>

                            <v-row no-gutters>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Postleitzahl"
                                        type="number"
                                        class="mx-auto d-flex textfield"
                                        v-model="cityNumber"
                                    ></v-text-field>
                                </v-col>
                            </v-row>

                            <v-card-actions>
                                <v-btn color="primary" text @click="selectedStudio = null" v-if="!directLink">
                                    Zurück
                                </v-btn>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="checkCityDialog">Bestätigen</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-col v-if="!loadingContracts" cols="12">
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th class="text-left">Verträge</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    @click="pushToContract(contract._id)"
                                    v-for="contract in contractList"
                                    :key="contract._id"
                                >
                                    <td>{{ contract.internalTitle }}</td>
                                    <!---  <td v-if="contract.formFields.find((e) => e.inputType === 'title')">
                                        {{ contract.formFields.find((e) => e.inputType === 'title').inputValue }}
                                    </td>
                                    <td v-else>Vertrag</td>-->
                                    <td class="d-flex justify-end align-center">
                                        <v-btn icon><v-icon>mdi-pen-plus</v-icon></v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </v-col>
                    <v-col v-else cols="12">
                        <v-progress-linear indeterminate color="primary"></v-progress-linear>
                    </v-col>
                    <v-col v-if="!directLink" cols="12" class="justify-center d-flex">
                        <v-btn @click="selectedStudio = null" text>Zurück</v-btn>
                    </v-col>
                </v-row>
            </v-col>
            <v-col v-else>
                <v-card-title class="d-flex justify-center">Wähle dein Standort aus:</v-card-title>
                <v-row class="d-flex justify-space-evenly justify-center">
                    <v-col cols="12" class="text-center">
                        <v-chip
                            :color="selectedCanton === canton ? 'primary' : 'default'"
                            @click="selectedCanton = selectedCanton === canton ? null : canton"
                            v-for="canton of studioCantons"
                            :key="canton"
                            class="ma-2"
                        >
                            {{ canton }}
                        </v-chip>
                    </v-col>

                    <v-col
                        style="cursor: pointer; min-height: 90px"
                        class="d-flex flex-column align-center justify-center"
                        cols="5"
                        v-for="studio of studioList
                            .filter((e) => obfeldenCheck(e))
                            .filter((e) => e.canton === (selectedCanton == null ? e.canton : selectedCanton))"
                        :key="studio._id"
                    >
                        <v-card
                            outlined
                            color="primary"
                            @click="selectedStudio = studio"
                            style="width: 100%"
                            class="studioSelection elevation-7 d-flex fill-height justify-center align-center text-center white--text"
                        >
                            <span class="font-weight-bold">{{ studio.name }}</span>
                        </v-card>
                    </v-col>
                </v-row>
                <div>
                    <div v-for="studio of studioList" :key="studio._id"></div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import api from '@/api'
export default {
    name: 'contractCreatorExtern',
    data() {
        return {
            directLink: false,
            skipPresale: false,
            showPreSaleMessage: false,
            showPreSaleCityDialog: false,
            studioList: [],
            loading: false,
            loadingContracts: false,
            selectedStudio: null,
            contractList: [],
            cityNumber: null,
            allowedCities: [],
            selectedCanton: null,
        }
    },
    created() {
        this.fetchStudios()
        console.log(this.$route)
    },
    mounted() {
        if (this.navigationVisible) this.toggleNavigationBarVisibility()
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', [
            'toggleNavigationBarVisibility',
            'setNavigationBarText',
            'setNavigationbarVisibility',
        ]),
        obfeldenCheck(e) {
            if (this.$moment().isAfter(this.$moment('01-05-2024', 'DD-MM-YYYY'))) return true

            return !e.name.toLowerCase().includes('obfelden')
        },
        checkCityDialog() {
            if (this.allowedCities.includes(this.cityNumber.toString())) {
                this.$toast.success('Deine Postleitzahl ist gültig. Du kannst dich jetzt registrieren.')
                this.showPreSaleCityDialog = false
            } else {
                this.$toast.error(
                    'Die eingegebene Postleitzahl ist leider nicht gültig für den Vorverkauf. Falls du dennoch teilnehmen möchtest, kontaktiere uns bitte unter info@wellcomefit.ch, um weitere Informationen zu erhalten.'
                )
                if (this.selectedStudio.code === 'arbon') {
                    this.skipPresale = true
                    this.showPreSaleCityDialog = false
                }
            }
        },
        async fetchStudios() {
            let studios = await api.fetchPublicStudios()
            console.log(studios)

            if (this.$route.query.studio) {
                let studio = studios.find((e) => e.code === this.$route.query.studio)
                if (studio) {
                    this.directLink = true

                    this.selectedStudio = studio
                    this.checkPreSaleStudios()
                }
            }
            this.studioList = studios
        },
        checkPreSaleStudios() {
            // Aktuelles Datum
            const today = this.$moment()

            // Ziel-Datum zum Vergleich
            const targetDate = this.$moment('2024-03-01')

            // Prüfe, ob das aktuelle Datum dem Ziel-Datum entspricht
            if (today.isSameOrAfter(targetDate, 'day')) {
                this.showPreSaleCityDialog = this.selectedStudio.code === 'arbon'
                this.showPreSaleMessage = this.selectedStudio.code === 'arbon'

                if (this.selectedStudio.code === 'arbon') {
                    this.allowedCities = [
                        '9320',
                        '9322',
                        '8599',
                        '8590',
                        '9314',
                        '9315',
                        '9325',
                        '9306',
                        '9305',
                        '9323',
                        '9326',
                        '9327',
                        '9403',
                        '9404',
                        '9422',
                        '9423',
                        '9425',
                        '9424',
                        '9036',
                        '9404',
                        '9308',
                        '9313',
                    ]
                }
            }
        },
        pushToContract(id) {
            this.$router.push('/contract/create/' + id + '/' + this.selectedStudio._id + '/' + this.skipPresale)
        },
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationItems', 'navigationVisible']),
        studioCantons() {
            if (!this.studioList.length > 0) return []
            return [...new Set(this.studioList.filter((e) => e.canton).map((e) => e.canton))]
        },
    },
    watch: {
        async selectedStudio() {
            if (this.selectedStudio !== null) {
                this.checkPreSaleStudios()
                this.loadingContracts = true
                let contracts = await api.fetchContractsPublic(this.selectedStudio._id)
                this.contractList = contracts
                if (contracts.length === 1 && !this.showPreSaleMessage) {
                    this.pushToContract(contracts[0]._id)
                }
                console.log(this.contractList, this.showPreSaleMessage)
                this.loadingContracts = false
            } else {
                this.contractList = []
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.textfield {
    width: 150px;
}
</style>