<template>
    <v-card class="mx-auto" flat max-width="700px">
        <v-carousel
            :show-arrows="false"
            hide-delimiter-background
            :hide-delimiters="!(slides.length > 1)"
            cycle
            class="rounded-lg carouselClass"
            vertical-delimiters="right"
            vertical
            height="auto"
        >
            <v-carousel-item
                @click="openLink(slide)"
                v-for="(slide, i) of slides"
                :key="i + '-slide'"
                cover
                :src="'/img/' + slide.image"
            ></v-carousel-item>
        </v-carousel>
    </v-card>
</template>

<script>
import api from '../../api'

export default {
    name: 'diverseSlider',

    data() {
        return {
            loading: true,
            slides: [],
        }
    },

    async created() {
        this.slides = [...(await api.fetchMainSlider()), ...(await api.fetchEventsForSlider())]
        this.loading = false
    },
    methods: {
        openLink(slide) {
            let link = slide.link || null
            if (link) {
                if (link.includes('http://') || link.includes('https://')) {
                    window.open(link, '_blank').focus
                } else {
                    this.$router.push(link)
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
