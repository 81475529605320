<template>
    <div class="d-flex justify-space-between flex-column overflow-y-auto">
        <div>
            <v-img
                class="pt-4 mx-5"
                min-height="125px"
                contain
                max-height="250px"
                src="/img/einmalig/alibeyLogo.svg"
            ></v-img>
            <v-card-title class="h6 justify-center pb-0 d-flex flex-column font-weight-bold">
                <span>Ali Bey Club Manavgat, Antalya</span>
                <span>31.MAI 2025 – 07.JUNI 2025</span>
            </v-card-title>
            <v-card-title class="alibey--text justify-center h6 mb-0">SPORT- & GESUNDHEITSWOCHE</v-card-title>

            <v-carousel class="ali-bey__card-carousel rounded" height="250" continuous hide-delimiters cycle>
                <v-carousel-item
                    contain
                    v-for="(image, i) in carouselImages"
                    :key="i"
                    :src="'https://app.wellcomefit.ch/img/einmalig/alibey/slider/' + image"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                ></v-carousel-item>
            </v-carousel>
        </div>
        <v-card-text class="ali-bey__card-text text-center">
            Sonne, Meer und ganz viel Fitness!
            <br />
            Das bietet die Sportwoche mit dem
            <br />
            well come FIT und dem winti FIT
            <br />

            vom 31. Mai 2025 – 07. Juni 2025
            <br />
            an der wunderschönen Türkischen Riviera
            <br />
            für Mitglieder und Nichtmiglieder.
            <br />
            <br />

            Ein Fitness Center vor Ort, tägliche Groupfitnesskurse, Personaltraining und Ernährungsplanung mit Experten
            bringen Deine Trainingsroutine auf ein neues Level.
            <br />
            <br />

            TOP Instruktorinnen und Instruktoren laden Dich täglich vormittags und am frühen Abend zu den aktuellsten
            und beliebtesten Groupfitness Stunden ein.
            <br />
            <br />

            Der Mix aus Sport, Gesundheit, Party und Erholung macht dieses Fitness Event zu einem unvergleichlichen
            Erlebnis für Dich, Deine Freunde und Deine Familie!
            <br />
            <br />
        </v-card-text>
        <v-card-text class="d-flex flex-column">
            <!--<span class="font-weight-bold alibey--text pb-4 align-self-center">
				Empfohlene Flugdaten werden bei Bekanntgabe aufgeschaltet.
			</span>-->
            <span class="font-weight-bold py-4 align-self-center text-center" @click="pushToBookingPage">
                Buche gleich hier Deine Frühlings Sportwoche
            </span>

            <strong class="text-center">Flugempfehlung:</strong>
            <span class="text-center">31.05.25 09:40 Uhr ZRH - 13:55 Uhr AYT (SunExpress)</span>
            <span class="text-center">07.06.25 14:55 Uhr AYT - 17:35 Uhr ZRH (SunExpress)</span>
            <v-btn class="mx-auto mt-2" outlined @click="openSunExpress">SunExpress</v-btn>
        </v-card-text>

        <!--<v-card-text class="d-flex flex-column align-items-center text-center">
			<span class="py-1 font-weight-bold">100 % Geld-Zurück-Garantie:</span>
			<span class="py-1 text-center">
				Wenn internationale Reisen aufgrund von Covid 19 zwischen Schweiz und Türkei nicht möglich sind, werden dir 100 %
				zurückerstattet.</span
			>
			<span class="py-1 text-center">
				Wenn Du aufgrund einer Corona-Erkrankung oder eines anderen Gesundheitsproblems (unter Vorlage eines ärztlichen Attest) reiseunfähig
				bist, werden Dir 100 % zurückerstattet</span
			>
			<span class="py-1 text-center"> 100 % Rückerstattung bis 7 Tage vor dem Check-In</span>
		</v-card-text>--->
        <!-- <v-card-title class="font-weight-bold h6 justify-center text-break">
			KLEINER TIP
			<lottie-player
				mode="normal"
				style="width:32px;height:32px;"
				class="alibey-lottie"
				loop
				name="deletePersonIcon"
				autoplay
				src="/img/lottiefiles/alibeyLightbulb.json"
			></lottie-player>
			Je früher Du buchst, um so günstiger sind die Preise (Die hier ausgewiesenen Preise entsprechen den tagesaktuellen Preisen des Hotels)
		</v-card-title>
-->
        <div>
            <v-card-title class="justify-center h5 mb-0">LEISTUNGEN WÄHREND DER EVENTWOCHE</v-card-title>

            <v-card-text class="text-center d-flex flex-column align-items-center">
                <span class="py-1">
                    7 Nächte im Standard Doppelzimmer (weitere Zimmerkategorien gegen Aufpreis buchbar
                </span>
                <span class="py-1">Transfer Hotel / Flughafen</span>
                <span class="py-1">
                    <strong>Ultra</strong>
                    All inclusive Verpflegung
                </span>
                <span class="py-1">Tägliche Groupfitness Kurse</span>
                <span class="py-1">Kostenlose Nutzung des Fitness Studios während der Veranstaltungswoche</span>
                <span class="py-1">
                    Wellcome Party und Gala Abend (Durchführung abhängig von den jeweils geltenden Hygiene-Vorschriften
                    zum Zeitpunkt der Reise)
                </span>
                <span class="py-1">Betreuung durch well come FIT Mitarbeiter vor Ort</span>
                <br />
                <br />
                <br />
            </v-card-text>
        </div>
        <div style="margin-bottom: 200px">
            <v-card-title class="justify-center h5 mb-0">DEINE VORTEILE</v-card-title>

            <v-card-text class="d-flex flex-column align-items-center mb-5">
                <span class="py-1">Individuelle Trainings- und Ernährungsberatung</span>
                <span class="py-1">7 Tage Groupfitness Kurse</span>
                <span class="py-1">Kostenlose Nutzung des Fitness Studios während der Veranstaltungswoche</span>
                <span class="py-1">Trainer- und Instruktorennetzwerk des well come FIT und winti FIT</span>
                <span class="py-1">well come FIT und winti FIT Community vor Ort</span>
                <br />
                <br />
                <br />
            </v-card-text>
            <v-card-title class="justify-center h5 mb-0">Ultra All inclusive</v-card-title>
            <v-expansion-panels :value="0" color="primary">
                <v-expansion-panel>
                    <v-expansion-panel-header>Ohne Gebühr</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Minibar (2x Bier, 2x Cola, 2 x Fanta, 2x Wasser und 2x Mineralwasser), 24 Stunden Eiscreme
                        (bekannte Marken wie Algida, Carte D’or), alle Softgetränke, frisch gepresster Orangen- und
                        Grapefruitsaft beim Frühstück, alle lokalen alkoholischen Getränke (Ausnahme Weinsorten), Energy
                        Drink, Sekt/ Champagner sowie alle Import- Getränke mit Ausnahme von einigen Premiumgetränken,
                        Türkisches Fladenbrot Service am Strand, Strandtücher, Safe, Türkischer Hammam, Sauna, Wi-Fi
                        (Zimmer, Lobby und Poolbereich), Türkischer Kaffee mit Patisserie, Aquapark und Tennis bar,
                        Business-Ecke, Mini Club, Beach Volleyball, Wasserball, Tisch Tennis, Shuffle Board, Boccia,
                        Dart, Bogenschießen
                    </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                    <v-expansion-panel-header>Gegen Gebühr</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        Premiumgetränke, diverse Weinsorten, Tennisplätze und Kurse, Padel-Tennisplätze,
                        Tennisausrüstung, Billard, Arcade-Spiele, Massage, VIP-Hammam, Arzt, Geschäfte, Wäsche Service,
                        Friseur, Fotograf, Kinderwagenverleih, Fitness First (für Mitglieder kostenlos),
                        Wassersportmöglichkeiten (Fremdanbieter), Telefon, Panorama A la Carte Restaurant, Teppanyaki A
                        la Carte Restaurant, Pavillonservice am Strand, Mini Golf (mit Vorreservierung) und Kletterpark
                        (mit Vorreservierung)
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <v-card-title>Getränke Bisher (AI):</v-card-title>
            <v-card-text>
                Alle nicht alkoholischen Getränke (Ausnahme: Energy Drink) und alle lokalen alkoholischen Getränke
                (Ausnahme: Weinsorten), frisch gepresster Orangensaft und Grapefruitsaft beim Frühstück
            </v-card-text>
            <v-card-title>Getränke Neu (UA):</v-card-title>
            <v-card-text>
                alle Import Getränke (Ausnahme: einige Premiumgetränke), Sekt/ Champagner, Energy Drink
            </v-card-text>
            <v-card-title>Einige Beispiele an</v-card-title>

            <v-card-text>
                Getränken / Marken: Irish Coffee, White Russian Coffee, Bier: Heineken, Becks, Miller, Bomonti Wodka:
                Absolut, Smirnoff, Ketel One Whiskey: Chivas Regal 12 Yrs, Ballantine’s 12 Yrs, Jack Daniels, Jim Beam,
                Johnnie Walker Black Label 12 Yrs, Johnnie Walker Red Label, The Glenlivet Founder’ Reserve Gin:
                Beefeater, Gordon’s London Dry Rum: Bacardi, Havana Anejo 3 Anos, Captain Morgan Black Tequila: Olmeca
                Gold, El Jimador Liquer: Jagermeister, Amaretto, Malibu, Baileys, Frenet Branca, Liquer 43, Ramazotti
                Digestive: Campari, Martini (Bianco, Rosso, Extra Dry)
            </v-card-text>

            <v-card-actions class="justify-content-center">
                <v-btn color="primary" @click="$router.push('/')">zurück</v-btn>
            </v-card-actions>
        </div>
        <v-snackbar v-model="snackbar">Du hast bereits eine Buchung erstellt.</v-snackbar>
    </div>
</template>

<script>
import api from '../../api'
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
    name: 'aliBey',
    data() {
        return {
            hasListEntry: false,
            snackbar: false,
            carouselImages: [],
        }
    },
    created() {
        for (let i = 1; i <= 8; i++) {
            this.carouselImages.push('alibeySlider' + i + '.jpg')
        }
        api.checkListEntry('alibey').then((data) => (this.hasListEntry = data))
        this.setNavigationBarText({ text: 'RESERVATION', state: false, color: 'alibey' })
    },
    computed: {
        ...mapGetters('navigationStoreNotPersisted', ['navigationBarTextState']),
    },
    methods: {
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText']),
        openSunExpress() {
            window.open('https://sunexpress.com', '__blank')
        },
        pushToBookingPage() {
            // !this.hasListEntry && this.$router.push({ name: 'AliBeyBooking' })
            this.$router.push({ name: 'AliBeyBooking' })
            this.snackbar = true
        },
    },
    watch: {
        async navigationBarTextState(value) {
            // if (value) return this.$toast.error('Die Reservierung ist noch nicht aufgeschaltet. Infos folgen')
            value && this.pushToBookingPage()
        },
    },
}
</script>

<style lang="scss" scoped>
.alibey-lottie {
    display: unset;
}
.h5 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 19px;
}

.ali-bey {
    &__card {
        height: 80vh;

        &-carousel {
            width: 90%;
            max-width: 600px;
            margin: auto;
        }
    }
}
</style>
