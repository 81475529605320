<template>
    <v-dialog fullscreen persistent v-model="showDialog">
        <v-card>
            <v-card-title class="primary white--text">Job angebot</v-card-title>
            <v-container style="max-width: 700px" class="mx-auto">
                <v-img
                    class="mx-auto"
                    height="200"
                    width="200"
                    v-if="job.picture"
                    :src="'/api/website/image/' + job.picture"
                ></v-img>
                <v-col class="d-flex justify-center flex-column" cols="12">
                    <v-file-input
                        outlined
                        hide-details
                        v-model="file"
                        class="mb-2"
                        label="Bild auswählen"
                        @change="onFileChange"
                    ></v-file-input>

                    <v-btn class="mx-auto mb-2" color="primary" @click="uploadImage">Hochladen</v-btn>
                </v-col>
                <v-text-field outlined label="Berufbezeichnung" v-model="job.title"></v-text-field>
                <v-select
                    label="Studios"
                    outlined
                    multiple
                    v-model="job.selectedStudios"
                    :items="studios"
                    item-text="name"
                    item-value="_id"
                ></v-select>
                <v-select
                    outlined
                    :items="durationItems"
                    v-model="job.durationTitle"
                    label="Art der Anstellung"
                ></v-select>
                <v-switch
                    v-model="job.customContact"
                    color="primary"
                    label="Benutzerdefinierte Kontaktperson anstatt Centerleitung"
                ></v-switch>
                <v-row no-gutters v-if="job.customContact">
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="job.customContactInfo.firstName"
                            class="pr-1"
                            label="Vorname"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            v-model="job.customContactInfo.lastName"
                            class="pl-1"
                            label="Nachname"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="job.customContactInfo.email" label="E-Mail"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field v-model="job.customContactInfo.phone" label="Telefon"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                        <v-card-subtitle class="px-0">Anzeigen von bis</v-card-subtitle>
                        <v-date-picker :min="$moment().format('YYYY-MM-DD')" v-model="job.dates" range></v-date-picker>
                    </v-col>
                </v-row>

                <vue-editor ref="myEditor" v-model="job.content" :editorToolbar="customToolbar" />
            </v-container>
            <v-card-actions style="max-width: 700px" class="mx-auto">
                <v-btn @click="close" text>Schliessen</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="save">Speichern</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
  <script>
import { mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import api from '@/api'
export default {
    components: { VueEditor },
    data() {
        return {
            file: null,
            durationItems: ['Teilzeit', 'Festanstellung', 'Lehrstelle', 'Praktikum'],
            showDialog: false,
            job: {
                _id: null,
                picture: null,
                title: null,
                selectedStudios: [],
                durationTitle: null,
                dates: [],
                content: '',
                customContact: false,
                customContactInfo: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                },
            },
            customToolbar: [
                ['bold', 'italic', 'underline'],
                [{ list: 'ordered' }, { list: 'bullet' }],
            ],
        }
    },
    methods: {
        edit(job) {
            let jobOffer = JSON.parse(JSON.stringify(job))
            delete jobOffer.studio
            this.job = jobOffer
            this.showDialog = true
        },
        createNewJob() {
            this.reset()
            this.showDialog = true
        },
        async save() {
            if (!this.checkFields()) {
                this.$toast.error('Bitte fülle alle benötigten Felder aus')
                return
            }

            try {
                let job = this.job
                let status = await api.updateJob({ job })
                this.$toast.success('Job erfolgreich gespeichert')
                this.close()
                this.$emit('update')
            } catch (e) {
                this.$toast.error('Es gab einen Fehler beim Speichern')
            }
        },
        reset() {
            this.job = {
                _id: null,
                picture: null,
                title: null,
                selectedStudios: [],
                durationTitle: null,
                dates: [],
                content: '',
                customContact: false,
                customContactInfo: {
                    firstName: null,
                    lastName: null,
                    email: null,
                    phone: null,
                },
            }
        },
        close() {
            this.showDialog = false
            this.reset()
        },
        checkFields() {
            let job = this.job
            if (!job) return false
            if (!(job.title && job.title.length > 3)) return false
            if (!(job.durationTitle && job.durationTitle.length > 3)) return false
            if (!(job.selectedStudios.length > 0)) return false
            if (job.customContact && !(customContact.firstName && customContact.firstName.length > 2)) return false
            if (job.customContact && !(customContact.lastName && customContact.lastName.length > 2)) return false
            if (job.dates.length !== 2) return false
            return true
        },
        checkFields() {
            let job = this.job
            if (!job) return false
            if (!(job.title && job.title.length > 3)) return false
            if (!(job.durationTitle && job.durationTitle.length > 3)) return false
            if (!(job.selectedStudios.length > 0)) return false
            if (job.customContact && !(customContact.firstName && customContact.firstName.length > 2)) return false
            if (job.customContact && !(customContact.lastName && customContact.lastName.length > 2)) return false
            if (job.dates.length !== 2) return false
            return true
        },
        async uploadImage() {
            if (!this.file) {
                this.$toast.error('Bitte wähle ein Bild aus ')
                return
            }

            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('alt', '')

            try {
                // upload
                let response = await api.uploadWebsiteImage(formData)

                if (response.file) {
                    this.job.picture = response.file

                    this.file = null
                }
            } catch (error) {
                this.$toast.error('Fehler beim hochladen des Bildes')
                console.error(error)
            }
        },
        onFileChange(file) {
            // Temporäres Bild-Element erstellen, um die Dimensionen zu überprüfen
            const img = new Image()

            img.onload = () => {
                const width = img.width
                const height = img.height

                // Überprüfen, ob das Bild im 16:9-Format ist
                if (width / height === 1 / 1) {
                    this.file = file
                } else {
                    this.file = null
                    this.$toast.error('Bitte wählen Sie ein Bild im 1:1-Format.')
                }
            }

            // Fehlerfall, falls das Bild nicht geladen werden kann
            img.onerror = () => {
                this.file = null
                this.$toast.error('Ungültige Bilddatei.')
            }

            // Bildquelle auf das ausgewählte Dateiblob setzen
            img.src = URL.createObjectURL(file)
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        studios() {
            let filterList = ['ot', 'alibey']
            return this.storeStudios
                .filter((e) => e.code && !filterList.includes(e.code))
                .map((e) => ({ name: e.name, _id: e._id, code: e.code }))
        },
    },
}
</script>
  
  <style scoped>
/* Benutzerdefinierter Stil für Aufzählungen */
::v-deep .ql-editor ul {
    list-style-type: none;
    padding-left: 1.5em; /* Platz für das Emoji */
}

::v-deep .ql-editor ul li:before {
    content: '◾'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
    position: absolute;
    margin-left: -1.5em; /* Positionierung des Emojis */
}

::v-deep .ql-editor ol {
    list-style-type: none;
    padding-left: 1.5em; /* Platz für das Emoji */
}

::v-deep .ql-editor ol li:before {
    content: '👉'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
    position: absolute;
    margin-left: -1.5em; /* Positionierung des Emojis */
}
</style>
  