<template>
    <v-row no-gutters>
        <v-col class="pa-5" v-for="(job, i) of jobs" :key="job._id + '-' + i" cols="12" sm="4">
            <v-card class="pb-2" @click="showDetails(job)" color="#eceff4">
                <v-card-subtitle class="pb-0 customFontColor">
                    Studios angewählt: {{ job.selectedStudios.length }}
                </v-card-subtitle>
                <v-card-title class="pt-0 pb-2 customFontColor">{{ job.title }}</v-card-title>
                <v-chip class="mx-5">{{ job.durationTitle }}</v-chip>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
export default {
    props: {
        jobs: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedJob: null,
            showDialog: false,
        }
    },
    methods: {
        showDetails(job) {
            this.$emit('showDetails', job)
        },
    },
    computed: {},
}
</script>

<style scoped>
.customFontColor {
    color: #313131 !important;
}

/* Benutzerdefinierter Stil für Aufzählungen */
::v-deep .ql-editor ul {
    list-style-type: none;
    padding-left: 1.5em; /* Platz für das Emoji */
}

::v-deep .ql-editor ul li:before {
    content: '◾'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
    position: absolute;
    margin-left: -1.5em; /* Positionierung des Emojis */
}

::v-deep .ql-editor ol {
    list-style-type: none;
    padding-left: 1.5em; /* Platz für das Emoji */
}

::v-deep .ql-editor ol li:before {
    content: '👉'; /* Hier das Emoji, das Sie als Aufzählungszeichen verwenden möchten */
    position: absolute;
    margin-left: -1.5em; /* Positionierung des Emojis */
}
</style>

