<template>
    <div class="workouts">
        <div v-if="$route.name === 'Workouts'">
            <v-row v-if="!loadingGFRecommendations && gfRecommendations && gfRecommendations.length > 0">
                <v-col cols="12">
                    <v-card-subtitle class="ml-0 pl-0 pb-2">Deine Group Fitness Empfehlungen</v-card-subtitle>
                    <div class="scroll-container">
                        <v-card
                            flat
                            v-for="(course, index) in gfRecommendations"
                            :key="course._id"
                            class="course-card"
                            inline
                        >
                            <div class="relative-position">
                                <v-img
                                    @click="$router.push('/kurse')"
                                    class="rounded-lg"
                                    :src="'/img/kurse/' + course.bild"
                                    height="80px"
                                    width="160px"
                                ></v-img>
                            </div>
                            <v-card-title style="line-height: 1.2; font-size: 12px" class="mb-0 pb-0 h6 pt-2 px-1">
                                {{ course.name.substring(0, 15) }}
                            </v-card-title>
                        </v-card>
                    </div>
                </v-col>
            </v-row>
            <v-card-title class="d-flex justify-space-between">
                <span class="workouts__title h5">{{ showCustomerWorkouts ? 'Kundenpläne' : '' }}</span>
                <v-btn
                    v-if="userService.hasRight('tp_viewAll')"
                    class="caption px-0"
                    text
                    @click="toggleCustomerWorkouts"
                    depressed
                    plain
                >
                    {{ showCustomerWorkouts ? 'Eigene Pläne' : 'Kundenpläne' }}
                </v-btn>
            </v-card-title>

            <div v-show="generating_print" class="generating-print">
                <img src="\img\icons\loading.gif" />
                Printversion wird generiert...
            </div>

            <v-row
                v-if="!showCustomerWorkouts && $route.name === 'Workouts'"
                class="workouts__card-container mx-0"
                style="padding-bottom: 100px"
                :class="{ 'workouts__card-container-mobile': $vuetify.breakpoint.mobile }"
            >
                <v-col cols="6" md="6" lg="4" xl="4" class="px-1">
                    <v-card
                        hover
                        @click="$router.push('/workouts/create')"
                        class="workouts__card rounded-lg"
                        img="/img/einmalig/workoutCreatePlaceholder.png"
                    >
                        <v-responsive :aspect-ratio="1 / 1">
                            <div class="workouts__card-create-text d-flex white--text rounded-lg">
                                <v-card-title class="h5 bold pb-0 text-break">Plan Erstellen</v-card-title>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>

                <v-col class="px-1" v-for="(plan, key) in trainingsplaene_new" :key="key" cols="6" md="6" lg="4" xl="4">
                    <v-skeleton-loader
                        v-if="loading"
                        max-height="172"
                        max-width="172"
                        class="mx-auto"
                        type="image"
                    ></v-skeleton-loader>
                    <div v-else>
                        <v-btn
                            color="primary"
                            @click="plan.fab = !plan.fab"
                            x-small
                            fab
                            absolute
                            style="right: 15px; top: 15px"
                        >
                            <v-icon color="white">mdi-clipboard-edit-outline</v-icon>
                        </v-btn>
                        <v-card
                            hover
                            class="workouts__card rounded-lg"
                            :img="plan.form.picture ? '/img/uebungen/' + plan.form.picture : getImageIfNotSet()"
                        >
                            <v-responsive @click="startWorkout(plan)" :aspect-ratio="1 / 1">
                                <div class="workouts__card-action-buttons--container" v-if="plan.fab">
                                    <v-row class="ma-0">
                                        <v-col>
                                            <v-btn fab small color="blue" @click="editWorkout(plan)">
                                                <v-icon color="white">mdi-pencil</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn fab small color="orange" @click="copyPlan(plan)">
                                                <v-icon color="white">mdi-content-copy</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <v-col>
                                            <v-btn
                                                class="workouts__card-fab-lottie"
                                                fab
                                                small
                                                @click="deleteWorkout(plan)"
                                                color="red"
                                            >
                                                <v-icon color="white">mdi-delete</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col>
                                            <v-btn
                                                :loading="printing"
                                                fab
                                                small
                                                color="green"
                                                @click="downloadPDF(plan)"
                                            >
                                                <v-icon color="white">mdi-printer-settings</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>

                                <div
                                    class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg"
                                    v-if="!plan.fab"
                                >
                                    <v-card-title class="h5 pb-0 text-break">{{ plan.form.title }}</v-card-title>
                                    <v-card-text>{{ formatDate(plan.form.ts) }}</v-card-text>
                                </div>
                            </v-responsive>
                        </v-card>
                    </div>
                </v-col>

                <v-col
                    class="px-1"
                    v-for="(plan, key) in trainingsplaene_old"
                    :key="'trainingsplaene_old' + key"
                    cols="6"
                    md="6"
                    lg="4"
                    xl="4"
                >
                    <v-card hover v-if="key <= 8" class="workouts__card rounded-lg" :img="getImageIfNotSet()">
                        <v-responsive :aspect-ratio="1 / 1">
                            <v-btn
                                fab
                                class="trainingsplaeneold-fab"
                                style="z-index: 10000"
                                x-small
                                color="green"
                                @click.stop="generatePrintNew(plan._id)"
                            >
                                <v-icon color="white">mdi-printer-settings</v-icon>
                            </v-btn>
                            <v-btn
                                class="trainingsplaeneold-fab"
                                style="right: 45px; z-index: 10000"
                                fab
                                x-small
                                @click.prevent="deletePlan(plan)"
                                color="red"
                            >
                                <v-icon color="white">mdi-delete</v-icon>
                            </v-btn>

                            <div
                                class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg"
                                @click="startPlan(plan)"
                                v-if="!plan.fab"
                            >
                                <v-card-title class="h5 pb-0 text-break">{{ plan.tp.title }}</v-card-title>
                                <v-card-text>{{ formatDate(plan.tp.ts) }}</v-card-text>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>

                <v-col
                    class="px-1"
                    v-for="(plan, key) in trainingsplaene_old_old"
                    :key="'trainingsplaene_old_old' + key"
                    cols="6"
                    md="6"
                    lg="4"
                    xl="4"
                >
                    <v-card hover v-if="key <= 8" class="workouts__card rounded-lg" :img="getImageIfNotSet()">
                        <v-responsive :aspect-ratio="1 / 1">
                            <v-btn
                                fab
                                class="trainingsplaeneold-fab"
                                style="z-index: 10000"
                                x-small
                                color="green"
                                @click.prevent="generatePrint(plan._id)"
                            >
                                <v-icon color="white">mdi-printer-settings</v-icon>
                            </v-btn>
                            <v-btn
                                class="trainingsplaeneold-fab"
                                style="right: 45px; z-index: 10000"
                                fab
                                x-small
                                @click="deletePlan(plan)"
                                color="red"
                            >
                                <v-icon color="white">mdi-delete</v-icon>
                            </v-btn>
                            <div
                                class="workouts__card-text d-flex flex-column justify-space-between white--text rounded-lg"
                            >
                                <v-card-title class="h5 pb-0 text-break">{{ plan.name }}</v-card-title>
                                <v-card-text>{{ formatDate(plan.datum) }}</v-card-text>
                            </div>
                        </v-responsive>
                    </v-card>
                </v-col>
            </v-row>
            <!---<v-row
				v-if="showCustomerWorkouts && $route.name === 'Workouts'"
				class="mx-0"
				:class="{ 'workouts__card-container-mobile': $vuetify.breakpoint.mobile }"
			>
				<v-col style="height:120px" class="px-1 mb-2" v-for="(plan, key) in customerWorkouts" :key="key" cols="12">
					<v-btn color="primary" @click="plan.fab = !plan.fab" x-small fab absolute style="right: 15px; top: 15px;"
						><v-icon color="white">mdi-clipboard-edit-outline</v-icon></v-btn
					>
					<div class="workouts__card-action-buttons--container" v-if="plan.fab">
						q
										height="30"
										width="30"
										class="workouts__card-fab-lottie"
										name="copy"
										  src="/img/lottiefiles/copyWhite.json"
									></lottie-player>
								</v-btn>
							</v-col>
						</v-row>
						<v-row class="ma-0">
							<v-col>
								<v-btn fab small @click="deleteWorkout(plan)" color="red">
									<lottie-player
							mode="normal" 
										height="35"
										width="35"
										class="workouts__card-fab-lottie"
										name="delete"
										  src="/img/lottiefiles/binWhite.json"
									></lottie-player>
								</v-btn>
							</v-col>
							<v-col>
								<v-btn fab small color="green" @click="downloadPDF(plan)">
									<lottie-player
							mode="normal" 
										height="35"
										width="35"
										class="workouts__card-fab-lottie"
										name="print"
										  src="/img/lottiefiles/printWhite.json"
									></lottie-player>
								</v-btn>
							</v-col>
						</v-row>
					</div>
					<v-card @click="$router.push('/plan/run/' + plan._id)" class="rounded-lg d-flex flex-row">
						<div>
							<v-img
								class="rounded-lg"
								height="120px"
								width="120px"
								:src="plan.form.picture ? '/img/uebungen/' + plan.form.picture : getImageIfNotSet()"
							>
							</v-img>
						</div>
						<div class="d-flex flex-column justify-space-between" v-if="!plan.fab">
							<v-card-title class="h5 pb-0 text-break">{{ plan.form.title }}</v-card-title>
							<v-card-text>{{ formatDate(plan.form.ts) }}</v-card-text>
						</div>
					</v-card>
				</v-col>
			</v-row> ---->
            <v-row
                v-if="showCustomerWorkouts && $route.name === 'Workouts'"
                class="mx-0 workouts__search"
                :class="{ 'workouts__card-container-mobile': $vuetify.breakpoint.mobile }"
            >
                <v-card width="100%" color="grey darken-3" dark>
                    <v-card-title class="text-h5 grey darken-4">Suche</v-card-title>
                    <v-card-text class="mt-2">
                        Suche nach einem Kunden (Name / E-Mail) oder direkt nach dem Titel des Trainingsplans
                    </v-card-text>
                    <v-card-text>
                        <v-autocomplete
                            v-model="model"
                            :items="items"
                            :loading="isLoading"
                            :search-input.sync="search"
                            clearable
                            color="white"
                            hide-no-data
                            hide-selected
                            item-text="searchText"
                            item-value="_id"
                            label="Kundenpläne"
                            placeholder="Tippe um zu suchen"
                            prepend-icon="mdi-file-document-multiple"
                            return-object
                        ></v-autocomplete>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-expand-transition>
                        <v-list v-if="model" class="grey darken-4">
                            <v-list-item v-for="(field, i) in fields" :key="i">
                                <v-list-item-content>
                                    <v-list-item-title v-text="field.value"></v-list-item-title>
                                    <v-list-item-subtitle v-text="field.key"></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-card-actions>
                                <v-row class="ma-0">
                                    <v-col v-if="model.form">
                                        <v-btn fab small color="blue" @click="editWorkout(model)">
                                            <v-icon color="white">mdi-pencil</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col v-if="model.form">
                                        <v-btn fab small color="orange" @click="copyPlan(model)">
                                            <v-icon color="white">mdi-content-copy</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0">
                                    <v-col v-if="model.form">
                                        <v-btn fab small @click="deleteWorkout(model)" color="red">
                                            <v-icon color="white">mdi-delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col>
                                        <v-btn
                                            :loading="printing || generating_print"
                                            fab
                                            small
                                            color="green"
                                            @click="model.form ? downloadPDF(model) : generatePrintNew(model._id)"
                                        >
                                            <v-icon color="white">mdi-printer-settings</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-list>
                    </v-expand-transition>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="!model" color="red" @click="model = null">
                            Suche zurücksetzen
                            <v-icon right>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-row>
        </div>
        <router-view @reload-workouts="getTrainingPlans"></router-view>
    </div>
</template>

<script>
import { jsPDF } from 'jspdf'
import api from '../../api'
import userService from '../../services/userService'
import abortService from '../../services/abortService'
import $ from 'jquery'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    data() {
        return {
            gfRecommendations: [],
            loadingGFRecommendations: true,
            _timerId: null,
            printing: false,
            abortController: null,
            userService,
            trainingsplaene_new: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
            trainingsplaene_old: [],
            trainingsplaene_old_old: [],
            generating_print: false,
            ben_id: '',
            trainingsplaene: [],
            x: null,
            rollen: {},
            zeitplan_id: '',
            studio_id: '',
            customerWorkouts: [],
            showCustomerWorkouts: false,
            generatingprint: false,
            studio_id_workout: '',
            editMode: 0,
            chooseModus: 0,
            users: [],
            users_filtered: [],
            kundensuche: '',
            assign_ben_id: 1,
            assign_plan: null,
            loading: true,
            exampleImages: [
                '/img/einmalig/workoutExample.png',
                '/img/einmalig/workoutExample1.png',
                '/img/einmalig/workoutExample2.png',
            ],
            descriptionLimit: 60,
            entries: [],
            isLoading: false,
            model: null,
            search: null,
        }
    },
    computed: {
        ...mapState('navigationStoreNotPersisted', ['navigationVisible']),
        ...mapState('workingoutStore', ['finishedSteps']),
        ...mapGetters('deviceStore', ['exerciseList', 'fetchDeviceList']),
        fields() {
            if (!this.model) return []
            let fields = []
            if (this.model.form) {
                if (this.model.form.title) {
                    fields.push({ key: 'Titel', value: this.model.form.title })
                }
                if (this.model.form.user_id) {
                    if (
                        this.model.form.user_id.vorname &&
                        this.model.form.user_id.nachname &&
                        this.model.form.user_id.email
                    ) {
                        fields.push({
                            key: 'Kunde',
                            value:
                                this.model.form.user_id.vorname +
                                ' ' +
                                this.model.form.user_id.nachname +
                                ' (' +
                                this.model.form.user_id.email +
                                ')',
                        })
                    }
                }
                if (this.model.form.instructor_id) {
                    if (
                        this.model.form.instructor_id.vorname &&
                        this.model.form.instructor_id.nachname &&
                        this.model.form.instructor_id.email
                    ) {
                        fields.push({
                            key: 'Trainer',
                            value:
                                this.model.form.instructor_id.vorname +
                                ' ' +
                                this.model.form.instructor_id.nachname +
                                ' (' +
                                this.model.form.instructor_id.email +
                                ')',
                        })
                    }
                }
                if (this.model.exercises) {
                    let exercisesText = ''
                    for (let exercise of this.model.exercises) {
                        if (exercise.exercise_id && exercise.exercise_id.title) {
                            exercisesText += exercise.exercise_id.title + ', '
                        }
                    }
                    if (exercisesText) {
                        fields.push({
                            key: 'Enthaltene Übungen',
                            value: exercisesText.length > 60 ? exercisesText.slice(0, 60) : exercisesText,
                        })
                    }
                }
                if (this.model.exercises) {
                    fields.push({
                        key: 'Anzahl Übungen',
                        value: this.model.exercises.length,
                    })
                }
                if (this.model.createdAt) {
                    fields.push({
                        key: 'Erstellt am',
                        value: this.$moment(this.model.createdAt).format('DD.MM.YY HH:mm') + ' Uhr',
                    })
                }
                if (this.model.updatedAt) {
                    fields.push({
                        key: 'Zuletzt bearbeitet am',
                        value: this.$moment(this.model.updatedAt).format('DD.MM.YY HH:mm') + ' Uhr',
                    })
                }
            }
            if (this.model.tp) {
                if (this.model.tp.title) {
                    fields.push({ key: 'Titel', value: this.model.tp.title })
                }
                if (this.model.tp.user_id) {
                    if (
                        this.model.tp.user_id.vorname &&
                        this.model.tp.user_id.nachname &&
                        this.model.tp.user_id.email
                    ) {
                        fields.push({
                            key: 'Kunde',
                            value:
                                this.model.tp.user_id.vorname +
                                ' ' +
                                this.model.tp.user_id.nachname +
                                ' (' +
                                this.model.tp.user_id.email +
                                ')',
                        })
                    }
                }
                if (this.model.tp.instructor_id) {
                    if (
                        this.model.tp.instructor_id.vorname &&
                        this.model.tp.instructor_id.nachname &&
                        this.model.tp.instructor_id.email
                    ) {
                        fields.push({
                            key: 'Trainer',
                            value:
                                this.model.tp.instructor_id.vorname +
                                ' ' +
                                this.model.tp.instructor_id.nachname +
                                ' (' +
                                this.model.tp.instructor_id.email +
                                ')',
                        })
                    }
                }
                if (this.model.tp_exercises) {
                    let exercisesText = ''
                    for (let exercise of this.model.tp_exercises) {
                        if (exercise.exercise_id && exercise.exercise_id.title) {
                            exercisesText += exercise.exercise_id.title + ', '
                        }
                    }
                    if (exercisesText) {
                        fields.push({
                            key: 'Enthaltene Übungen',
                            value: exercisesText.length > 60 ? exercisesText.slice(0, 60) : exercisesText,
                        })
                    }
                }
                if (this.model.tp_exercises) {
                    fields.push({
                        key: 'Anzahl Übungen',
                        value: this.model.tp_exercises.length,
                    })
                }
            }
            return fields
        },
        items() {
            return this.entries.map((entry) => {
                return Object.assign({}, entry)
            })
        },
    },
    watch: {
        search(val) {
            if (this.isLoading) return
            /*
			console.log(val)
			// Items have already been loaded
			if (this.items.length > 0) return

			// Items have already been requested
			

			this.isLoading = true
			api.fetchStudioWorkouts(this.abortController.signal).then((e) => {
				this.count = e.length
				this.entries = e
				this.isLoading = false
			})
			*/
            if (!val || val.length < 3) {
                return
            }

            this.fetchWorkoutsDebounced(val)
        },
    },
    mounted() {},
    methods: {
        ...mapActions('workingoutStore', [
            'setEditInStore',
            'resetFinishedStepsArray',
            'setWorkoutCompletedInfos',
            'setSavedNote',
        ]),
        ...mapActions('deviceStore', ['setDeviceList']),
        ...mapActions('navigationStoreNotPersisted', ['setNavigationBarText', 'toggleNavigationBarVisibility']),
        async fetchGFRecommendations() {
            this.loadingGFRecommendations = true
            try {
                let status = await api.fetchGFRecommendationsSelf()
                console.log(status)
                this.gfRecommendations = status.recommendations
            } catch (e) {
                console.log('Fehler beim laden')
            }

            this.loadingGFRecommendations = false
        },
        handleMessage(message) {
            if (message.topic === 'workouts' && message.type === 'newWorkout') this.getTrainingPlans()
        },
        fetchWorkoutsDebounced(val) {
            // cancel pending call

            clearTimeout(this._timerId)

            // delay new call 500ms
            this._timerId = setTimeout(() => {
                this.isLoading = true
                this.fetchWorkouts(val)
            }, 2000)
        },
        fetchWorkouts(val) {
            console.log(val)
            api.lookupWorkouts(val, this.abortController.signal).then((e) => {
                this.count = e.length
                this.entries = e
                this.isLoading = false
            })
        },
        async downloadPDF(plan) {
            this.printing = true
            let pdf = await api.fetchPlanPDF(plan)
            this.printing = false
        },
        getImageIfNotSet() {
            return '/img/einmalig/workoutExample.png'
        },
        startPlan(plan) {
            this.$router.push('/workouts/run/' + plan._id)
        },
        startWorkout(plan) {
            if (!plan.fab) {
                if (!this.finishedSteps.length || this.finishedSteps.find((step) => step.plan_id === plan._id)) {
                    this.$router.push('/plan/run/' + plan._id)
                } else {
                    if (
                        confirm(
                            'Du hast noch ein aktives Workout. Möchtest du trotzdem den Trainingsplan beginnen und das aktive Workout überschreiben?'
                        )
                    ) {
                        this.setSavedNote(null)
                        this.resetFinishedStepsArray()
                        this.$router.push('/plan/run/' + plan._id)
                    }
                }
                this.workoutStepsHistory
            }
        },
        editWorkout(plan) {
            for (let index in plan.exercises) {
                if (plan.exercises[index].exercise_id && plan.exercises[index].exercise_id._id)
                    plan.exercises[index].exercise_id = plan.exercises[index].exercise_id._id
                let origExercise = this.exerciseList.find((ex) => ex._id === plan.exercises[index].exercise_id)
                if (origExercise) {
                    if (origExercise.title) plan.exercises[index].title = origExercise.title
                    if (origExercise.picture) plan.exercises[index].picture = origExercise.picture
                    if (origExercise.manual) plan.exercises[index].manual = origExercise.manual
                    if (origExercise.set_exercise_settings)
                        plan.exercises[index].set_exercise_settings = origExercise.set_exercise_settings
                    for (let setIndex in plan.exercises[index].sets) {
                        plan.exercises[index].sets[setIndex].active = false
                        plan.exercises[index].sets[setIndex].activeBefore = false
                        origExercise.set_exercise_settings.forEach((exerciseSetting) => {
                            if (
                                !plan.exercises[index].sets[setIndex].settings.find(
                                    (setting) => setting._id === exerciseSetting._id
                                )
                            )
                                plan.exercises[index].sets[setIndex].settings.push(exerciseSetting)
                        })
                    }
                } else {
                    console.error('origExercise not found', origExercise)
                }
            }

            this.setEditInStore(JSON.parse(JSON.stringify(plan)))
            this.$router.push('/workouts/edit/' + plan._id)
        },
        filterKunden() {
            /*this.users_filtered = this.users.filter((user) => {
				if (user.nachname !== null && user.vorname !== null && user.email !== null) {
					return (
						user.nachname.toLowerCase().indexOf(this.kundensuche.toLowerCase()) > -1 ||
						user.vorname.toLowerCase().indexOf(this.kundensuche.toLowerCase()) > -1 ||
						(user.vorname + ' ' + user.nachname).toLowerCase().indexOf(this.kundensuche.toLowerCase()) > -1 ||
						user.email.toLowerCase().indexOf(this.kundensuche.toLowerCase()) > -1
					)
				}
			})*/
        },
        copyPlan(plan) {
            api.copyTrainingPlan(plan._id).then((data) => {
                this.getTrainingPlans()
                if (this.showCustomerWorkouts) this.loadKundenplaene()
                alert('Plan dupliziert')
                console.log(data)
                this.editWorkout(data)
            })
        },
        deletePlan(plan) {
            let title = plan.name ? plan.name : plan.tp.title
            if (confirm('Wollen Sie den Trainingsplan ' + title + ' wirklich löschen?')) {
                api.deleteWorkout({ plan_id: plan._id })
                    .then((data) => {
                        this.getTrainingPlans()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        deleteWorkout(workout) {
            if (confirm('Wollen Sie den Trainingsplan ' + workout.form.title + ' wirklich löschen?')) {
                api.deleteWorkoutById({ workout_id: workout._id })
                    .then((data) => {
                        this.getTrainingPlans()
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
        toggleCustomerWorkouts() {
            if (!this.customerWorkouts.length) {
                //this.loadKundenplaene()
            }
            this.showCustomerWorkouts = !this.showCustomerWorkouts
        },
        loadKundenplaene() {
            api.loadTrainerWorkouts(this.abortController.signal)
                .then((data) => {
                    data.forEach((plan) => {
                        plan.fab = false
                    })
                    this.customerWorkouts = data
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getTrainingPlans() {
            this.loading = true
            api.getTrainingPlans(this.abortController.signal).then((data) => {
                data.forEach((plan) => {
                    plan.fab = false
                })
                this.trainingsplaene_new = data
                this.loading = false
            })
            api.getOldPlans(this.abortController.signal).then((data) => {
                this.trainingsplaene_old = data
            })

            api.loadWorkouts().then((data) => {
                this.trainingsplaene_old_old = data.trainingsplaene
            })
        },
        async getOrientationNew(file) {
            var reader = new FileReader()

            return new Promise((resolve, reject) => {
                reader.onload = function (e) {
                    var view = new DataView(e.target.result)
                    if (view.getUint16(0, false) != 0xffd8) {
                        resolve(-2)
                    }
                    var length = view.byteLength,
                        offset = 2
                    while (offset < length) {
                        if (view.getUint16(offset + 2, false) <= 8) resolve(-1)
                        var marker = view.getUint16(offset, false)
                        offset += 2
                        if (marker == 0xffe1) {
                            if (view.getUint32((offset += 2), false) != 0x45786966) {
                                resolve(-1)
                            }

                            var little = view.getUint16((offset += 6), false) == 0x4949
                            offset += view.getUint32(offset + 4, little)
                            var tags = view.getUint16(offset, little)
                            offset += 2
                            for (var i = 0; i < tags; i++) {
                                if (view.getUint16(offset + i * 12, little) == 0x0112) {
                                    resolve(view.getUint16(offset + i * 12 + 8, little))
                                }
                            }
                        } else if ((marker & 0xff00) != 0xff00) {
                            break
                        } else {
                            offset += view.getUint16(offset, false)
                        }
                    }
                    resolve(-1)
                }
                reader.readAsArrayBuffer(file)
            })
        },
        getOrientation(file, callback) {
            var reader = new FileReader()
            reader.onload = function (e) {
                var view = new DataView(e.target.result)
                if (view.getUint16(0, false) != 0xffd8) {
                    return callback(-2)
                }
                var length = view.byteLength,
                    offset = 2
                while (offset < length) {
                    if (view.getUint16(offset + 2, false) <= 8) return callback(-1)
                    var marker = view.getUint16(offset, false)
                    offset += 2
                    if (marker == 0xffe1) {
                        if (view.getUint32((offset += 2), false) != 0x45786966) {
                            return callback(-1)
                        }

                        var little = view.getUint16((offset += 6), false) == 0x4949
                        offset += view.getUint32(offset + 4, little)
                        var tags = view.getUint16(offset, little)
                        offset += 2
                        for (var i = 0; i < tags; i++) {
                            if (view.getUint16(offset + i * 12, little) == 0x0112) {
                                return callback(view.getUint16(offset + i * 12 + 8, little))
                            }
                        }
                    } else if ((marker & 0xff00) != 0xff00) {
                        break
                    } else {
                        offset += view.getUint16(offset, false)
                    }
                }
                return callback(-1)
            }
            reader.readAsArrayBuffer(file)
        },

        async newPage(doc, plan, page) {
            if (page !== 0) doc.addPage()
            doc.setFontSize(11)
            var img = new Image()
            img.src = '/img/print_' + plan.studio.logo
            doc.addImage(img, 'png', 5, 5, 70, 20)
            doc.text(plan.studio.name, 85, 10)
            doc.setFontSize(10)
            doc.text('Datum: ' + this.$moment.unix(plan.tp.ts).format('DD.MM.YYYY'), 85, 14)
            doc.text('Neuer Plan: ' + this.$moment.unix(plan.tp.due).format('DD.MM.YYYY'), 85, 18)
            doc.text(plan.user.vorname + ' ' + plan.user.nachname, 85, 22)
            doc.text('Datum: ', 145, 10)
            doc.setFontSize(13)
            let x_table = 160
            let y_table = 5
            doc.setDrawColor(150, 150, 150)
            for (let z = 0; z <= 1; z++) {
                for (let s = 0; s <= 11; s++) {
                    if (z == 1) {
                        doc.setFontSize(10)
                        doc.cell(x_table, y_table, 11, 7, 'kg')
                        doc.setFontSize(13)
                    } else {
                        doc.cell(x_table, y_table, 11, 7, ' ')
                    }

                    x_table += 11
                }
                x_table = 160
                y_table += 7
            }
            return doc
        },
        async addPicture(exercise_picture, doc, x, y) {
            let imageUrl = '/img/uebungen/' + exercise_picture
            let image_blob = await fetch(imageUrl)
            image_blob = await image_blob.blob()
            let file = new File([image_blob], 'image.jpg', image_blob)
            let orientation = await this.getOrientationNew(file)
            var img = new Image()
            img.src = imageUrl

            if (orientation == 6) {
                doc.addImage(img, 'jpg', x, y - 20, 20, 20, null, 'NONE', 270)
            } else if (orientation == 3) {
                doc.addImage(img, 'jpg', x + 20, y - 20, 20, 20, null, 'NONE', 180)
            } else {
                doc.addImage(img, 'jpg', x, y, 20, 20)
            }
            return doc
        },
        async generatePrintNew(plan_id) {
            this.generating_print = true

            let page = 0

            if (!this.generatingprint) {
                this.generatingprint = true
                let plan = await api.getWorkout(plan_id)

                let doc = new jsPDF({
                    orientation: 'landscape',
                    unit: 'mm',
                    format: [297, 210],
                })

                let x = 5
                let y = 28

                doc = await this.newPage(doc, plan, page)
                page++

                for (let tp_exercise of plan.tp_exercises) {
                    if (y > 190) {
                        doc = await this.newPage(doc, plan, page)
                        page++

                        y = 28
                    }
                    let exercise_pictures = []
                    exercise_pictures.push(tp_exercise.picture)
                    doc.setFontSize(10)
                    tp_exercise.guide = tp_exercise.guide.slice(0, 3)
                    tp_exercise.guide.forEach((g, i) => {
                        if (g.bild && !exercise_pictures.includes(g.bild)) exercise_pictures.push(g.bild)
                        doc.text(i + 1 + '. ' + g.beschreibung, 50, y + (i + 2) * 5)
                    })

                    exercise_pictures = exercise_pictures.slice(0, 2)
                    x = 5

                    for (let exercise_picture of exercise_pictures) {
                        doc = await this.addPicture(exercise_picture, doc, x, y)
                        x += 21
                    }
                    y += 1

                    x = 50

                    doc.setFontSize(9)

                    tp_exercise.tp_exercise_settings.forEach((tpes) => {
                        if (
                            !(
                                tpes.title === 'Sätze' ||
                                tpes.title === 'Gewicht' ||
                                tpes.title === 'Min Wiederholungen' ||
                                tpes.title === 'Max Wiederholungen'
                            )
                        ) {
                            let text =
                                tpes.title && tpes.value ? tpes.title.toString() + ': ' + tpes.value.toString() : ''
                            if (text) {
                                doc.text(text, x, y)
                                x += doc.getTextWidth(text) + 5
                            }
                        }
                    })

                    doc.setFontSize(13)
                    x = 50
                    y += 5

                    doc.text(tp_exercise.shortForm + ' | ' + tp_exercise.title, x, y)

                    let saetze = 1
                    let gewicht = null
                    let min_wiederholungen = null
                    let max_wiederholungen = null

                    tp_exercise.tp_exercise_settings.forEach((tpes) => {
                        if (tpes.title === 'Sätze') saetze = tpes.value
                        if (tpes.title === 'Gewicht') gewicht = tpes.value
                        if (tpes.title === 'Min Wiederholungen') min_wiederholungen = tpes.value
                        if (tpes.title === 'Max Wiederholungen') max_wiederholungen = tpes.value
                    })

                    for (let s = 0; s <= saetze - 1; s++) {
                        x = 147
                        doc.setFontSize(9)
                        if (min_wiederholungen && max_wiederholungen) {
                            doc.text(
                                'WH: ' + min_wiederholungen + '-' + max_wiederholungen + ' | ' + (s + 1) + '. Satz ',
                                x - 16,
                                y + 4
                            )
                        } else {
                            doc.text(s + 1 + '. Satz ', x, y + 4)
                        }
                        doc.setFontSize(13)
                        x = 160
                        for (let s = 0; s <= 11; s++) {
                            if (s == 0) {
                                doc.cell(x, y, 11, 5, ' ')
                                if (gewicht) {
                                    doc.setFontSize(10)
                                    doc.text(gewicht.toString(), x + 3, y + 5 / 2 + 1.2)
                                    doc.setFontSize(13)
                                }
                            } else {
                                doc.cell(x, y, 11, 5, ' ')
                            }

                            x += 11
                        }
                        y += 5

                        if (y > 190) {
                            doc = await this.newPage(doc, plan, page)
                            page++

                            y = 28
                        }
                    }

                    if (saetze == null || saetze == 0) y += 18
                    if (saetze == 1) y += 13
                    if (saetze == 2) y += 10
                    if (saetze == 3) y += 5
                }

                this.savePlan(plan, doc)
            }
        },
        savePlan(plan, doc) {
            var is_chrome = navigator.userAgent.indexOf('Chrome') > -1
            var is_explorer = navigator.userAgent.indexOf('MSIE') > -1
            var is_firefox = navigator.userAgent.indexOf('Firefox') > -1
            var is_safari = navigator.userAgent.indexOf('Safari') > -1
            var is_opera = navigator.userAgent.toLowerCase().indexOf('op') > -1
            if (is_chrome && is_safari) {
                is_safari = false
            }
            if (is_chrome && is_opera) {
                is_chrome = false
            }
            if (is_safari) {
                doc.save(plan.tp.title.toString().replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, '_'))
            } else {
                var blob = doc.output('blob')
                window.open(URL.createObjectURL(blob))
            }
            this.generatingprint = false
            this.generating_print = false
        },
        generatePrint(zeitplan_id_passed) {
            this.generating_print = true
            let page = 1
            if (!this.generatingprint) {
                this.generatingprint = true
                let exercise_array = []
                let bilder_temp = []
                let titel_temp = []
                let label_temp = []
                let steps_temp = []
                api.getPlanExerciseSettings(zeitplan_id_passed)
                    .then((planexercisesettings) => {
                        let plan_exercise_settings = []
                        for (let pxe of planexercisesettings) {
                            plan_exercise_settings[pxe.exercise_setting_id] = pxe.value
                        }

                        api.workingOut(zeitplan_id_passed)
                            .then((data) => {
                                api.getStudios()
                                    .then((studios) => {
                                        let studio_infos = {}
                                        let datum = ''
                                        let dauer = ''
                                        let newplandatum = ''
                                        let username = ''
                                        let exercise_settings = []
                                        let user = JSON.parse(localStorage.getItem('user')).user
                                        console.log(user)
                                        username = user.vorname + ' ' + user.nachname

                                        studio_infos._id = data.trainingsplaene.studio_id

                                        api.getAllExcersiceSettings(studio_infos._id)
                                            .then((ex_settings) => {
                                                for (let ex_s of ex_settings) {
                                                    if (!exercise_settings[ex_s.exercise_id]) {
                                                        exercise_settings[ex_s.exercise_id] = []
                                                    }

                                                    exercise_settings[ex_s.exercise_id].push(ex_s)
                                                }
                                                api.getUebungbyStudioId(studio_infos._id)
                                                    .then((uebungen_db) => {
                                                        for (let studio of studios) {
                                                            if (studio._id == studio_infos._id) {
                                                                studio_infos.bild = studio.logo

                                                                studio_infos.art = studio.art
                                                                studio_infos.name = studio.name
                                                            }
                                                        }

                                                        datum = new Date(
                                                            data.trainingsplaene.datum * 1000
                                                        ).toLocaleDateString()

                                                        newplandatum = new Date(
                                                            data.trainingsplaene.datum * 1000 + 7257600000
                                                        ).toLocaleDateString()

                                                        newplandatum =
                                                            newplandatum.split('.')[1] +
                                                            '.' +
                                                            newplandatum.split('.')[2]

                                                        studio_infos._id = data.trainingsplaene.studio_id

                                                        dauer = data.trainingsplaene.zeit

                                                        for (let ex of data.plan_exercises) {
                                                            if (ex.plan_exercise.new_plan_exercise_id == null) {
                                                                exercise_array.push(ex.plan_exercise)
                                                                this.anz_uebungen++

                                                                bilder_temp[ex.plan_exercise._id] = []
                                                                bilder_temp[ex.plan_exercise._id].push(ex.bild)

                                                                switch (ex.plan_exercise_steps.length) {
                                                                    case 0:
                                                                        break
                                                                    case 1:
                                                                        if (
                                                                            ex.plan_exercise_steps[0].bild !==
                                                                                ex.bild &&
                                                                            ex.plan_exercise_steps[0].bild !== ''
                                                                        ) {
                                                                            bilder_temp[ex.plan_exercise._id].push(
                                                                                ex.plan_exercise_steps[0].bild
                                                                            )
                                                                        }
                                                                        break
                                                                    case 2:
                                                                        if (
                                                                            ex.plan_exercise_steps[1].bild !==
                                                                                ex.bild &&
                                                                            ex.plan_exercise_steps[1].bild !== ''
                                                                        ) {
                                                                            bilder_temp[ex.plan_exercise._id].push(
                                                                                ex.plan_exercise_steps[1].bild
                                                                            )
                                                                        }
                                                                        break
                                                                    case 3:
                                                                        if (
                                                                            ex.plan_exercise_steps[2].bild !==
                                                                                ex.bild &&
                                                                            ex.plan_exercise_steps[2].bild !== ''
                                                                        ) {
                                                                            bilder_temp[ex.plan_exercise._id].push(
                                                                                ex.plan_exercise_steps[2].bild
                                                                            )
                                                                        } else if (
                                                                            ex.plan_exercise_steps[1].bild !==
                                                                                ex.bild &&
                                                                            ex.plan_exercise_steps[1].bild !== ''
                                                                        ) {
                                                                            bilder_temp[ex.plan_exercise._id].push(
                                                                                ex.plan_exercise_steps[1].bild
                                                                            )
                                                                        }
                                                                        break
                                                                }
                                                                titel_temp[ex.plan_exercise._id] = ex.titel
                                                                label_temp[ex.plan_exercise._id] =
                                                                    ex.interne_bezeichnung
                                                                steps_temp[ex.plan_exercise._id] =
                                                                    ex.plan_exercise_steps
                                                            }
                                                        }

                                                        let doc = new jsPDF({
                                                            orientation: 'landscape',
                                                            unit: 'mm',
                                                            format: [297, 210],
                                                        })
                                                        doc.setFontSize(11)

                                                        var img = new Image()

                                                        img.src = '/img/print_' + studio_infos.bild

                                                        doc.addImage(img, 'png', 5, 5, 70, 20)
                                                        doc.text(studio_infos.name, 85, 10)
                                                        doc.setFontSize(10)
                                                        doc.text('Datum: ' + datum, 85, 14)
                                                        doc.text('Neuer Plan: ' + newplandatum, 85, 18)

                                                        if (exercise_array[0].pause) {
                                                            let pause = exercise_array[0].pause.toString()
                                                            doc.text('Pause: ' + pause + 's', 85, 22)
                                                            doc.text(username, 85, 26)
                                                        } else {
                                                            doc.text(username, 85, 22)
                                                        }
                                                        doc.text('Datum: ', 145, 10)
                                                        doc.setFontSize(13)
                                                        let x_table = 160
                                                        let y_table = 5
                                                        doc.setDrawColor(150, 150, 150)
                                                        for (let z = 0; z <= 1; z++) {
                                                            for (let s = 0; s <= 11; s++) {
                                                                if (z == 1) {
                                                                    doc.setFontSize(10)
                                                                    doc.cell(x_table, y_table, 11, 7, 'kg')
                                                                    doc.setFontSize(13)
                                                                } else {
                                                                    doc.cell(x_table, y_table, 11, 7, ' ')
                                                                }

                                                                x_table += 11
                                                            }
                                                            x_table = 160
                                                            y_table += 7
                                                        }

                                                        //doc.addPage();
                                                        // console.log(steps_temp);
                                                        let x = 5
                                                        let y = 28
                                                        let counter_length = exercise_array.length
                                                        let counter_ex = 0
                                                        let promises = []
                                                        let promises_img = {}
                                                        for (let exercise of exercise_array) {
                                                            counter_ex++
                                                            x = 5
                                                            var img = new Image()

                                                            for (let bildx of bilder_temp[exercise._id]) {
                                                                if (bildx) {
                                                                    let ref = this

                                                                    let imageUrl = '/img/uebungen/' + bildx

                                                                    /* old not rotating but working way*/
                                                                    /*
                                      img.src = imageUrl;

                                      console.log(data);
                                      doc.addImage(img, "jpg", x, y, 20, 20);
                                      */

                                                                    promises_img[imageUrl] = {
                                                                        x_pos: x,
                                                                        y_pos: y,
                                                                        page,
                                                                    }

                                                                    promises.push(
                                                                        new Promise((resolve, reject) => {
                                                                            fetch(imageUrl)
                                                                                .then((res) => res.blob())
                                                                                .then((blob) => {
                                                                                    const file = new File(
                                                                                        [blob],
                                                                                        'image.jpg',
                                                                                        blob
                                                                                    )

                                                                                    ref.getOrientation(
                                                                                        file,
                                                                                        function (orientation) {
                                                                                            if (orientation == -2) {
                                                                                                reject('fehler')
                                                                                            } else {
                                                                                                resolve({
                                                                                                    imageUrl,
                                                                                                    ori: orientation,
                                                                                                })
                                                                                            }
                                                                                        }
                                                                                    )
                                                                                })
                                                                        }).catch((e) => {
                                                                            console.log(
                                                                                'Es gab einen Fehler beim laden des Bildes',
                                                                                e
                                                                            )
                                                                        })
                                                                    )

                                                                    x += 21
                                                                }
                                                            }

                                                            for (let ueb of uebungen_db) {
                                                                if (ueb.uebung._id == exercise.exercise_id) {
                                                                    x = 50
                                                                    if (ueb.geraet) {
                                                                        doc.text(
                                                                            ueb.geraet.label + ' | ' + ueb.uebung.titel,
                                                                            x,
                                                                            y + 5
                                                                        )
                                                                    } else {
                                                                        doc.text(ueb.uebung.titel, x, y + 5)
                                                                    }
                                                                    let x_tmp = 128
                                                                    doc.setFontSize(10)
                                                                    if (exercise.ausdauergeraet) {
                                                                        let text_ausdauer = ''
                                                                        if (exercise.a_dauer) {
                                                                            text_ausdauer +=
                                                                                'Dauer: ' +
                                                                                exercise.a_dauer.toString() +
                                                                                'min, '
                                                                        }

                                                                        if (exercise.a_geschwindigkeit) {
                                                                            text_ausdauer +=
                                                                                'Km/h: ' +
                                                                                exercise.a_geschwindigkeit.toString() +
                                                                                ', '
                                                                        }
                                                                        if (exercise.a_geschwindigkeit) {
                                                                            text_ausdauer +=
                                                                                'Km/h: ' +
                                                                                exercise.a_geschwindigkeit.toString() +
                                                                                ', '
                                                                        }
                                                                        if (exercise.a_hf) {
                                                                            text_ausdauer +=
                                                                                'HF: ' + exercise.a_hf.toString() + ', '
                                                                        }
                                                                        if (exercise.a_kalorien) {
                                                                            text_ausdauer +=
                                                                                'Kalorien: ' +
                                                                                exercise.a_kalorien.toString() +
                                                                                ', '
                                                                        }
                                                                        if (exercise.a_methode) {
                                                                            text_ausdauer +=
                                                                                'Methode: ' +
                                                                                exercise.a_methode.toString() +
                                                                                ', '
                                                                        }
                                                                        if (exercise.a_upm) {
                                                                            text_ausdauer +=
                                                                                'UPM: ' +
                                                                                exercise.a_upm.toString() +
                                                                                ', '
                                                                        }
                                                                        if (exercise.a_watt) {
                                                                            text_ausdauer +=
                                                                                'Watt: ' + exercise.a_watt.toString()
                                                                        }

                                                                        doc.text(text_ausdauer, x_tmp, y + 5)
                                                                        if (exercise.notiz) {
                                                                            let notes = exercise.notiz
                                                                            if (exercise.notiz.length > 80) {
                                                                                notes =
                                                                                    exercise.notiz.substring(0, 80) +
                                                                                    '...'
                                                                            }
                                                                            doc.text(
                                                                                notes.replace(/(\r\n|\n|\r)/gm, ' '),
                                                                                x_tmp,
                                                                                y + 10
                                                                            )
                                                                        }
                                                                    } else {
                                                                        if (label_temp[exercise._id] == 'Faszien') {
                                                                            let text = ''
                                                                            if (exercise.f_saetze) {
                                                                                text +=
                                                                                    'Sätze / Übung: ' +
                                                                                    exercise.f_saetze.toString() +
                                                                                    ', '
                                                                            }
                                                                            if (exercise.f_dauer) {
                                                                                text +=
                                                                                    'Dauer in Minuten: ' +
                                                                                    exercise.f_dauer.toString() +
                                                                                    ', '
                                                                            }
                                                                            if (exercise.f_notiz) {
                                                                                text +=
                                                                                    'Notizen: ' +
                                                                                    exercise.f_notiz.toString() +
                                                                                    ', '
                                                                            }

                                                                            doc.text(text, x_tmp, y + 5)
                                                                        } else if (ueb.uebung.titel == 'Seilspringen') {
                                                                            let text = ''
                                                                            if (exercise.f_dauer) {
                                                                                text +=
                                                                                    'Dauer: ' +
                                                                                    exercise.f_dauer.toString() +
                                                                                    ', '
                                                                            }

                                                                            if (
                                                                                exercise.f_notiz &&
                                                                                exercise.f_notiz !== 'Anmerkungen...'
                                                                            ) {
                                                                                text +=
                                                                                    'Notiz: ' +
                                                                                    exercise.f_notiz.toString() +
                                                                                    ''
                                                                            }

                                                                            doc.text(text, x_tmp, y + 5)
                                                                        } else if (
                                                                            label_temp[exercise._id] == 'Mobility' ||
                                                                            label_temp[exercise._id] == 'Xtension'
                                                                        ) {
                                                                            let text = ''

                                                                            if (
                                                                                exercise.m_dehnmethode &&
                                                                                label_temp[exercise._id] !== 'Xtension'
                                                                            ) {
                                                                                text +=
                                                                                    'Dehnmethode: ' +
                                                                                    exercise.m_dehnmethode.toString() +
                                                                                    ', '
                                                                            }

                                                                            if (exercise.m_dauer) {
                                                                                text +=
                                                                                    'Dauer: ' +
                                                                                    exercise.m_dauer.toString() +
                                                                                    ', '
                                                                            }
                                                                            /*
                                          if (exercise.m_saetze) {
                                            text +=
                                              "Anz. Sätze: " +
                                              exercise.m_saetze.toString() +
                                              ", ";
                                          }
                                          */
                                                                            if (exercise.m_pause) {
                                                                                text +=
                                                                                    'Pause: ' +
                                                                                    exercise.m_pause.toString() +
                                                                                    ', '
                                                                            }
                                                                            if (
                                                                                exercise.m_notiz &&
                                                                                exercise.m_notiz !== 'Anmerkungen...'
                                                                            ) {
                                                                                text +=
                                                                                    'Notiz: ' +
                                                                                    exercise.m_notiz.toString() +
                                                                                    ', '
                                                                            }

                                                                            doc.text(text, x_tmp, y + 5)
                                                                        } else if (
                                                                            label_temp[exercise._id] == 'Senso Pro'
                                                                        ) {
                                                                            let text = ''

                                                                            if (exercise.sp_programm) {
                                                                                text +=
                                                                                    'Programm: ' +
                                                                                    exercise.sp_programm.toString() +
                                                                                    ','
                                                                            }

                                                                            if (exercise.sp_thema) {
                                                                                text +=
                                                                                    'Thema: ' +
                                                                                    exercise.sp_thema.toString() +
                                                                                    ','
                                                                            }

                                                                            if (exercise.sp_level) {
                                                                                text +=
                                                                                    'Level: ' +
                                                                                    exercise.sp_level.toString() +
                                                                                    ','
                                                                            }

                                                                            if (exercise.sp_dauer) {
                                                                                text +=
                                                                                    'Dauer in Minuten: ' +
                                                                                    exercise.sp_dauer.toString() +
                                                                                    ','
                                                                            }

                                                                            if (exercise.sp_notiz) {
                                                                                text +=
                                                                                    'Notizen: ' +
                                                                                    exercise.sp_notiz.toString() +
                                                                                    ','
                                                                            }

                                                                            doc.text(text, x_tmp, y + 5)
                                                                        }
                                                                        /*

                          */
                                                                    }
                                                                    doc.setFontSize(13)
                                                                    let y_tmp = y
                                                                    let counter = 1
                                                                    y_tmp += 10
                                                                    doc.setFontSize(10)
                                                                    for (let step of steps_temp[exercise._id]) {
                                                                        if (step.beschreibung) {
                                                                            let step_beschreibung_short =
                                                                                step.beschreibung
                                                                            if (step_beschreibung_short.length > 25) {
                                                                                step_beschreibung_short =
                                                                                    step_beschreibung_short.substring(
                                                                                        0,
                                                                                        25
                                                                                    ) + '...'
                                                                            }
                                                                            doc.text(
                                                                                counter +
                                                                                    '. ' +
                                                                                    step_beschreibung_short,
                                                                                x,
                                                                                y_tmp
                                                                            )
                                                                            y_tmp += 5
                                                                            counter++
                                                                        }
                                                                    }

                                                                    let y_tmp_setting = y
                                                                    y_tmp_setting += 10

                                                                    let x_setting = x + 49

                                                                    let settingBlacklist = [
                                                                        'Max WH Dauer',
                                                                        'Max Wiederholungen',
                                                                        'Min WH Dauer',
                                                                        'Min Wiederholungen',
                                                                        'Notiz',
                                                                        'Pause',
                                                                        'Sätze',
                                                                        'Gewicht',
                                                                        'Dauer',
                                                                        'Programm',
                                                                        'Thema',
                                                                        'Level',
                                                                        '',
                                                                    ]

                                                                    if (exercise_settings[exercise.exercise_id]) {
                                                                        for (let setting of exercise_settings[
                                                                            exercise.exercise_id
                                                                        ]) {
                                                                            if (
                                                                                setting.name &&
                                                                                !settingBlacklist.includes(setting.name)
                                                                            ) {
                                                                                let setting_name = setting.name
                                                                                if (!setting_name.includes(':')) {
                                                                                    setting_name = setting_name + ':'
                                                                                }
                                                                                if (
                                                                                    plan_exercise_settings[
                                                                                        setting._id
                                                                                    ] &&
                                                                                    plan_exercise_settings[
                                                                                        setting._id
                                                                                    ] !== 'Auswahl'
                                                                                ) {
                                                                                    setting_name +=
                                                                                        ' ' +
                                                                                        plan_exercise_settings[
                                                                                            setting._id
                                                                                        ]
                                                                                }
                                                                                doc.text(
                                                                                    setting_name,
                                                                                    x_setting,
                                                                                    y_tmp_setting
                                                                                )
                                                                                y_tmp_setting += 5
                                                                                counter++
                                                                            }

                                                                            doc.setFontSize(10)
                                                                        }
                                                                    }

                                                                    y_tmp += 2

                                                                    let y_tmp_2 = y + 10

                                                                    if (
                                                                        !exercise.ausdauergeraet &&
                                                                        !(
                                                                            label_temp[exercise._id] == 'Faszien' ||
                                                                            label_temp[exercise._id] == 'Senso Pro'
                                                                        )
                                                                    ) {
                                                                        if (exercise.min_wiederholungen_dauer) {
                                                                            let text = exercise.min_wiederholungen_dauer
                                                                            if (
                                                                                exercise.min_wiederholungen_dauer > 10
                                                                            ) {
                                                                                text =
                                                                                    exercise.min_wiederholungen_dauer.substring(
                                                                                        0,
                                                                                        10
                                                                                    ) + '...'
                                                                            }
                                                                            doc.text(
                                                                                'WH mind. ' +
                                                                                    text.replace(/(\r\n|\n|\r)/gm, ' '),
                                                                                128,
                                                                                y + 4
                                                                            )
                                                                        }
                                                                        if (exercise.max_wiederholungen_dauer) {
                                                                            let text = exercise.max_wiederholungen_dauer
                                                                            if (
                                                                                exercise.max_wiederholungen_dauer > 10
                                                                            ) {
                                                                                text =
                                                                                    exercise.max_wiederholungen_dauer.substring(
                                                                                        0,
                                                                                        10
                                                                                    ) + '...'
                                                                            }
                                                                            doc.text(
                                                                                'WH max. ' +
                                                                                    text.replace(/(\r\n|\n|\r)/gm, ' '),
                                                                                160,
                                                                                y + 4
                                                                            )
                                                                        }
                                                                        if (exercise.notiz) {
                                                                            let notes = exercise.notiz
                                                                            let y_temp_notes = 128
                                                                            let snip = 80
                                                                            if (exercise.min_wiederholungen_dauer) {
                                                                                y_temp_notes += 32
                                                                                snip = 65
                                                                            }
                                                                            if (exercise.max_wiederholungen_dauer) {
                                                                                y_temp_notes += 32
                                                                                snip = 55
                                                                            }

                                                                            if (exercise.notiz.length > snip) {
                                                                                notes =
                                                                                    exercise.notiz.substring(0, snip) +
                                                                                    '...'
                                                                            }

                                                                            doc.text(
                                                                                notes.replace(/(\r\n|\n|\r)/gm, ' '),
                                                                                y_temp_notes,
                                                                                y + 4
                                                                            )
                                                                        }
                                                                        x = 128
                                                                        doc.setFontSize(10)
                                                                        let saetzecounter = parseInt(exercise.saetze)
                                                                        if (
                                                                            saetzecounter < 1 ||
                                                                            exercise.saetze == null
                                                                        ) {
                                                                            if (
                                                                                exercise.m_saetze !== null &&
                                                                                exercise.m_saetze > 1
                                                                            ) {
                                                                                saetzecounter = exercise.m_saetze
                                                                            } else {
                                                                                saetzecounter = 1
                                                                            }
                                                                        }
                                                                        for (let s = 1; s <= saetzecounter; s++) {
                                                                            if (
                                                                                label_temp[exercise._id] !==
                                                                                    'Mobility' &&
                                                                                ueb.uebung.titel !== 'Seilspringen'
                                                                            ) {
                                                                                if (
                                                                                    label_temp[exercise._id] ==
                                                                                        'Power Plate' ||
                                                                                    label_temp[exercise._id] ==
                                                                                        'Xtension'
                                                                                ) {
                                                                                    doc.text(
                                                                                        s + '. Satz ',
                                                                                        x + 15,
                                                                                        y_tmp_2
                                                                                    )
                                                                                } else {
                                                                                    if (
                                                                                        exercise.min_wiederholungen &&
                                                                                        exercise.max_wiederholungen
                                                                                    ) {
                                                                                        doc.text(
                                                                                            'WH: ' +
                                                                                                exercise.min_wiederholungen +
                                                                                                '-' +
                                                                                                exercise.max_wiederholungen +
                                                                                                ' | ' +
                                                                                                s +
                                                                                                '. Satz ',
                                                                                            x,
                                                                                            y_tmp_2
                                                                                        )
                                                                                    } else {
                                                                                        doc.text(
                                                                                            'WH: ___ | ' +
                                                                                                s +
                                                                                                '. Satz ',
                                                                                            x,
                                                                                            y_tmp_2
                                                                                        )
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                if (
                                                                                    !ueb.uebung.titel.includes(
                                                                                        'Senso'
                                                                                    ) &&
                                                                                    !ueb.uebung.titel.includes(
                                                                                        'Seilspringen'
                                                                                    ) &&
                                                                                    label_temp[exercise._id] !==
                                                                                        'Mobility'
                                                                                ) {
                                                                                    doc.text(
                                                                                        'WH: ___ | ' + s + '. Satz ',
                                                                                        x,
                                                                                        y_tmp_2
                                                                                    )
                                                                                }
                                                                            }

                                                                            x_table = 160
                                                                            y_table = y_tmp_2 - 4
                                                                            for (let s = 0; s <= 11; s++) {
                                                                                if (s == 0) {
                                                                                    doc.cell(
                                                                                        x_table,
                                                                                        y_table,
                                                                                        11,
                                                                                        5,
                                                                                        ' '
                                                                                    )
                                                                                    if (
                                                                                        exercise.gewicht &&
                                                                                        !ueb.uebung.titel.includes(
                                                                                            'Senso'
                                                                                        ) &&
                                                                                        !ueb.uebung.titel.includes(
                                                                                            'Seilspringen'
                                                                                        ) &&
                                                                                        label_temp[exercise._id] !==
                                                                                            'Mobility' &&
                                                                                        label_temp[exercise._id] !==
                                                                                            'Xtension' &&
                                                                                        label_temp[exercise._id] !==
                                                                                            'Power Plate'
                                                                                    ) {
                                                                                        doc.text(
                                                                                            exercise.gewicht.toString(),
                                                                                            x_table + 3,
                                                                                            y_table + 5 / 2 + 1.2
                                                                                        )
                                                                                    }
                                                                                } else {
                                                                                    doc.cell(
                                                                                        x_table,
                                                                                        y_table,
                                                                                        11,
                                                                                        5,
                                                                                        ' '
                                                                                    )
                                                                                }

                                                                                x_table += 11
                                                                            }
                                                                            y_tmp_2 += 5
                                                                        }
                                                                    } else {
                                                                        if (
                                                                            !(
                                                                                label_temp[exercise._id] == 'Faszien' ||
                                                                                label_temp[exercise._id] ==
                                                                                    'Mobility' ||
                                                                                label_temp[exercise._id] == 'Senso Pro'
                                                                            )
                                                                        ) {
                                                                            x_table = 160
                                                                            y_table = y + 12
                                                                            for (let s = 0; s <= 11; s++) {
                                                                                if (s == 0) {
                                                                                    doc.cell(
                                                                                        x_table,
                                                                                        y_table,
                                                                                        11,
                                                                                        5,
                                                                                        ' '
                                                                                    )
                                                                                } else {
                                                                                    doc.cell(
                                                                                        x_table,
                                                                                        y_table,
                                                                                        11,
                                                                                        5,
                                                                                        ' '
                                                                                    )
                                                                                }

                                                                                x_table += 11
                                                                            }
                                                                        }
                                                                    }

                                                                    if (
                                                                        Math.max(y_tmp_setting, y_tmp_2, y_tmp) - y <
                                                                        27
                                                                    ) {
                                                                        let additional =
                                                                            27 -
                                                                            (Math.max(y_tmp_setting, y_tmp_2, y_tmp) -
                                                                                y)

                                                                        y =
                                                                            Math.max(y_tmp_setting, y_tmp_2, y_tmp) +
                                                                            additional
                                                                    } else {
                                                                        y = Math.max(y_tmp_setting, y_tmp_2, y_tmp) + 2
                                                                    }
                                                                }
                                                            }
                                                            doc.setFontSize(13)
                                                            x = 10
                                                            y -= 5

                                                            if (y > 190 && counter_length !== counter_ex) {
                                                                x = 10
                                                                y = 25
                                                                doc.addPage()
                                                                page++
                                                                doc.setFontSize(11)

                                                                var img = new Image()

                                                                img.src = '/img/print_' + studio_infos.bild
                                                                doc.addImage(img, 'png', 5, 5, 70, 20)
                                                                doc.text(studio_infos.name, 85, 10)
                                                                doc.setFontSize(10)
                                                                doc.text('Datum: ' + datum, 85, 14)
                                                                doc.text('Neuer Plan: ' + newplandatum, 85, 18)

                                                                if (exercise_array[0].pause) {
                                                                    let pause = exercise_array[0].pause.toString()
                                                                    doc.text('Pause: ' + pause + 's', 85, 22)
                                                                    doc.text(username, 85, 26)
                                                                } else {
                                                                    doc.text(username, 85, 22)
                                                                }

                                                                doc.text('Datum: ', 145, 10)
                                                                doc.setFontSize(13)
                                                                let x_table = 160
                                                                let y_table = 5
                                                                doc.setDrawColor(150, 150, 150)
                                                                for (let z = 0; z <= 1; z++) {
                                                                    for (let s = 0; s <= 11; s++) {
                                                                        if (z == 1) {
                                                                            doc.setFontSize(10)
                                                                            doc.cell(x_table, y_table, 11, 7, 'kg')
                                                                            doc.setFontSize(13)
                                                                        } else {
                                                                            doc.cell(x_table, y_table, 11, 7, ' ')
                                                                        }

                                                                        x_table += 11
                                                                    }
                                                                    x_table = 160
                                                                    y_table += 7
                                                                }
                                                            }
                                                        }
                                                        Promise.allSettled(promises).then((values) => {
                                                            let image = new Image()
                                                            for (let val of values) {
                                                                if (val.value) {
                                                                    image.src = val.value.imageUrl

                                                                    doc.setPage(promises_img[val.value.imageUrl].page)

                                                                    if (val.value.ori == 6) {
                                                                        doc.addImage(
                                                                            image,
                                                                            'jpg',
                                                                            promises_img[val.value.imageUrl].x_pos,
                                                                            promises_img[val.value.imageUrl].y_pos - 20,
                                                                            20,
                                                                            20,
                                                                            null,
                                                                            'NONE',
                                                                            270
                                                                        )
                                                                    } else if (val.value.ori == 3) {
                                                                        doc.addImage(
                                                                            image,
                                                                            'jpg',
                                                                            promises_img[val.value.imageUrl].x_pos + 20,
                                                                            promises_img[val.value.imageUrl].y_pos - 20,
                                                                            20,
                                                                            20,
                                                                            null,
                                                                            'NONE',
                                                                            180
                                                                        )
                                                                    } else {
                                                                        doc.addImage(
                                                                            image,
                                                                            'jpg',
                                                                            promises_img[val.value.imageUrl].x_pos,
                                                                            promises_img[val.value.imageUrl].y_pos,
                                                                            20,
                                                                            20
                                                                        )
                                                                    }
                                                                }
                                                            }
                                                            var is_chrome = navigator.userAgent.indexOf('Chrome') > -1
                                                            var is_explorer = navigator.userAgent.indexOf('MSIE') > -1
                                                            var is_firefox = navigator.userAgent.indexOf('Firefox') > -1
                                                            var is_safari = navigator.userAgent.indexOf('Safari') > -1
                                                            var is_opera =
                                                                navigator.userAgent.toLowerCase().indexOf('op') > -1
                                                            if (is_chrome && is_safari) {
                                                                is_safari = false
                                                            }
                                                            if (is_chrome && is_opera) {
                                                                is_chrome = false
                                                            }
                                                            if (is_safari) {
                                                                doc.save(
                                                                    data.trainingsplaene.name
                                                                        .toString()
                                                                        .replace(/[ &\/\\#,+()$~%.'":*?<>{}]/g, '_')
                                                                )
                                                            } else {
                                                                var blob = doc.output('blob')
                                                                window.open(URL.createObjectURL(blob))
                                                            }
                                                            this.generatingprint = false
                                                            this.generating_print = false
                                                        })
                                                    })
                                                    .catch((error) => {
                                                        alert('fehler')
                                                        this.generatingprint = false
                                                        this.generating_print = false
                                                        console.log(error)
                                                    })
                                            })
                                            .catch((error) => {
                                                alert('fehler')
                                                this.generatingprint = false
                                                this.generating_print = false
                                                console.log(error)
                                            })
                                    })
                                    .catch((error) => {
                                        alert('fehler')
                                        this.generatingprint = false
                                        this.generating_print = false
                                        console.log(error)
                                    })
                            })
                            .catch((error) => {
                                alert('fehler')
                                this.generatingprint = false
                                this.generating_print = false
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        alert('fehler')
                        this.generatingprint = false
                        this.generating_print = false
                        console.log(error)
                    })
            }
        },
        formatDate(datum) {
            let date = new Date(datum * 1000)
            return date.toLocaleDateString()
        },
    },
    beforeDestroy() {
        clearInterval(this.x)

        this.abortController.abort('not needed anymore')
    },
    async created() {
        this.abortController = new abortService()
        this.$emit('checkVersion')
        this.setNavigationBarText(null)
        if (!this.navigationVisible) this.toggleNavigationBarVisibility()

        let studio = null

        studio = await api.getUserStudio().catch((e) => {
            window.app.$root.$children[0].checkStudio()
        })
        studio = studio.studio
        let studio_id = studio._id
        this.studio_id_workout = studio_id
        this.fetchGFRecommendations()
        // force || !state.deviceList.length || moment().diff(state.deviceListFetched, 'minutes') >= 10) {
        api.fetchDevices(this.abortController.signal).then((deviceList) => {
            deviceList.forEach((e) => (e.fab = false))
            this.setDeviceList(JSON.parse(JSON.stringify(deviceList)))
        })

        /*if (this.userService.hasRight('tp_viewAll', 'tp_create')) {
			api.getAllUserList(this.abortController.signal)
				.then((data) => {
					this.users = data.user
					this.users_filtered = this.users
				})
				.catch((error) => {
					console.log(error)
				})
		}*/

        this.getTrainingPlans()
    },
}
</script>
<style lang="scss" scoped>
.trainingsplaeneold-fab {
    position: absolute;
    top: 5px;
    right: 5px;
}
::v-deep .v-speed-dial__list {
    padding-right: 0 !important;
}
.lottie-player {
    width: 100% !important;
    height: 60vh !important;
}

.workouts {
    &__search {
        width: 100%;
        max-height: 75vh;
        overflow: auto;
    }
    &__title {
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-weight: bold;
    }
    &__card {
        width: 100%;
        position: relative;
        &-action-buttons {
            &--container {
                position: absolute;
                z-index: 1;
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                width: 100%;
                height: 100%;
                background: rgba(grey, 40%);
            }
        }
        &-container {
            overflow-x: auto;
            &-mobile {
                &::-webkit-scrollbar {
                    display: none;
                }
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        }
        &-fab {
            &-lottie {
                height: 35px;
                width: 35px;
                display: flex;
            }
        }
        &-create-text {
            background: rgba(grey, 80%);
            text-align: center;
            margin: auto;
            position: relative;
            width: 75%;
            height: 50%;
            top: 25%;
        }
        &-text {
            position: absolute;
            left: 10px;
            bottom: 10px;
            max-width: 90%;
            background: rgba(grey, 80%);
        }
    }
}

.statistik {
    display: flex;
    padding: 6px 0px;
    width: 50%;
    justify-content: center;
    color: white;
    span {
        padding: 5px;
    }
}
.generating-print {
    position: absolute;
    padding: 20px;
    font-weight: 400;
    font-size: 18px;
    background-color: white;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    img {
        width: 40px;
        height: 40px;
    }
}
.loading {
    width: 100%;
    height: 60vh;
    padding: 20px;
    font-weight: 400;
    font-size: 18px;
    background-color: white;
}
.assign-plan-button {
    margin-top: 60px;
    width: 100%;
    padding: 10px 5px;
    background-color: #424242;
    border-radius: 5px;
}
.zurueck {
    position: fixed;
    width: 300px;
    bottom: 60px;
    right: calc(50% - 150px);
    text-align: center;
    background-color: var(--v-primary);
    color: white;
    padding: 10px 0px;
    border-radius: 5px;
}
.kunde-auswaehlen {
    position: fixed;
    top: calc(50% - 150px);
    height: 300px;
    width: 300px;
    max-width: 80vw;
    max-height: 80vh;
    background-color: var(--v-primary);
    color: white;
    right: calc(50% - 150px);
    padding: 20px;
    border-radius: 10px;
    text-align: center;

    input {
        width: 100%;
        min-height: 40px;
        font-weight: 200;

        text-rendering: auto;
        color: white;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;

        box-sizing: border-box;

        align-items: center;
        white-space: pre;

        cursor: default;
        margin: 0em;
        font: 400 13.3333px Arial;
        border-radius: 0px;
        border-width: 0px;
        border-style: solid;

        border-image: initial;
        padding: 0px;

        &.filter-customers {
            border-bottom: 1px solid white;
            margin-bottom: 15px;
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: white;
        }
        &::-moz-placeholder {
            /* Firefox 19+ */
            color: white;
        }
        &:-ms-input-placeholder {
            /* IE 10+ */
            color: white;
        }
        &:-moz-placeholder {
            /* Firefox 18- */
            color: white;
        }
    }

    select {
        width: 100%;
        color: white;
        font-weight: 200;

        option {
            color: black;
        }
    }
}
.ModusPicker {
    position: absolute;
    top: 0px;
    height: 100vh;
    width: 100vw;
    /* max-width: 80vw; */
    /* max-height: 80vh; */
    /* background-color: var(--v-primary); */
    color: #fff;
    /* right: calc(50% - 150px); */
    padding: 20px;
    /* border-radius: 10px; */
    text-align: center;
    background-color: #000000c2;

    .modus-wrapper {
        position: absolute;
        height: 260px;
        width: 90%;
        max-width: 350px;
        top: calc(50% - 130px);
        right: calc(50% - 165px);
        background-color: #fff;
        color: #000;
        padding: 10px;
        border-radius: 5px;
    }

    .title {
        font-size: 14px;
        font-weight: bold;
    }
    .assignMode {
        padding: 10px 5px;
        border-radius: 5px;
        margin: 10px 0px;
        background-color: #424242;
        color: white;
    }
    .editMode {
        padding: 10px 5px;
        border-radius: 5px;
        margin: 10px 0px;
        background-color: #424242;
        color: white;
    }
    .deleteMode {
        padding: 10px 5px;
        border-radius: 5px;
        margin: 10px 0px;
        background-color: #dc2323eb;
        color: white;
    }
    .schliessen {
        position: absolute;
        bottom: 0px;
        text-align: center;
        width: 100%;
        padding: 5px;
        right: 0px;
    }
}

.edit-workouts {
    margin-left: auto;
    margin-right: 30px;
    padding: 5px;
    height: 40px;
    display: block;
    line-height: 40px;
    width: 40px;
}
.kunden-workouts {
    display: flex;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    justify-content: space-between;
    color: white;
    background-color: #424242;
    .material-icons {
        line-height: 48px;
        padding-right: 17px;
    }
}
.coming-soon {
    display: flex;
    justify-content: center;
}
.coming-soon img {
    width: 100vw;
    max-width: 400px;
}
.no-workouts {
    color: #4a4a4a;
    padding: 10px;
    .span {
        color: #4a4a4a;
        padding: 2px;
    }
}
.workout-action-new {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    background-color: var(--v-primary);

    .workout-print {
        width: 100% !important;
    }
}
.workout-action {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    background-color: var(--v-primary);
}
.workout {
    &-print {
        display: flex;
        justify-content: center;
        width: 50%;
        color: white;
        border-right: 1px solid #4a4a4a;
        padding: 6px 0px;
        span {
            padding: 5px;
        }
    }
    &-assign {
        position: absolute;
        right: 15px;
        top: 20px;
        padding: 10px;
    }
    &-delete {
        position: absolute;
        padding: 10px;
        right: 15px;
        top: 20px;
    }
}
.workout-wrap {
    position: relative;
    border-bottom: 1px solid #4a4a4a;
}
.back-navigation {
    background-color: var(--v-primary);
    color: white;
    padding: 0px 10px;
    font-weight: 500;
    display: flex;
    position: relative;
    height: 44px;

    .create-workout {
        span {
            position: absolute;
            right: 15px;
            font-size: 30px;
        }
    }

    span {
        margin-top: auto;
        margin-bottom: auto;
    }
    a {
        display: flex;
        padding: 5px;
        color: white;

        span {
            font-size: 34px;
        }
    }
}
.workouts {
    color: #4a4a4a;

    .workout {
        padding: 20px 0px;
        border-bottom: 1px solid #4a4a4a;

        display: flex;
        flex-direction: column;
        color: #4a4a4a;
        position: relative;
        text-decoration: none;
        .workout-name {
            padding-left: 15px;
        }
        .workout-datum {
            padding-left: 15px;
            font-weight: 300;
            font-size: 14px;
        }
        span {
            position: absolute;
            right: 15px;
            top: 30px;
        }
    }
}
.scroll-container {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 10px; /* Abstand zwischen den Karten */
}
.scroll-container::-webkit-scrollbar {
    display: none; /* Versteckt die Scrollbar */
}

.course-card {
    width: 140px; /* Stelle sicher, dass die Karten eine Mindestbreite haben */
}

.relative-position {
    position: relative;
}

.delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; /* Stellt sicher, dass der Button über dem Bild liegt */
}
</style>
