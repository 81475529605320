import { render, staticRenderFns } from "./myTraining.vue?vue&type=template&id=78bd7494&scoped=true&"
import script from "./myTraining.vue?vue&type=script&lang=js&"
export * from "./myTraining.vue?vue&type=script&lang=js&"
import style0 from "./myTraining.vue?vue&type=style&index=0&id=78bd7494&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78bd7494",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VBtn,VIcon,VProgressLinear,VSkeletonLoader})
