<template>
    <v-dialog
        :fullscreen="$vuetify.breakpoint.xs"
        max-width="500px"
        persistent
        v-model="statisticDialog"
        content-class="statisticDialog"
        :origin="origin">
        <v-card style="background-color: white">
            <div style="background-color: #f4fdf2; min-height: 100vh">
                <v-btn @click="closeStatisticDialog" absolute icon top right x-large style="z-index: 1000"
                    ><v-icon class="white--text">mdi-close</v-icon></v-btn
                >
                <v-date-picker
                    color="primary"
                    :allowed-dates="(date) => date <= new Date().toISOString().substr(0, 10)"
                    full-width
                    locale="CH-DE"
                    v-model="activeDate"
                    :events="events"
                    @change="loadTrainingDataForDay()"></v-date-picker>
                <div v-if="data">
                    <v-row v-if="data.checkin" class="ma-0">
                        <v-col cols="12" class="py-0">
                            <v-card-title class="font-weight-bold pb-2 pa-0">Check-IN</v-card-title>
                            <v-card-text class="px-0"
                                >Du hast dich an diesem Tag um {{ $moment(data.checkin).format('HH:mm') }} Uhr
                                eingecheckt.</v-card-text
                            >
                        </v-col>
                    </v-row>
                    <v-row v-if="data.courseArray.length" class="ma-0">
                        <v-col cols="12" class="pt-0">
                            <v-card-title class="pa-0 font-weight-bold">Group Fitness</v-card-title>
                        </v-col>
                        <v-col
                            class="pt-0"
                            :key="'groupFitness' + course.course.name"
                            v-for="course of data.courseArray"
                            cols="6">
                            <v-tooltip left>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on"
                                        style="
                                            background: white;
                                            height: 22px;
                                            width: 22px;
                                            position: absolute;
                                            top: 2px;
                                            right: 17px;
                                            z-index: 100000;
                                        "
                                        class="rounded-xl"
                                        color="primary"
                                        >mdi-information</v-icon
                                    >
                                </template>
                                <div class="d-flex flex-column">
                                    <span> {{ groupFitnessExecutions[course.course._id].count }}x besucht</span>
                                    <span>
                                        {{ groupFitnessExecutions[course.course._id].duration }} Minuten im Kurs
                                        verbracht</span
                                    >
                                    <span class="pt-1" v-if="getStarCountForCourse(course.course._id) > 0"
                                        >Durschnittliche Bewertung</span
                                    >
                                    <v-rating
                                        v-if="getStarCountForCourse(course.course._id) > 0"
                                        :value="getStarCountForCourse(course.course._id)"
                                        color="yellow darken-3"
                                        background-color="grey darken-1"
                                        empty-icon="$ratingFull"
                                        half-increments
                                        readonly></v-rating>
                                </div>
                            </v-tooltip>
                            <v-card elevation="7" class="d-flex flex-column rounded-lg justify-space-around">
                                <v-responsive :aspect-ratio="2 / 1.5">
                                    <v-img
                                        class="fill-height"
                                        style="position: absolute; opacity: 0.7"
                                        :src="'/img/kurse/' + course.course.bild"></v-img>
                                    <v-card-text class="font-weight-black pb-0 px-2 course-top">{{
                                        course.course.name
                                    }}</v-card-text>
                                    <v-card-text class="font-weight-bold pt-2 px-2 course-bottom">
                                        {{ course.time }}
                                    </v-card-text>
                                </v-responsive>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row v-if="data.workouts.length" class="ma-0">
                        <v-col cols="12" class="pt-0">
                            <v-card-title class="font-weight-bold pa-0">Trainingspläne</v-card-title>
                        </v-col>
                        <v-col class="pt-0" :key="'workout' + workout._id" v-for="workout of data.workouts" cols="6">
                            <v-card elevation="7" class="d-flex flex-column justify-space-around rounded-lg">
                                <v-responsive :aspect-ratio="1 / 1">
                                    <v-img
                                        class="fill-height"
                                        style="position: absolute; opacity: 0.4"
                                        :src="'/img/uebungen/' + workout.image"></v-img>
                                    <v-card-text
                                        style="position: relative; font-size: 1rem"
                                        class="font-weight-black pb-0 px-2"
                                        >{{ workout.title }}</v-card-text
                                    >
                                    <v-row style="position: relative" class="ma-0">
                                        <v-col
                                            v-if="workout.caloriesBurned"
                                            class="d-flex flex-column pa-0 align-items-center"
                                            :cols="workout.liftedWeight ? '12' : '6'">
                                            <lottie-player
                                                mode="normal"
                                                class="workingOut__lottie-player"
                                                style="width: 30px; height: 30px"
                                                name="loading"
                                                autoplay
                                                src="/img/lottiefiles/callorieBurned.json"></lottie-player>
                                            <span> {{ workout.caloriesBurned }} kcal</span>
                                        </v-col>
                                        <v-col
                                            v-if="workout.duration"
                                            class="d-flex flex-column pa-0 align-items-center"
                                            cols="6">
                                            <lottie-player
                                                mode="normal"
                                                class="workingOut__lottie-player"
                                                style="width: 30px; height: 30px"
                                                name="loading"
                                                autoplay
                                                src="/img/lottiefiles/hourGlass.json"></lottie-player>
                                            <span> {{ workout.duration }} </span>
                                        </v-col>

                                        <v-col
                                            v-if="workout.liftedWeight"
                                            class="d-flex flex-column align-items-center pa-0"
                                            cols="6">
                                            <lottie-player
                                                mode="normal"
                                                class="workingOut__lottie-player"
                                                style="width: 30px; height: 30px"
                                                name="loading"
                                                autoplay
                                                src="/img/lottiefiles/dumbell.json"></lottie-player>
                                            <span><number :from="0" :to="workout.liftedWeight"></number>KG</span>
                                        </v-col>
                                    </v-row>
                                </v-responsive>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
                <div v-else class="text-center pa-4">
                    <v-btn color="primary" :loading="true" icon></v-btn>
                </div>

                <div v-if="data && !data.checkin && !data.workouts.length && !data.courseArray.length">
                    <v-card-title class="justify-center font-weight-bold">Keine Einträge an diesem Tag</v-card-title>
                </div>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import api from '../../api'
import userService from '../../services/userService'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: 'myTrainingStatisticDialog',
    props: {
        origin: {
            type: String,
            required: true,
        },
        openedDate: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tooltip: true,
            statisticDialog: true,
            groupFitnessExecutions: [],
            loading: true,
            activeDate: this.$moment().format('YYYY-MM-DD'),
            events: [],
            data: null,
        }
    },
    async created() {
        this.toggleNavigationBarVisibility()
        if (this.openedDate <= new Date().toISOString().substr(0, 10)) {
            this.activeDate = JSON.parse(JSON.stringify(this.openedDate))
        }
        await this.createCalendar()
        await this.loadTrainingDataForDay()
    },

    methods: {
        ...mapActions('navigationStoreNotPersisted', ['toggleNavigationBarVisibility']),
        getStarCountForCourse(course) {
            let stars = 0
            this.groupFitnessExecutions[course].ratings.forEach((rating) => {
                stars += rating
            })
            return stars / this.groupFitnessExecutions[course].ratings.length
        },
        async loadTrainingDataForDay() {
            this.data = null
            this.data = await api.fetchTrainingDataForDay(this.activeDate)
        },
        closeStatisticDialog() {
            this.toggleNavigationBarVisibility()
            this.statisticDialog = false
            setTimeout(() => {
                this.$emit('close')
            }, 500)
        },
        openDate(index) {
            this.openedDate = this.openedDate === index ? null : index
        },
        async createCalendar() {
            this.loading = true
            let data = await api.fetchCompleteTrainingCalendar()
            this.events = data.events
            this.groupFitnessExecutions = data.groupFitnessExecutions
            this.loading = false
        },
    },
    computed: {},
}
</script>

<style lang="scss" scoped>
::v-deep .v-dialog:not(.v-dialog--fullscreen) {
    overflow-y: hidden !important;
}
.statisticDialog {
    overflow-x: hidden !important;
}

::v-deep .v-dialog {
    transition: 0.75s cubic-bezier(0.25, 0.8, 0.25, 1);
}

::v-deep .v-picker__body {
    background: #f4fdf2;
}

::v-deep .v-progress-circular {
    height: 50px !important;
    width: 50px !important;
}

.course-top {
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    margin: auto;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.7);
    width: 90%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.course-bottom {
    font-size: 1rem;
    left: 0;
    right: 0;
    margin: auto;
    position: absolute;
    top: 50%;
    background: rgba(255, 255, 255, 0.7);
    width: 90%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
</style>
