<template>
    <v-container>
        <v-tabs centered dark v-model="tab">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab v-for="item in tabItems" :key="item">
                {{ item }}
            </v-tab>
        </v-tabs>
        <v-tabs-items dark v-model="tab">
            <v-tab-item>
                <v-row>
                    <v-col class="d-flex justify-center" cols="12">
                        <v-btn @click="createNewJob" class="my-5" color="primary">Erstellen</v-btn>
                        <jobOffer
                            @update="fetchJobs"
                            :studios="studioList.map((e) => ({ name: e.name, _id: e._id }))"
                            ref="newJobOffer"
                        />
                    </v-col>
                    <v-col cols="12">
                        <jobCard @showDetails="showDetails" :jobs="jobs" />
                    </v-col>
                </v-row>
            </v-tab-item>
            <v-tab-item>
                <v-card class="ma-5" flat>
                    <v-row>
                        <v-col v-for="studio of studioListSorted" :key="studio._id" cols="12" md="4">
                            <v-card @click="openStudio(studio)" color="primary" class="d-flex justify-center">
                                <v-card-title class="white--text">{{ studio.name }}</v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog fullscreen v-model="showDialog">
            <v-card style="position: relative; height: 100vh; overflow: scroll">
                <v-card-title
                    style="position: absolute; top: 0; width: 100%"
                    class="primary white--text d-flex justify-center"
                >
                    {{ selectedStudio.name }}
                </v-card-title>
                <div
                    v-if="studioContent.studio"
                    style="
                        max-width: 600px;
                        max-height: calc(100% - 120px);
                        overflow: scroll;
                        margin-top: 70px;
                        margin-bottom: 20px;
                    "
                    class="d-flex flex-column mx-auto px-5"
                >
                    <v-row class="mt-3">
                        <v-col cols="12" v-if="studioContent && studioContent.headerVideo">
                            <v-responsive aspect-ratio="16/9">
                                <div class="video-container">
                                    <video autoplay loop>
                                        <source
                                            :src="
                                                '/api/website/video/' +
                                                studioContent.headerVideo.replace('.mp4', '-original.mp4')
                                            "
                                            type="video/mp4"
                                        />
                                        Your browser does not support the video tag.
                                    </video>
                                </div>
                            </v-responsive>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center flex-column">
                            <!-- Video Upload -->
                            <v-file-input v-model="videoFile" label="Video hochladen" accept="video/*"></v-file-input>
                            <v-btn color="primary" @click="uploadVideo" :disabled="processingVideo" class="mx-auto">
                                Video hochladen
                            </v-btn>
                            <div v-if="uploadProgress > 0 && uploadProgress <= 98" class="text-center mt-5">
                                <v-progress-linear :value="uploadProgress" class="mb-1"></v-progress-linear>
                                <p>Das Video wird hochgeladen</p>
                            </div>

                            <div
                                v-if="processingVideo && !(uploadProgress >= 0 && uploadProgress <= 98)"
                                class="text-center mt-5"
                            >
                                <v-progress-circular
                                    class="mb-1"
                                    indeterminate
                                    color="primary"
                                    size="45"
                                ></v-progress-circular>
                                <p>Das Video wird verarbeitet, bitte verlasse die Seite nicht.</p>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-textarea
                                label="Club Infos"
                                filled
                                placeholder="Willkommen im well come FIT..."
                                class="px-5 mt-5"
                                v-model="studioContent.clubSection.text"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12">
                            <!--- Bilder Galerie -->

                            <v-carousel
                                v-if="
                                    studioContent &&
                                    studioContent.clubSection &&
                                    studioContent.clubSection.pictures &&
                                    studioContent.clubSection.pictures.length > 0
                                "
                                height="auto"
                                hide-delimiters
                                class="position-relative"
                            >
                                <v-carousel-item
                                    v-for="(picture, index) in studioContent.clubSection.pictures"
                                    :key="index"
                                    :src="'/api/website/image/' + picture.src"
                                >
                                    <v-img
                                        :src="picture.src"
                                        :alt="picture.alt"
                                        aspect-ratio="16/9"
                                        class="rounded-lg"
                                    ></v-img>
                                    <v-icon @click="removePicture(index)" color="red" dark class="remove-btn">
                                        mdi-close
                                    </v-icon>
                                    <div class="alt-text-container">
                                        <v-text-field
                                            label="Alt Text (Bildbeschreibung | SEO)"
                                            v-model="picture.alt"
                                            filled
                                            dense
                                        ></v-text-field>
                                    </div>
                                </v-carousel-item>
                            </v-carousel>
                        </v-col>
                        <v-col class="d-flex justify-center flex-column" cols="12">
                            <v-file-input v-model="file" label="Bild hochladen" @change="onFileChange"></v-file-input>
                            <v-text-field label="Alt Text (Bildbeschreibung | SEO)" v-model="altText"></v-text-field>
                            <v-btn class="mx-auto" color="primary" @click="uploadImage">Hochladen</v-btn>
                        </v-col>
                    </v-row>
                </div>
                <v-card-actions
                    style="position: absolute; bottom: 0; width: 100%; background-color: white"
                    class="d-flex justify-center"
                >
                    <v-btn class="mr-5" @click="resetDialog" text>Abbrechen</v-btn>

                    <v-btn class="ml-5" @click="updateContent" color="primary">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template> 
<script>
import api from '@/api'
import axios from 'axios'
import jobOffer from '@/components/marketing/jobOffer.vue'
import jobCard from '@/components/marketing/jobCard.vue'
export default {
    components: { jobOffer, jobCard },
    data() {
        return {
            studioList: [],
            jobs: [],
            tabItems: ['Jobs', 'Content'],
            tab: null,
            studioContent: {},
            showDialog: false,
            selectedStudio: { name: 'Bearbeiten', code: '', _id: '' },
            file: null,
            altText: '',
            videoFile: null,
            uploadProgress: 0,
            processingVideo: false,
        }
    },
    async created() {
        this.fetchStudios()
    },
    computed: {
        studioListSorted() {
            return this.studioList.sort((a) => a.name.toLowerCase())
        },
    },
    methods: {
        async fetchStudios() {
            try {
                let studios = await api.fetchStudios()
                studios = studios.filter((e) => e.code !== 'alibey' && e.code !== 'ot')
                this.studioList = studios
                this.fetchJobs()
            } catch (e) {
                this.$toast.error('Fehler beim laden der Studios')
            }
        },
        createNewJob() {
            this.$refs.newJobOffer.createNewJob()
        },
        showDetails(job) {
            this.$refs.newJobOffer.edit(job)
        },
        async fetchJobs() {
            try {
                let jobs = await api.fetchJobs()
                this.jobs = jobs
            } catch (e) {
                this.$toast.error('Fehler beim laden der Jobs')
            }
        },
        async uploadVideo() {
            if (!this.videoFile) {
                this.$toast.error('Bitte wähle ein Video aus')
                return
            }
            this.studioContent.headerVideo = null

            let formData = new FormData()
            formData.append('video', this.videoFile)
            this.processingVideo = true

            try {
                const response = await axios.post('/api/website/upload/video', formData, {
                    headers: {
                        Authorization: localStorage.getItem('token'),
                        'Content-Type': 'multipart/form-data',
                    },
                    onUploadProgress: (progressEvent) => {
                        this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    },
                })

                if (response.data.file) {
                    this.studioContent.headerVideo = response.data.file
                    this.$toast.success('Video erfolgreich hochgeladen')
                }
            } catch (error) {
                this.$toast.error('Fehler beim Hochladen des Videos')
                console.error(error)
            } finally {
                this.uploadProgress = 0
                this.videoFile = null
                this.processingVideo = false
            }
        },
        async uploadImage() {
            if (!this.file || !this.altText) {
                this.$toast.error('Bitte wähle ein Bild aus oder füge einen Alt Text hinzu')
                return
            }

            let formData = new FormData()
            formData.append('image', this.file)
            formData.append('alt', this.altText)

            try {
                // upload
                let response = await api.uploadWebsiteImage(formData)

                if (response.file) {
                    if (this.studioContent.clubSection.pictures === null) this.studioContent.clubSection.pictures = []
                    this.studioContent.clubSection.pictures.push({
                        src: response.file,
                        alt: response.alt,
                    })
                }

                this.file = null
                this.altText = ''
            } catch (error) {
                this.$toast.error('Fehler beim hochladen des Bildes')
                console.error(error)
            }
        },
        removePicture(index) {
            this.studioContent.clubSection.pictures.splice(index, 1)
        },
        onFileChange(file) {
            // Temporäres Bild-Element erstellen, um die Dimensionen zu überprüfen
            const img = new Image()

            img.onload = () => {
                const width = img.width
                const height = img.height

                // Überprüfen, ob das Bild im 16:9-Format ist
                if (width / height === 16 / 9) {
                    this.file = file
                } else {
                    this.file = null
                    this.$toast.error('Bitte wählen Sie ein Bild im 16:9-Format.')
                }
            }

            // Fehlerfall, falls das Bild nicht geladen werden kann
            img.onerror = () => {
                this.file = null
                this.$toast.error('Ungültige Bilddatei.')
            }

            // Bildquelle auf das ausgewählte Dateiblob setzen
            img.src = URL.createObjectURL(file)
        },
        openStudio(studio) {
            this.selectedStudio = { name: studio.name, code: studio.code, _id: studio._id }
            this.fetchStudioContent()
        },
        async updateContent() {
            if (!this.studioContent.studio) {
                this.$toast.error('Bitte lade die Seite neu')
                return
            }
            try {
                let status = await api.updateWebsiteContent(this.studioContent)

                this.$toast.success('Gespeichert')
            } catch (e) {
                console.log(e)
                this.$toast.error('Es gab einen Fehler')
            }
        },
        async fetchStudioContent() {
            if (!this.selectedStudio.code) {
                this.$toast.error('Kein Studio ausgewählt')
                return
            }
            try {
                let content = await api.fetchWebsiteContent(this.selectedStudio.code)
                if (content.result) {
                    this.studioContent = content.result
                } else {
                    this.initNew()
                }

                this.showDialog = true
            } catch (e) {
                this.showDialog = false
                this.$toast.error('Es gab einen Fehler')
            }
        },
        initNew() {
            this.studioContent = {
                studio: this.selectedStudio._id,
                headerVideo: null,
                clubSection: {
                    text: null,
                    pictures: null,
                },
            }
        },
        resetDialog() {
            this.showDialog = false
            this.studioContent = {}
            this.selectedStudio = { name: '', code: '', _id: '' }
        },
    },
    watch: {},
}
</script>
<style lang="scss" scoped>
.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 ratio */
}

video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.position-relative {
    position: relative;
}
.remove-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
}
.alt-text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}
.v-text-field {
    margin: 0;
}
</style>
