<template>
    <div>
        <div style="background-color: #343541" class="white--text">
            <v-data-table
                v-if="!$vuetify.breakpoint.mobile"
                dark
                :search="search"
                :loading="!finishedLoading"
                :headers="groupfitnessCoursesTable"
                no-data-text="Keine Trainer gefunden..."
                loading-text="Trainerstatistiken werden geladen..."
                :items="filteredDashboardTrainerInfos"
                :items-per-page="8"
                class="elevation-5 trainer-statistics__table"
            >
                <template v-slot:top>
                    <v-row>
                        <v-col style="flex-grow: 2">
                            <v-text-field v-model="search" class="mx-4" label="Suche"></v-text-field>
                        </v-col>
                        <v-col style="align-self: center">
                            <v-btn color="primary" @click="percentagesTo100 = !percentagesTo100">
                                {{ percentagesTo100 ? 'Originalwerte anzeigen' : 'Stellenprozente aufrechnen' }}
                            </v-btn>
                        </v-col>
                        <v-col style="align-self: center">
                            <v-tooltip bottom>
                                <div class="d-flex flex-column">
                                    <span>Ausgeblendete Mitarbeiter (kein Vertrag, TP, und Anamnese):</span>
                                    <p v-for="trainer of notShownTrainers" :key="'notshown' + trainer._id">
                                        {{ trainer.fullName }}
                                    </p>
                                    <span v-if="!notShownTrainers.length">Es wurde kein Trainer ausgeblendet</span>
                                </div>
                                <template v-slot:activator="{ props, on }">
                                    <v-btn v-bind="props" v-on="on" icon color="white">
                                        <v-icon color="white">mdi-information</v-icon>
                                    </v-btn>
                                </template>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.percentage="{ item }">
                    <v-tooltip bottom>
                        <div class="d-flex flex-column">
                            <span>Stellenprozent Berechnung:</span>
                            <span
                                v-for="(percentageItem, index) of item.allPercentages"
                                :key="percentageItem._id + item.fullName + index"
                            >
                                {{
                                    storeStudios.find((item) => item._id === percentageItem._id)
                                        ? storeStudios.find((item) => item._id === percentageItem._id).name +
                                          ' ' +
                                          percentageItem.percentage +
                                          '%'
                                        : percentageItem.percentage + '%'
                                }}
                            </span>
                        </div>
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                color="grey"
                                v-bind="props"
                                v-on="on"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.percentage + '%' }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.contracts="{ item }">
                    <v-tooltip bottom>
                        {{ 'Durchschnittliche Verträge: ' + Math.round(contractsOnAverage * 100) / 100 }}
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getContractColor(item.contracts)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.contracts }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.avgPackages="{ item }">
                    <v-tooltip bottom>
                        <p>
                            {{ 'Gesammt Verkauft:' + Math.round(item.avgPackages * item.contracts) }}
                        </p>
                        <p>
                            {{ 'Durchschnittliche Pakete: ' + Math.round(packagesOnAverage * 100) / 100 }}
                        </p>
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getPackageColor(item.avgPackages)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.avgPackages }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.medicalHistory="{ item }">
                    <v-tooltip bottom>
                        {{ 'Durchschnittliche Anamnesebögen: ' + Math.round(medicalHistoriesOnAverage * 100) / 100 }}
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getMedicalHistoryColor(item.medicalHistory)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.medicalHistory }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.workouts="{ item }">
                    <v-tooltip bottom>
                        {{ 'Durchschnittliche Trainingspläne: ' + Math.round(workoutsOnAverage * 100) / 100 }}
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getWorkoutColor(item.workouts)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.workouts }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.packageCount="{ item }">
                    <v-chip
                        v-bind="props"
                        v-on="on"
                        :color="getPackageColor(item.avgPackages)"
                        style="min-width: 50%"
                        class="justify-content-center font-weight-bold"
                        dark
                    >
                        {{ item.packageCount }}
                    </v-chip>
                </template>
                <template v-slot:item.quote="{ item }">
                    <v-tooltip bottom>
                        <p>
                            {{
                                'Von: ' +
                                item.medicalHistory +
                                ' Anamnesebögen haben ' +
                                Math.round((item.quote / 100) * item.medicalHistory) +
                                ' einen Vertrag abgeschlossen'
                            }}
                        </p>
                        <p>{{ 'Durchschnittliche Versch. Kunden: ' + Math.round(quoteOnAverage * 100) / 100 }} %</p>
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getQuoteColor(item.quote)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.quote !== null ? item.quote + '%' : '-' }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.workoutsPerCustomer="{ item }">
                    <v-tooltip bottom>
                        <p>
                            {{ 'Durchschnitt: ' + Math.round(workoutsPerCustomerOnAverage * 100) / 100 }}
                        </p>
                        <template v-slot:activator="{ props, on }">
                            <v-chip
                                v-bind="props"
                                v-on="on"
                                :color="getWorkoutPerCustomerColor(item.workoutsPerCustomer)"
                                style="min-width: 50%"
                                class="justify-content-center font-weight-bold"
                                dark
                            >
                                {{ item.workoutsPerCustomer }}
                            </v-chip>
                        </template>
                    </v-tooltip>
                </template>
            </v-data-table>
            <v-row class="mt-5">
                <v-col cols="7">
                    <v-card-title class="white--text">Trainer</v-card-title>
                </v-col>
                <v-col cols="5">
                    <v-select label="sortieren" dark filled :items="sortItems" v-model="activeSorting"></v-select>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                    style="margin-top: 60px"
                    :cols="$vuetify.breakpoint.mobile ? 12 : 4"
                    :key="'trainer' + index"
                    v-for="(trainer, index) in filteredDashboardTrainerInfos"
                >
                    <v-card
                        dark
                        @click="selectedTrainer = trainer"
                        hover
                        style="height: 100%"
                        class="d-flex flex-column justify-content-center text-center rounded-xl"
                    >
                        <v-avatar
                            style="position: absolute; top: -62px; left: 0; right: 0"
                            class="employee-manager__card-avatar ma-auto"
                            color="grey"
                            size="125"
                        >
                            <img
                                :src="
                                    trainer.picture
                                        ? '/img/profilbilder' + trainer.picture
                                        : '/img/einmalig/imageUploadPlaceholderPFP.jpg'
                                "
                            />
                        </v-avatar>
                        <v-card-title class="justify-content-center text-break" style="margin-top: 55px">
                            {{ trainer.fullName }}
                        </v-card-title>
                        <v-card-subtitle>
                            <v-chip
                                class="mx-1"
                                :class="{
                                    primary:
                                        storeStudios.find((x) => x.name === std) &&
                                        storeStudios.find((x) => x.name === std)._id === trainer.mainStudio,
                                }"
                                v-for="std of trainer.studio.split(',')"
                                :key="trainer._id + std"
                            >
                                {{ std }}
                            </v-chip>
                        </v-card-subtitle>
                        <v-card-text class="py-1">
                            {{ trainer.workouts ? trainer.workouts : 'Keine' }} Trainingspläne
                        </v-card-text>
                        <v-card-text class="py-1">
                            {{ trainer.medicalHistory ? trainer.medicalHistory : 'Keine' }} Anamnesebögen
                        </v-card-text>
                        <v-card-text class="py-1">
                            {{ trainer.contracts ? trainer.contracts : 'Keine' }} Verträge abgeschlossen
                        </v-card-text>
                        <v-card-text class="py-1" v-if="trainer.contracts">
                            {{ trainer.avgPackages ? trainer.avgPackages : 'Keine' }}Ø Pakete verkauft
                        </v-card-text>
                        <v-card-text class="py-1">
                            Trainer
                            <v-rating
                                :value="trainer.tp_question_1"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                half-increments
                                readonly
                            ></v-rating>
                        </v-card-text>
                        <v-card-text class="py-1">
                            Trainingsplan
                            <v-rating
                                :value="trainer.tp_question_2"
                                color="yellow darken-3"
                                background-color="grey darken-1"
                                empty-icon="$ratingFull"
                                half-increments
                                readonly
                            ></v-rating>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <home-dashboard-trainer-statistics-dialog
            v-if="selectedTrainer"
            :trainer="selectedTrainer"
            :studioTrainers="
                selectedTrainer.mainStudio
                    ? dashboardTrainerInfos.filter((trainer) =>
                          trainer.studio.find((x) => x === selectedTrainer.mainStudio)
                      )
                    : dashboardTrainerInfos.filter((trainer) =>
                          trainer.studio.find((x) => x === selectedTrainer.studio[0])
                      )
            "
            :datePickerRange="datePickerRange"
            @close="closeDialog"
        ></home-dashboard-trainer-statistics-dialog>
    </div>
</template>

<script>
import api from '../../../api'
import { mapState, mapActions, mapGetters } from 'vuex'
import homeDashboardTrainerStatisticsDialog from './homeDashboardTrainerStatisticsDialog.vue'
export default {
    name: 'dashboard',
    props: {
        studios: {
            type: Array,
        },
        datePickerRange: {
            type: Array,
        },
    },
    components: {
        homeDashboardTrainerStatisticsDialog,
    },
    data() {
        return {
            notShownTrainers: [],
            activeSorting: '',
            selectedTrainer: null,
            sortItems: [
                {
                    text: 'Anz. Anamnese',
                    value: 'medicalHistory',
                },
                {
                    text: 'Anz. Trainingspläne',
                    value: 'workouts',
                },
                {
                    text: 'Anz. Verträge',
                    value: 'contracts',
                },
                {
                    text: 'Ø Pakete',
                    value: 'avgPackages',
                },
                {
                    text: 'Pakete',
                    value: 'packageCount',
                },
            ],
            search: '',
            percentagesTo100: false,
            dashboardTrainerInfos: [],
            finishedLoading: false,
            groupfitnessCoursesTable: [
                {
                    text: 'Name',
                    value: 'fullName',
                },
                {
                    text: 'Studio',
                    value: 'studio',
                },
                {
                    text: 'Stellenprozent',
                    value: 'percentage',
                },
                {
                    text: 'Verträge',
                    value: 'contracts',
                },
                { text: 'Ø Pakete', value: 'avgPackages' },
                { text: 'Pakete', value: 'packageCount' },
                {
                    text: 'Anamnesebögen',
                    value: 'medicalHistory',
                },
                {
                    text: 'Abschlussquote',
                    value: 'quote',
                },
                { text: 'Trainingspläne', value: 'workouts' },
                { text: 'Versch. Kunden TP', value: 'workoutsPerCustomer' },
            ],
        }
    },
    async created() {
        this.dashboardTrainerInfos = await api.fetchTrainerDashboardInfos({
            studios: this.studios.map((studio) => studio._id),
            range: this.datePickerRange,
        })
        this.notShownTrainers = this.dashboardTrainerInfos.filter(
            (trainer) => !trainer.contracts && !trainer.workouts && !trainer.medicalHistory
        )
        this.dashboardTrainerInfos = this.dashboardTrainerInfos.filter(
            (trainer) => trainer.contracts || trainer.workouts || trainer.medicalHistory
        )
        console.log(this.dashboardTrainerInfos)
        this.finishedLoading = true
    },
    methods: {
        closeDialog() {
            this.selectedTrainer = null
        },
        getContractColor(trainersAverage) {
            if (trainersAverage > this.contractsOnAverage + 2) {
                return 'green'
            } else if (trainersAverage < this.contractsOnAverage - 2) {
                return 'red'
            } else {
                return 'orange'
            }
        },
        getPackageColor(trainersAverage) {
            if (trainersAverage > this.packagesOnAverage + 0.5) {
                return 'green'
            } else if (trainersAverage < this.packagesOnAverage - 0.5) {
                return 'red'
            } else {
                return 'orange'
            }
        },
        getMedicalHistoryColor(trainersAverage) {
            if (trainersAverage > this.medicalHistoriesOnAverage + 2) {
                return 'green'
            } else if (trainersAverage < this.medicalHistoriesOnAverage - 2) {
                return 'red'
            } else {
                return 'orange'
            }
        },
        getWorkoutColor(trainersAverage) {
            if (trainersAverage > this.workoutsOnAverage + 2) {
                return 'green'
            } else if (trainersAverage < this.workoutsOnAverage - 2) {
                return 'red'
            } else {
                return 'orange'
            }
        },
        getWorkoutPerCustomerColor(trainersAverage) {
            if (trainersAverage > this.workoutsOnAverage + 2) {
                return 'green'
            } else if (trainersAverage < this.workoutsPerCustomerOnAverage - 2) {
                return 'red'
            } else {
                return 'orange'
            }
        },
        getQuoteColor(trainersAverage) {
            if (trainersAverage > this.quoteOnAverage + 10) {
                return 'green'
            } else if (trainersAverage < this.quoteOnAverage - 10) {
                return 'red'
            } else {
                return 'orange'
            }
        },
    },
    computed: {
        ...mapState('studioStore', ['storeStudios']),
        contractsOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.contracts
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        packagesOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.avgPackages
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        medicalHistoriesOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.medicalHistory
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        workoutsOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.workouts
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        quoteOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.quote
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        workoutsPerCustomerOnAverage() {
            return (
                this.dashboardTrainerInfos.reduce((acc, curr) => {
                    return acc + curr.workoutsPerCustomer
                }, 0) / this.dashboardTrainerInfos.length
            )
        },
        filteredDashboardTrainerInfos() {
            let copiedArray = JSON.parse(JSON.stringify(this.dashboardTrainerInfos))
            copiedArray = copiedArray.map((user) => {
                user.studio = user.studio
                    .map((stud) => {
                        return this.storeStudios.find((std) => std._id === stud)
                            ? this.storeStudios.find((std) => std._id === stud).name
                            : 'zus. Team'
                    })
                    .join(', ')

                if (this.percentagesTo100 && user.percentage < 100) {
                    user.contracts = Math.round((user.contracts / user.percentage) * 100)

                    user.medicalHistory = Math.round((user.medicalHistory / user.percentage) * 100)
                    user.workouts = Math.round((user.workouts / user.percentage) * 100)
                    user.workoutsPerCustomer = Math.round((user.workoutsPerCustomer / user.percentage) * 100)
                    user.percentage = (user.percentage / user.percentage) * 100
                }
                return user
            })
            return copiedArray.sort((a, b) => {
                if (this.activeSorting === 'medicalHistory') {
                    return b.medicalHistory - a.medicalHistory
                }
                if (this.activeSorting === 'contracts') {
                    return b.contracts - a.contracts
                }
                if (this.activeSorting === 'avgPackages') {
                    return b.avgPackages - a.avgPackages
                }

                if (this.activeSorting === 'packageCount') {
                    return Math.round(b.avgPackages * b.contracts) - Math.round(a.avgPackages * a.contracts)
                }
                if (this.activeSorting === 'workouts') {
                    return b.workouts - a.workouts
                }
            })
        },
    },
    watch: {
        async studios() {
            this.finishedLoading = false
            this.dashboardTrainerInfos = []

            this.dashboardTrainerInfos = await api.fetchTrainerDashboardInfos({
                studios: this.studios.map((studio) => studio._id),
                range: this.datePickerRange,
            })
            this.notShownTrainers = this.dashboardTrainerInfos.filter(
                (trainer) => !trainer.contracts && !trainer.workouts && !trainer.medicalHistory
            )
            this.dashboardTrainerInfos = this.dashboardTrainerInfos.filter(
                (trainer) => trainer.contracts || trainer.workouts || trainer.medicalHistory
            )
            this.finishedLoading = true
        },
        async datePickerRange() {
            if (this.datePickerRange[0] && this.datePickerRange[1]) {
                this.dashboardTrainerInfos = []
                this.finishedLoading = false
                this.dashboardTrainerInfos = await api.fetchTrainerDashboardInfos({
                    studios: this.studios.map((studio) => studio._id),
                    range: this.datePickerRange,
                })
                this.notShownTrainers = this.dashboardTrainerInfos.filter(
                    (trainer) => !trainer.contracts && !trainer.workouts && !trainer.medicalHistory
                )
                this.dashboardTrainerInfos = this.dashboardTrainerInfos.filter(
                    (trainer) => trainer.contracts || trainer.workouts || trainer.medicalHistory
                )
                this.finishedLoading = true
            }
        },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .apexcharts-menu-open {
    background-color: #343541 !important;
    height: 80vh;
}
.trainer-statistics {
    &__table {
        width: 100%;
    }
}
</style>
