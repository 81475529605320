var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nextCourses"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center pt-0",attrs:{"cols":"12"}},[_c('v-chip-group',_vm._l((_vm.items),function(item,index){return _c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.show),expression:"item.show"}],key:index,staticClass:"rounded-lg",class:{ 'selected-chip': _vm.selectedIndex === index },on:{"click":function($event){return _vm.selectChip(index)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1)],1),(_vm.selectedIndex === 0 || _vm.selectedIndex === 1)?_c('v-row',{staticClass:"nextCourses-container"},_vm._l((_vm.courseList),function(course){return _c('v-col',{key:course._id,staticClass:"nextCourses-container-col rounded-lg",class:{ 'mx-auto mb-2': _vm.showCourse(course), 'my-0 py-0': !_vm.showCourse(course) },attrs:{"cols":"11"}},[(_vm.showCourse(course))?_c('v-row',{staticClass:"nextCourses__entry",attrs:{"no-gutters":""},on:{"click":function($event){return _vm.pushToCourse(course)}}},[_c('v-col',{attrs:{"cols":"3"}},[_c('div',{staticStyle:{"position":"relative"}},[_c('v-img',{staticClass:"rounded-lg mx-auto",attrs:{"cover":"","height":"50px","max-width":"100px","width":"100%","src":course && course.course && course.course.bild
                                        ? '/img/kurse/' + course.course.bild
                                        : ''}}),(_vm.courseRecommendations.includes(course.course._id))?_c('span',{staticStyle:{"position":"absolute","top":"-10px","right":"-10px","background-color":"#ffd700","color":"white","border-radius":"20px","font-size":"14px"}},[_c('v-icon',{staticClass:"pa-1",attrs:{"small":"","color":"white"}},[_vm._v("mdi-star")])],1):_vm._e()],1)]),_c('v-col',{staticClass:"pl-5",attrs:{"cols":"7"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"nextCourses__entry-title font-weight-bold"},[_vm._v(" "+_vm._s(course && course.course && course.course.name ? course.course.name : '')+" ")])]),_c('v-col',{staticClass:"pb-0 pt-1 d-flex align-center",staticStyle:{"font-size":"14px"},attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock-time-eight")]),_c('span',{staticClass:"pl-1"},[_vm._v(_vm._s(_vm.courseStartDate(course))+" Uhr | "+_vm._s(course.duration)+" Min")])],1)],1)],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.selectedIndex === 0)?_c('div',[(course.onQueue)?_c('div',{staticClass:"nextCourses__card-signedUp"},[_c('v-icon',{staticClass:"pl-1",attrs:{"color":"red"}},[_vm._v("mdi-clipboard-alert")])],1):_vm._e(),(course.signedUp && !course.onQueue)?_c('div',{staticClass:"nextCourses__card-signedUp"},[_c('v-icon',{staticClass:"pl-1",attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")])],1):_vm._e(),(!course.onQueue && !course.signedUp)?_c('div',{staticClass:"single-chart mx-auto"},[_c('svg',{staticClass:"circular-chart green-color ml-auto",attrs:{"viewBox":"0 0 36 36"}},[_c('path',{staticClass:"circle-bg",attrs:{"d":"M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"}}),_c('path',{staticClass:"circle",style:(_vm.getStrokeColor(
                                                _vm.calcPercentage(course),
                                                course.course.slimMe,
                                                course.cancelled || course.cancelledVisibility,
                                                course.signedUp
                                            )),attrs:{"stroke-dasharray":_vm.calcPercentage(course) + ', 100',"d":"M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"}})])]):_vm._e()]):_c('div',[(course.lsSignedUp)?_c('div',{staticClass:"nextCourses__card-signedUp"},[_c('v-icon',{staticClass:"pl-1",attrs:{"color":"primary"}},[_vm._v("mdi-check-circle")])],1):_vm._e(),(!course.lsSignedUp && !course.onQueue)?_c('div',{staticClass:"nextCourses__card-signedUp"},[_c('v-icon',{staticClass:"pl-1",attrs:{"color":"red"}},[_vm._v("mdi-video")])],1):_vm._e()])])],1):_vm._e()],1)}),1):_vm._e(),(_vm.selectedIndex === 2)?_c('news-slider',{staticClass:"mt-5"}):_vm._e(),(_vm.selectedIndex === 3)?_c('trainingsplan-slider',{staticClass:"mt-5"}):_vm._e(),_c('div',{staticClass:"transparency-bottom"}),_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_vm._v("Livestreams")]),_c('v-card-text',{staticClass:"mt-5"},[_vm._v(" Wechsel in das Studio \"LIVESTREAMS\", um dich für Livestream Kurse anzumelden. ")]),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.showDialog = false}}},[_vm._v("Abbrechen")]),_c('v-btn',{staticClass:"text--white",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.showStudioPicker()}}},[_vm._v("Weiter")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }