<template>
    <div style="max-width: 500px" class="ma-auto">
        <div v-if="firstLoading" class="loader-div">
            <nutrition-loader></nutrition-loader>
        </div>
        <div
            v-show="!firstLoading"
            class="mainpage-top elevation-5 primary"
            :style="{ height: divHeight + 'px', width: '100vw', 'max-width': '520px' }"
        >
            <v-row no-gutters>
                <v-app-bar color="transparent" width="100%" fixed elevation="0" class="justify-space-between py-2">
                    <v-col cols="2">
                        <v-btn icon @click="$router.push('/')">
                            <v-icon color="white">mdi-chevron-left</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="8">
                        <v-img height="50px" @click="$router.push('/')" contain src="/img/roody_logo.png"></v-img>
                    </v-col>
                </v-app-bar>
            </v-row>
            <v-row no-gutters class="mt-10 pt-5">
                <v-col cols="12">
                    <nutrition-mainpage-calendar
                        v-if="nutritionUser"
                        @fetchForDay="fetchForDay"
                        :loading="loadingForDay"
                        :startDate="nutritionUser.mealPlan ? nutritionUser.mealPlan.createdAt : $moment()"
                        :selectedDays="nutritionUser.mealPlan ? nutritionUser.mealPlan.selectedDays : []"
                    ></nutrition-mainpage-calendar>
                </v-col>
                <v-col cols="12">
                    <nutrition-mainpage-chart
                        :height="divHeight - 150"
                        :dragging="dragging"
                        :date="date"
                        :totalCalories="dataForDay.totalCalories"
                        :substanceDistribution="dataForDay.substanceDistribution"
                        :dailyCalories="dataForDay.dailyCalories"
                        :nutritions="dataForDay.nutrition"
                        :entries="dataForDay.entries"
                    ></nutrition-mainpage-chart>
                </v-col>
                <v-col cols="12" style="margin-top: -14px; padding-top: 5px; z-index: 1001">
                    <div style="height: 20px" @touchstart.prevent="startDragging">
                        <div class="white" style="height: 4px; width: 40%; margin: auto"></div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <nutrition-mainpage-list
            :style="{ 'padding-top': divHeight + 'px' }"
            v-if="dataForDay"
            v-show="!firstLoading"
            :mealDistribution="dataForDay.mealDistribution"
            :dailyCalories="dataForDay.dailyCalories"
            :habits="habitsForDay"
            :selectedExecution="selectedHabitExecution"
            :date="date"
            :loading="loadingForDay"
            @selectMeal="(meal) => (nutritionDialogMeal = meal)"
        ></nutrition-mainpage-list>
        <nutrition-meal-dialog
            v-if="dataForDay"
            :meal="nutritionDialogMeal"
            :date="date"
            @addEntry="
                (entry) => {
                    dataForDay.entries.push(entry)
                    dataForDay.mealDistribution.find((dist) => dist.value === entry.meal).calories += Number(
                        entry.calories
                    )
                }
            "
            :dailyCalories="dataForDay.dailyCalories"
            @closeDialog="() => (nutritionDialogMeal = null)"
        ></nutrition-meal-dialog>

        <nutrition-mainpage-f-a-b
            :date="date"
            @dayChoice="dayChoiceActive = true"
            @selected="(habit) => (selectedHabit = habit)"
            @selected-execution="
                (habit) =>
                    habit === 'formUpdateHabitExecution'
                        ? (selectedHabitExecution = habitsForDay.find((hab) => hab.type === 'formUpdateHabit'))
                        : habit
            "
            @added="(habit) => habitsForDay.push(habit)"
            :formUpdateHabit="
                habitsForDay &&
                habitsForDay.length &&
                habitsForDay.find((habit) => habit && habit.type === 'formUpdateHabit')
            "
        ></nutrition-mainpage-f-a-b>
        <habit-dialog
            v-if="selectedHabit"
            @close="selectedHabit = null"
            :drinkingHabit="habitsForDay.find((habit) => habit.type === 'drinkHabit')"
            :habit="selectedHabit"
        ></habit-dialog>
        <nutrition-day-choice
            :date="date"
            :possibilities="nutritionUser && nutritionUser.mealPlan ? nutritionUser.mealPlan.selectedDays : []"
            :choiceActive="dayChoiceActive"
            @close="fetchForDay(date)"
        ></nutrition-day-choice>
        <anamnese-bogen-create ref="anamneseBogenRef" @submitted="anamnese = true"></anamnese-bogen-create>
        <v-dialog style="border-radius: 25px" persistent v-model="portionOrAnamneseMissingDialog">
            <v-card style="border-radius: 25px">
                <v-card-title class="font-weight-bold px-4">Deine nächsten Schritte</v-card-title>
                <v-window :touch="{}" v-model="windowState">
                    <v-window-item :value="1">
                        <v-row class="justify-space-around pa-7" no-gutters>
                            <v-col cols="5">
                                <div
                                    @click="nutritionUser.portionSizes ? '' : (windowState = 2)"
                                    class="option-button__wrapper"
                                >
                                    <v-img height="100px" width="100px" src="/img/lottiefiles/portionSizes.png"></v-img>
                                    <span>Portionsbilder</span>
                                    <v-overlay v-if="nutritionUser.portionSizes" absolute>
                                        <v-btn fab x-large><v-icon color="primary">mdi-check-bold</v-icon></v-btn>
                                    </v-overlay>
                                </div>
                            </v-col>
                            <v-col cols="5">
                                <div @click="anamnese ? '' : startAnamnese()" class="option-button__wrapper">
                                    <v-img
                                        height="100px"
                                        width="100px"
                                        src="/img/lottiefiles/nutritionReport.png"
                                    ></v-img>
                                    <span>Fragebogen</span>
                                    <v-overlay v-if="anamnese" absolute>
                                        <v-btn fab x-large><v-icon color="primary">mdi-check-bold</v-icon></v-btn>
                                    </v-overlay>
                                </div>
                            </v-col>
                        </v-row>
                    </v-window-item>
                    <v-window-item :value="2">
                        <nutrition-mainpage-portion-sizes-upload
                            @cancel="windowState = 1"
                            @saved="
                                () => {
                                    nutritionUser.portionSizes = true
                                    windowState = 1
                                }
                            "
                        ></nutrition-mainpage-portion-sizes-upload>
                    </v-window-item>
                </v-window>
                <v-card-text v-if="nutritionUser && nutritionUser.portionSizes && anamnese">
                    <v-alert type="success">
                        Deine Angaben sind bei uns eingegangen! Dein Ernährungscoach arbeitet nun an deinem Plan. Wir
                        melden uns bald bei dir.
                    </v-alert>
                </v-card-text>
                <v-card-actions class="justify-content-center">
                    <v-btn @click="$router.push('/')" color="primary">zur Startseite</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- <input v-if="false" type="file" id="fileUpload" @change="test" name="fileUpload" />
        -->
    </div>
</template>
<script>
import nutritionMainpageCalendar from './nutritionMainpageCalendar.vue'
import nutritionMainpageChart from './nutritionMainpageChart.vue'
import nutritionMainpageList from './nutritionMainpageList.vue'
import nutritionMealDialog from './nutritionMealDialog.vue'
import nutritionMainpageFAB from './nutritionMainpageFAB.vue'
import nutritionMainpagePortionSizesUpload from './nutritionMainpagePortionSizesUpload.vue'
import nutritionLoader from './nutritionLoader.vue'
import habitDialog from '../habit/habitDialog.vue'
import nutritionDayChoice from './nutritionDayChoice.vue'
import anamneseBogenCreate from '@/components/anamneseBogenCreate'

import { habitService } from '@/services/habitService.js'

import { createWorker } from 'tesseract.js'

import api from '../../api'

export default {
    components: {
        nutritionMainpagePortionSizesUpload,
        anamneseBogenCreate,
        nutritionMainpageCalendar,
        nutritionMainpageChart,
        nutritionMainpageList,
        nutritionMealDialog,
        nutritionMainpageFAB,
        habitDialog,
        nutritionDayChoice,
        nutritionLoader,
    },
    data() {
        return {
            windowState: 1,
            selectedHabit: null,
            dayChoiceActive: false,
            selectedHabitExecution: null,
            nutritionUser: null,
            loadingForDay: false,
            firstLoading: true,
            dataForDay: {},
            habitsForDay: null,
            nutritionDialogMeal: null,
            date: null,
            dragging: false,
            divHeight: 190,
            startY: 0,
            maxHeight: 500,
            anamnese: null,
            portionOrAnamneseMissingDialog: false,
        }
    },
    methods: {
        habitSelected(selection) {
            this.selectedHabit = selection
        },
        startDragging(event) {
            this.startY = event.touches[0].clientY
            this.dragging = true
            window.addEventListener('touchmove', this.drag)
            window.addEventListener('touchend', this.stopDragging)
        },
        drag(event) {
            if (this.dragging) {
                const touchY = event.touches[0].clientY
                const deltaY = touchY - this.startY
                this.startY = touchY
                const newHeight = this.divHeight + deltaY
                this.divHeight = Math.max(200, Math.min(this.maxHeight, newHeight))
            }
        },
        stopDragging() {
            this.dragging = false
            window.removeEventListener('touchmove', this.drag)
            window.removeEventListener('touchend', this.stopDragging)
        },
        async fetchForDay(date) {
            this.dataForDay = {}
            this.dayChoiceActive = false
            this.loadingForDay = true
            try {
                this.habitsForDay = await habitService.getHabits(date)
                let nutritionDataForDay = await api.fetchNutritionDataForDay(date)

                this.dataForDay = nutritionDataForDay
            } catch (e) {
                if (e.message === 'Request failed with status code 418' && this.nutritionUser.mealPlan) {
                    this.dayChoiceActive = true
                }
                console.error('Fehler beim Laden')
            }
            this.loadingForDay = false
            this.firstLoading = false
            this.date = date
        },
        async test(event) {
            const img = event.target.files[0]

            const base64Image = await this.convertToBase64(img)

            //  const apiResponse = await this.sendToGoogleVisionAPI(base64Image)
        },
        async sendToGoogleVisionAPI(image) {
            return alert('nicht freigeschaltet')
            const response = await fetch(
                'https://vision.googleapis.com/v1/images:annotate?key=AIzaSyDMSyRhJWvz_DpeEFoN_4eNlhZVzN_tOXY',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        requests: [
                            {
                                image: {
                                    content: image.split(',')[1], // Entferne den Base64-Header
                                },
                                features: [
                                    {
                                        type: 'TEXT_DETECTION',
                                    },
                                ],
                            },
                        ],
                    }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )
            return response.json()
        },
        convertToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve(reader.result)
                reader.onerror = (error) => reject(error)
            })
        },
        startAnamnese() {
            if (!this.anamnese) {
                this.$refs.anamneseBogenRef.setVisible(
                    null,
                    false,
                    true,
                    this.nutritionUser.user,
                    this.nutritionUser.coach
                )
            }
        },
    },
    async created() {
        let data = await api.loadNutritionUser()

        this.nutritionUser = data
        this.anamnese = await api.fetchOwnNutritionAnamnese()
        if (
            !this.anamnese ||
            !this.nutritionUser.mealPlan ||
            (!this.nutritionUser.portionSizes && !this.nutritionUser.mealPlan)
        ) {
            this.portionOrAnamneseMissingDialog = true
        }
    },
    mounted() {
        this.maxHeight = window.innerHeight - 30
    },
}
</script>
<style lang="scss">
.loader-div {
    position: relative;
    top: 40vh;
    left: 0;
    right: 0;
    width: 80px;
    margin: auto;
}
.mainpage-top {
    position: fixed;
    border-bottom-left-radius: 45px;
    border-bottom-right-radius: 45px;
    margin: -15px;
    z-index: 100;
    max-width: 100%;
    width: 100%;
    transition: height 0.05 ease;
}
.option-button {
    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 25px;
        background: lightgray;
        &-bulb {
            ::v-deep .v-image__image {
                width: 80px;
            }
            flex-direction: row;
        }
    }
    &__wrapper:hover {
        background: gray;
    }
}
</style>